import axios from "axios";
import React from 'react';
import { connect } from 'react-redux'
import { setPageTitle } from '../../app/actions'
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { compareJobs } from "../Common/CompareFunctions";
import JobTable from "./JobTable";

class JobsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          	jobs: [],
          	loading: false,
        };
        this.fetchJobs = this.fetchJobs.bind(this);
    }
    
    async componentDidMount() {
        this.props.setPageTitle('Jobs')
        this.fetchJobs();    
    }

    async fetchJobs() {
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }

        try{
            this.setState({loading: true});    
            let response = await axios.get(`${getApiURL()}/job`, options);
            let tmp = response['data'];
            let jobs = [];
            for(const job of tmp) {
                if('visible' in job) { //Hide jobs with 'visible' field set to false
                    if(job.visible) {
                        jobs.push(job);
                    }
                }
                else {
                    jobs.push(job);
                }
            }
            jobs.sort(compareJobs);
            this.setState({loading: false, jobs: jobs});
            this.props.setPageTitle('Jobs - ' + jobs.length);
        }
        catch(err) {
            console.log(err);
            this.setState({loading: false});
        }
    }

    render() {
        return(
            <div>
                <JobTable 
                    loading={this.state.loading} 
                    totalJobs={this.state.jobs.length}
                    jobs={this.state.jobs} 
                    refreshHandler={this.fetchJobs} 
                />
            </div>
        ); 
    }
}

export default connect(null, { setPageTitle })(JobsPage)