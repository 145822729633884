import React  from 'react';
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Input from "@amzn/awsui-components-react/polaris/input";

function SLAInput(props) {
    if(props.enabled) {
        return(
            <Input
                onChange={({ detail }) => props.setMetricsSLA(detail.value)}
                value={props.metricsSLA < 0 ? '' : props.metricsSLA}
                inputMode="numeric"
            />
        );
    }

    return '';
}

export default function MetricsSLASelctor(props) {
    const [enabled, setEnabled] = React.useState(false);

    if(props.metricsSLA > 0 && !enabled) {
        setEnabled(true);
    }

    return (
        <FormField
        description={'(Optional) Specify an SLA in days. Only unresolved Jiras older than the specified number will be considered'}
        label="Metrics SLA"
        >
            <Checkbox
                onChange={({ detail }) => {
                    setEnabled(detail.checked);
                    if(!detail.checked) {
                        props.setMetricsSLA(-1); //Reset SLA to -1 when not checked
                    }
                }}
                checked={enabled}
            >
                Set SLA
            </Checkbox>
            <SLAInput
                enabled={enabled}
                metricsSLA={props.metricsSLA}
                setMetricsSLA={props.setMetricsSLA}
            />
        </FormField>
    );
}