import React  from 'react';
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";

export default function DeviceFamilySelector(props) {
    const [loading, setLoading] = React.useState(true); 
    const [deviceFamilies, setDeviceFamilies] = React.useState([]);

    React.useEffect(() => {
        loadDeviceFamilies();
    }, [])

    const loadDeviceFamilies = async() => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            //Get list of onboarded devices in device_family_config.json.
            let response = await axios.get(`${getApiURL()}/config/file?name=device_family_config.json`, options);
            let device_families = response['data']['device_families'];
            let tmp = [];
            for(const family of Object.keys(device_families)) {
                tmp.push({ label: device_families[family]['label'], value: device_families[family]['label'] });
            }
            setDeviceFamilies(tmp);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    if(loading) {
        return <Spinner />;
    }

    return (
        <FormField
        description={'(Optional) Specify one or more device family filters'}
        label="Device Family"
        >
            <Multiselect
                selectedOptions={props.selectedOption}
                empty="No available device families."
                onChange={({ detail }) =>
                    props.setSelectedOption(detail.selectedOptions)
                }
                options={deviceFamilies}
                selectedAriaLabel="Selected"
            />
        </FormField>
    );
}