import React  from 'react';
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import { compareJobs } from "../Common/CompareFunctions";

export default function JobSelector(props) {
    const [loading, setLoading] = React.useState(true); 
    const [jobs, setJobs] = React.useState([]);

    React.useEffect(() => {
        loadJobs();
    }, [])

    const loadJobs = async() => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let response = await axios.get(`${getApiURL()}/job`, options);
            let jobs_response = response['data'];
            jobs_response.sort(compareJobs);
            let tmp = [];
            for(const job of jobs_response) {
                if(job.description && job.description !== '') {
                    tmp.push({
                        label: job['description'] + " (" + job['id'] + ")", 
                        value: job['id']
                    })
                }
                else {
                    tmp.push({
                        label: job['id'],
                        value: job['id']
                    })
                }
            }
            setJobs(tmp)
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    if(loading) {
        return <Spinner />;
    }

    return (
        <FormField
        label="Specify Zest Job"
        >
            <Select
                selectedOption={props.selectedOption}
                empty="No Zest Jobs found."
                onChange={({ detail }) =>
                    props.setSelectedOption(detail.selectedOption)
                }
                options={jobs}
                selectedAriaLabel="Selected"
            />
        </FormField>
    );
}