import React, { useCallback, useMemo } from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle, setStatusBarMessage } from "../../app/actions";
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Container from "@amzn/awsui-components-react/polaris/container";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import AttributeEditor from "@amzn/awsui-components-react/polaris/attribute-editor";
import Cards from "@amzn/awsui-components-react/polaris/cards";
import TypeLabelConfigurationAddModal from './TypeLabelConfigurationAddModal';

function SaveButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    if(props.disabled) {
        return(<Button variant="primary" disabled>Save</Button>); 
    }
    return(<Button onClick={(e) => {e.preventDefault(); props.save();}} variant="primary">Save</Button>);
}

function TypeJiraLabelMapping(props) {

    let typeJiraLabels = [];
    for(const typeJiraLabel of props.typeJiraLabels) {
        typeJiraLabels.push({
            description: typeJiraLabel.description,
            types: typeJiraLabel.types.join(', '),
            labels: typeJiraLabel.labels.join(', ')
        });
    }

    return (
        <Cards
        header={''}
        ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: "Item selection"
        }}
        cardDefinition={{
            sections: [
            {
                id: "description",
                header: "Description",
                content: item => item.description
            },
            {
                id: "types",
                header: "ASB Issue Types",
                content: item => item.type
            },
            {
                id: "labels",
                header: "Jira Labels",
                content: item => item.size
            }
            ]
        }}
        cardsPerRow={[
            { cards: 1 },
            { minWidth: 500, cards: 2 }
        ]}
        items={typeJiraLabels}
        />
    )
}

const LabelControl = React.memo(({ value, index, placeholder, setItems, prop }) => {
    return (
      <Input
        value={value}
        placeholder={placeholder}
        onChange={({ detail }) => {
          setItems(items => {
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              [prop]: detail.value,
            };
            return updatedItems;
          });
        }}
      />
    );
  });

export default function GlobalConfigPage() {
    const dispatch = useDispatch();

    //Response
    const [loading, setLoading] = React.useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false);
    const [changeSets, setChangeSets] = React.useState([]);

    //Global config
    const [loadingGlobalConfig, setLoadingGlobalConfig] = React.useState(false); 
    const [globalConfig, setGlobalConfig] = React.useState(null);
    const [ownerName, setOwnerName] = React.useState("");
    const [ownerAlias, setOwnerAlias] = React.useState("");
    const [globalJiraLabels, setGlobalJiraLabels] = React.useState([]);
    const [devicesExclude, setDevicesExclude] = React.useState([]);
    const [typeLabelConfiguration, setTypeLabelConfiguration] = React.useState([]);
    const [showTypeLabelConfigurationModal, setShowTypeLabelConfigurationModal] = React.useState(false);
    const [dueDateWeeks, setDueDateWeeks] = React.useState(4);

    React.useEffect(() => {
        dispatch(setPageTitle('Configure Global Settings'));
        loadGlobalConfig();
    }, [])

    const loadGlobalConfig = async() => {
        setLoadingGlobalConfig(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }

        try {
            let response = await axios.get(`${getApiURL()}/config/file?name=asb_config.json`, options);
            let globalConfig = response['data'];
            setGlobalConfig(globalConfig);
            
            //Owner name and alias
            setOwnerName(globalConfig['asb_owner_data']['name']);
            setOwnerAlias(globalConfig['asb_owner_data']['alias']);

            let tmp = [];
            for(const label of globalConfig['asb_global_jira_labels']) {
                tmp.push({
                    key: label
                })
            }
            setGlobalJiraLabels(tmp);

            tmp = [];
            for(const device of globalConfig['devices_exclude']) {
                tmp.push({
                    key: device
                })
            }
            setDevicesExclude(tmp);

            if(globalConfig['type_label_configuration']) {
                setTypeLabelConfiguration(globalConfig['type_label_configuration']);
            }

            setDueDateWeeks(globalConfig['asb_patch_due_date_weeks'].toString());

            
        } catch (e) {
            console.log(e);
        }

        setLoadingGlobalConfig(false);
    }

    const addNewTypeLabelMapping = (description, types, labels) => {
        let tmp = typeLabelConfiguration;
        tmp.push({
            description: description,
            types: types,
            labels: labels
        });
        setTypeLabelConfiguration(tmp);
    }

    const saveConfig = async() => {
        dispatch(setStatusBarMessage(null));
        let missingFields = [];

        if(!ownerAlias) {
            missingFields.push('Owner alias');
        }
        if(!ownerName) {
            missingFields.push('Owner name');
        }
        if(isNaN(dueDateWeeks)) {
            missingFields.push('Patch Due Weeks');
        }
    
        if(missingFields.length > 0) {
            dispatch(setStatusBarMessage({type: 'warning', message: 'Missing or invalid required field(s): ' + missingFields.join(', ')}));
            return;
        }

        //Submit changes to API
        setLoading(true);
        setSaveButtonDisabled(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        };

        let global_labels = [];
        for(const label of globalJiraLabels) {
            global_labels.push(label.key);
        }

        let devices_exclude = [];
        for(const device of devicesExclude) {
            devices_exclude.push(device.key);
        }

        try {
            const global_config = {
                file: 'asb_config.json',
                action: 'UPDATE_GLOBAL_CONFIG',
                description: 'Update global config values',
                changes: [
                    {
                        'asb_owner_data': {'name': ownerName, 'alias': ownerAlias},
                        'asb_patch_due_date_weeks': parseInt(dueDateWeeks),
                        'asb_global_jira_labels': global_labels,
                        'devices_exclude': devices_exclude
                    }
                ]
            };
            console.log(global_config);
            let global_config_response = await axios.post(`${getApiURL()}/config/changeset`, global_config_response, options);
            let tmp = changeSets;
            tmp.push({
                id: global_config_response.data,
                description: global_config['description'],
                status: 'Submitted'
            });
            setChangeSets(tmp);
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setSaveButtonDisabled(false);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to update configuration: ' + e}));
            return;
        }

        setLoading(false);
        dispatch(setStatusBarMessage({type: 'success', message: 'Configuration update in progress...'}));
    }

    const global_label_definition = useMemo(
        () => [
          {
            label: 'Global Jira Labels',
            description: 'Labels applied to every filed ASB Jira.',
            control: ({ key = '' }, itemIndex) => (
              <LabelControl prop="key" value={key} index={itemIndex} placeholder="Enter label text" setItems={setGlobalJiraLabels} />
            )
          }
        ],
        []
    );

    const onAddDeviceExcludeButtonClick = useCallback(() => {
        setDevicesExclude(items => [...items, {}]);
    }, []);

    const onRemoveDeviceExcludeButtonClick = useCallback(({ detail: { itemIndex } }) => {
        setDevicesExclude(items => {
            const newItems = items.slice();
            newItems.splice(itemIndex, 1);
            return newItems;
        });
    }, []);

    const devices_exclude_definition = useMemo(
        () => [
          {
            label: 'Excluded Devices',
            description: 'Devices excluded from ASB Jira filing.',
            control: ({ key = '' }, itemIndex) => (
              <LabelControl prop="key" value={key} index={itemIndex} placeholder="Enter device name" setItems={setDevicesExclude} />
            )
          }
        ],
        []
    );

    const onAddLabelButtonClick = useCallback(() => {
        setGlobalJiraLabels(items => [...items, {}]);
    }, []);

    const onRemoveLabelButtonClick = useCallback(({ detail: { itemIndex } }) => {
        setGlobalJiraLabels(items => {
            const newItems = items.slice();
            newItems.splice(itemIndex, 1);
            return newItems;
        });
    }, []);

    if(loadingGlobalConfig) {
        return (
            <SpaceBetween size='l'>
                <Container
                    header={
                        <Header
                            variant="h2"
                        >
                        Global Configuration
                        </Header>
                    }
                >
                    <Spinner/>
                </Container>
            </SpaceBetween>
        );
    }

    return (
        <SpaceBetween size='l'>
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Global Configuration
                    </Header>
                }
            >
                <SpaceBetween size='m'>
                    <FormField
                        label="ASB Jira Owner"
                        description="The contact owner displayed on every ASB Jira."
                    >
                        <FormField
                            description="Name"
                        >
                            <Input
                                onChange={({ detail }) => setOwnerName(detail.value)}
                                value={ownerName}
                            />
                        </FormField>
                        <FormField
                            description="Amazon Alias"
                        >
                            <Input
                                onChange={({ detail }) => setOwnerAlias(detail.value)}
                                value={ownerAlias}
                            />
                        </FormField>
                        
                    </FormField>
                    <FormField
                        label="Patch Due Weeks"
                        description="The due date of every ASB patch in weeks. Applied to the 'due date' field in Jira."
                    >
                        <Input
                            onChange={({ detail }) => setDueDateWeeks(detail.value)}
                            value={dueDateWeeks}
                        />
                    </FormField>
                    <AttributeEditor
                        items={globalJiraLabels}
                        definition={global_label_definition}
                        onAddButtonClick={onAddLabelButtonClick}
                        onRemoveButtonClick={onRemoveLabelButtonClick}
                        addButtonText="Add label"
                        removeButtonText="Remove"
                        empty="No labels configured."
                    />
                    <AttributeEditor
                        items={devicesExclude}
                        definition={devices_exclude_definition}
                        onAddButtonClick={onAddDeviceExcludeButtonClick}
                        onRemoveButtonClick={onRemoveDeviceExcludeButtonClick}
                        addButtonText="Add excluded device"
                        removeButtonText="Remove"
                        empty="No excluded devices configured."
                    />
                    <FormField
                        label="ASB Type Jira Labels"
                        description="Additional mappings from ASB issue types to Jira labels."
                    >
                        <TypeJiraLabelMapping typeJiraLabels={typeLabelConfiguration} />
                        <TypeLabelConfigurationAddModal visible={showTypeLabelConfigurationModal} setModalVisibility={setShowTypeLabelConfigurationModal} addNewTypeLabelMapping={addNewTypeLabelMapping} />
                        <Button onClick={(e) => {e.preventDefault(); setShowTypeLabelConfigurationModal(true);}}>Add Mapping</Button>
                    </FormField>
                </SpaceBetween>
            </Container>
            <div align="right">
                <SaveButton save={saveConfig} loading={loading} disabled={saveButtonDisabled}/>
            </div>
        </SpaceBetween>
    );
}