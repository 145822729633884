import React from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../app/actions";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import ASBMonthSelector from './ASBMonthSelector';
import DeviceFamilySelector from './DeviceFamilySelector';
import JobTypeBadge from './JobTypeBadge';
import MetricsSLASelector from './MetricsSLASelector';
import {
    Container,
    Multiselect,
    Spinner
} from '@amzn/awsui-components-react/polaris';
import Alert from "@amzn/awsui-components-react/polaris/alert";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Wizard from "@amzn/awsui-components-react/polaris/wizard";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Input from "@amzn/awsui-components-react/polaris/input";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Button from "@amzn/awsui-components-react/polaris/button";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import Select from "@amzn/awsui-components-react/polaris/select";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";

function JobParsingFilingOptionList(props) {
  let options = [];
  if(props.patch01) {
    options.push('Parse Patch01 (Kernel) Issues');
  }
  if(props.patch05) {
    options.push('Parse Patch05 (Platform) Issues');
  }
  if(props.generatePatches) {
    options.push('Generate Patches');
  }
  if(props.generateTrackingTable) {
    options.push('Generate Tracking Wiki Page');
  }
  if(props.sendEmailNotification) {
    options.push('Send Email Notification to TPMs and FireOS team');
  }

  const listItems = options.map((option) =>  <li>{option}</li>);
  return <ul>{listItems}</ul>;
}

function GenericList(props) {
  if(props.options && props.options.length > 0) {
    const listItems = props.options.map((option) =>  <li>{'value' in option ? option['value'] : option}</li>);
    return <ul>{listItems}</ul>;
  }
  return props.empty ? props.empty : '--';
}

function ASBDataSelector(props) {
    if(props.jobType === 'backfill_asb') {
        return(
            <SpaceBetween direction="vertical" size="l">
                <EnvironmentSelector selectedOption={props.environmentSelection} setSelectedOption={props.setEnvironmentSelection}/>
            </SpaceBetween>
        );
    }
    else if(props.jobType === 'standard_asb') {
        return (
            <SpaceBetween direction="vertical" size="l">
                <ASBMonthSelector jobType={props.jobType} multiselect={false} selectedOption={props.asbDataSelection} setSelectedOption={props.setASBDataSelection}/>
                <EnvironmentSelector selectedOption={props.environmentSelection} setSelectedOption={props.setEnvironmentSelection}/>
            </SpaceBetween>
        )
    }
    else if(props.jobType === 'metrics') {
        return (
          <SpaceBetween direction="vertical" size="l">
              <ASBMonthSelector jobType={props.jobType} optional={true} multiselect={true} selectedOption={props.metricsMonthSelection} setSelectedOption={props.setMetricsMonthSelection}/>
              <DeviceFamilySelector jobType={props.jobType} selectedOption={props.deviceFamilySelection} setSelectedOption={props.setDeviceFamilySelection}/>
              <MetricsSLASelector metricsSLA={props.metricsSLA} setMetricsSLA={props.setMetricsSLA}/>
          </SpaceBetween>
        )
    }
}

function ASBCheckbox(props) {
    if(props.disabled) {
        return(
            <Checkbox
                checked={props.checked}
                disabled
                >
                {props.text}
            </Checkbox>
        );
    }

    return(
        <Checkbox
            onChange={({ detail }) =>
                props.onChange(detail.checked)
            }
            checked={props.checked}
            >
            {props.text}
        </Checkbox>
    );
}

function EnvironmentSelector(props) {
    const environments = [
        {label: 'PANN', description: 'Create JSON output for use by PANN before filing (recommended)', value: 'pann-verify'},
        {label: 'Dry-run', description: 'Log output only', value: 'dry-run'},
        /*Deprecated options below:*/
        {enabled: false, disabled: true, label: 'File to staging (deprecated)', description: 'issues-staging.labcollab.net', value: 'staging'},
        {enabled: false, disabled: true, label: 'File to production (deprecated)', description: 'issues.labcollab.net', value: 'production'}
    ];

    return (
        <FormField
        description="Specify environment. PANN creates output file for use by PANN before filing issues (recommended). Dry-run generates text output."
        label="Environment"
        >
            <Select
                selectedOption={props.selectedOption}
                empty="No ASB data available for use."
                onChange={({ detail }) =>
                    props.setSelectedOption(detail.selectedOption)
                }
                options={environments}
                selectedAriaLabel="Selected"
            />
        </FormField>
    );
}

function DeviceSelector(props) {
    const [loading, setLoading] = React.useState(true); 
    const [devices, setDevices] = React.useState([]);

    React.useEffect(() => {
        loadDevices();
    }, [])

    const loadDevices = async() => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let tmp = [];
            let response = await axios.get(`${getApiURL()}/config/devices`, options);
            if(response['data']) {
                response['data'].sort()
                for(const device of response['data']) {
                    tmp.push({
                        label: device, value: device
                    });
                }
            }
            setDevices(tmp);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    if(loading) {
        return <Spinner />;
    }

    return (
        <FormField
        description="Specify device filter from a list of onboarded devices. For backfill, this field is required."
        label="Device Selection"
        >
            {props.multiselect && //Allow user to select multiple devices for metrics report
              <Multiselect
                  selectedOptions={props.selectedOption}
                  empty="No devices available for use."
                  onChange={({ detail }) =>
                      props.setSelectedOption(detail.selectedOptions)
                  }
                  options={devices}
                  selectedAriaLabel="Selected"
              />
            }
            {!props.multiselect && 
              <Select
                  selectedOption={props.selectedOption}
                  empty="No devices available for use."
                  onChange={({ detail }) =>
                      props.setSelectedOption(detail.selectedOption)
                  }
                  options={devices}
                  selectedAriaLabel="Selected"
              />
            }
        </FormField>
      );
}

export default function JobCreatePage() {
    const dispatch = useDispatch();
    const [responseLoading, setResponseLoading] = React.useState(false); 
    const [activeStepIndex, setActiveStepIndex] = React.useState(0);
    const [jobType, setJobType] = React.useState({});
    const [jobDescription, setJobDescription] = React.useState('');
    const [jobId, setJobId] = React.useState(uuidv4());
    const [asbDataSelection, setASBDataSelection] = React.useState({});
    const [metricsMonthSelection, setMetricsMonthSelection] = React.useState([]);
    const [deviceSelection, setDeviceSelection] = React.useState('');
    const [environmentSelection, setEnvironmentSelection] = React.useState({});
    const [deviceFamilySelection, setDeviceFamilySelection] = React.useState([]); //For metrics report
    const [metricsDeviceSelection, setMetricsDeviceSelection] = React.useState([]); //For metrics report
    const [metricsSLA, setMetricsSLA] = React.useState(-1); //For metrics report
    const [metricsJQL, setMetricsJQL] = React.useState(''); //For metrics report
    const [patch01, setPatch01] = React.useState(true);
    const [patch05, setPatch05] = React.useState(true);
    const [generatePatches, setGeneratePatches] = React.useState(false);
    const [generateTrackingTable, setGenerateTrackingTable] = React.useState(true);
    const [sendEmailNotification, setSendEmailNotification] = React.useState(false);
    const [cveFilters, setCVEFilters] = React.useState([]);
    const [maxFile, setMaxFile] = React.useState('');
    const [zestRawParams, setZestRawParams] = React.useState('');
    const [errorWindowVisible, setErrorWindowVisible] = React.useState(false);
    const [errorWindowTitle, setErrorWindowTitle] = React.useState('');
    const [errorWindowDescription, setErrorWindowDescription] = React.useState('');

    React.useEffect(() => {
        dispatch(setPageTitle('Create Job'));
    // eslint-disable-next-line
    }, [])

    /*if(loading) {
        return (
            <Container>
                <div align="center">
                    <Spinner size="large" />
                </div>
            </Container>
        );
    }*/

    const generateJobID = () => {
      setJobId(uuidv4());
      setZestRawParams(generateZestServiceParameterString());
    }

    const generateZestServiceParameterString = () => {
      let paramString = '';
      
      paramString += '--run-id ' + jobId + ' '; //Job ID

      if(jobType === 'backfill_asb' || jobType === 'standard_asb') {
        if(jobType === 'backfill_asb') { //Patches
          paramString += '--patches all ';
        }
        else {
          paramString += '--patches ' + asbDataSelection.value + ' ';
        }
        paramString += '--environment ' + environmentSelection.value + ' '; //Environment
        if(patch01) {
          paramString += '--patch01 ';
        }
        if(patch05) {
          paramString += '--patch05 ';
        }
        if(deviceSelection) {
          paramString += '--device ' + deviceSelection.value + ' '; //Device
        }
        if(cveFilters && cveFilters.length > 0) {
          paramString += '--cves ' + cveFilters + ' '; //CVE filter
        }
        if(jobType === 'backfill_asb') {
          paramString += '--backfill '; //Backfill flag
        }
        if(maxFile > 0) {
          paramString += '--max-file ' + maxFile + ' '; //Max number of Jiras to file
        }
        if(generatePatches) {
          paramString += '--generate-patches ';
        }
        if(generateTrackingTable) {
          paramString += '--generate-tracking-table ';
        }
        if(sendEmailNotification) {
          paramString += '--send-email-notification ';
        }
        
        //All jobs created through this wizard will be parse-asb jobs. Other actions available on specific job detail page after parsing.
        paramString += '--mode parse-asb '
      }
      else if(jobType === 'metrics') {
        if(metricsMonthSelection && metricsMonthSelection.length > 0) { //ASB months
          paramString += '--patches ';
          const patches = metricsMonthSelection.map(patch => {
              return patch['value'];
          })
          paramString += patches.join(',') + ' ';
        }
        else {
          paramString += '--patches all ';
        }

        if(deviceFamilySelection && deviceFamilySelection.length > 0) { //Device family
          paramString += '--device-family ';
          const labels = deviceFamilySelection.map(device => {
              return device['value'];
          })
          paramString += labels.join(',') + ' ';
        }

        if(metricsDeviceSelection && metricsDeviceSelection.length > 0) { //Device filters
          paramString += '--device ';
          const devices = metricsDeviceSelection.map(device => {
              return device['value'];
          })
          paramString += devices.join(',') + ' ';
        }
        
        if(metricsSLA > 0) { //SLA
          paramString += '--sla ' + metricsSLA + ' ';
        }

        if(metricsJQL && metricsJQL.length > 0) { //Custom metrics query
          const dquote_regex = /["]/g;
          const replacement = '\\"';
          paramString += '--custom-jql "' + metricsJQL.replace(dquote_regex, replacement) + '" ';
        }

        paramString += '--mode metrics-report --environment production ';
      }

      return paramString.trim();
    }

    const validateJobOptions = () => {
      let validationErrors = [];
      
      if(!jobDescription || jobDescription.trim().length < 1) {
        validationErrors.push('Missing job description.');
      }

      if(!jobType || jobType.trim().length < 1) {
        validationErrors.push('Missing job type.');
      }
      else {
        if(jobType === 'backfill_asb') {
          if(!deviceSelection || !deviceSelection.value || deviceSelection.value.trim().length < 1) {
            validationErrors.push('Missing device selection for ASB backfill.');
          }
        }
      }

      if(jobType === 'backfill_asb' || jobType === 'standard_asb') {
        //Standard filing jobs require ASB data selection
        if(jobType !== 'backfill_asb' && (!asbDataSelection || !asbDataSelection.value || asbDataSelection.value.trim().length < 1)) {
          validationErrors.push('Missing ASB month(s).');
        }

        if(!environmentSelection || !environmentSelection.value || environmentSelection.value.trim().length < 1) {
          validationErrors.push('Missing environment selection.');
        }

        if(!patch01 && !patch05){
          validationErrors.push('Patch01 and Patch05 parsing are both disabled.');
        }
      }

      return validationErrors;
    }

    const createZestJob = async() => {
        setResponseLoading(true);
        setErrorWindowVisible(false);
        let validationErrors = validateJobOptions();

        if(validationErrors.length < 1) {
          const options = {
              headers: {
                  'Authorization': `Bearer ${getJwtToken()}`,
                  'Content-Type': 'application/json'
              }
          }
          const data = {
            job_id: jobId,
            job_description: jobDescription,
            params: zestRawParams.length > 0 ? zestRawParams : generateZestServiceParameterString()
          }
          
          let response = null;
          try {
              response = await axios.post(`${getApiURL()}/job`, data, options);
              if(response.status === 200) {
                window.location = '/jobs/' + response['data']; //Navigate to new Job detail view
              }
              else { //Display creation error message (API response)
                setErrorWindowVisible(true);
                setErrorWindowTitle('Failed to Create Job');
                setErrorWindowDescription(response && response['data'] ? response['data'] : 'Unknown error creating new job.');
              }
          } catch (e) { //Display creation error message (local)
              console.log(e);
              setErrorWindowVisible(true);
              setErrorWindowTitle('Failed to Create Job');
              setErrorWindowDescription((response != null && response['data']) ? response['data'] : e.toString());
          }
        }
        else {
          setErrorWindowVisible(true);
          setErrorWindowTitle('Invalid Job Parameters');
          const listItems = validationErrors.map((error) =>  <li>{error}</li>);
          setErrorWindowDescription(<ul>{listItems}</ul>);
        }
        setResponseLoading(false);
    }

    return (
        <Wizard
          i18nStrings={{
            stepNumberLabel: stepNumber =>
              `Step ${stepNumber}`,
            collapsedStepsLabel: (stepNumber, stepsCount) =>
              `Step ${stepNumber} of ${stepsCount}`,
            skipToButtonLabel: (step, stepNumber) =>
              `Skip to ${step.title}`,
            previousButton: "Previous",
            nextButton: "Next",
            submitButton: "Create job",
            optional: "optional",
          }}
          loading={responseLoading}
          isLoadingNextStep={responseLoading}
          onSubmit={createZestJob}
          onNavigate={({ detail }) =>
            setActiveStepIndex(detail.requestedStepIndex)
          }
          activeStepIndex={activeStepIndex}
          steps={[
            {
              title: "Job Type",
              //info: <Popover dismissButton={false} position="top" size="small" triggerType="custom" content={'hello'}><Button iconName="status-info" variant="icon">Copy</Button></Popover>,
              description: "Job type determines how the ASB is parsed and issues are filtered.",
              content: (
                <Container
                  header={
                    <Header variant="h2">
                      Select Job Type
                    </Header>
                  }
                >
                  <SpaceBetween direction="vertical" size="l">
                    <RadioGroup
                        onChange={({ detail }) => {
                          setJobType(detail.value)
                          if(detail.value === 'backfill_asb') {
                            setPatch01(false);
                            setGeneratePatches(false);
                            setGenerateTrackingTable(false);
                            setSendEmailNotification(false);
                          }
                        }}
                        value={jobType}
                        items={[
                            { 
                                value: "standard_asb", 
                                label: "Standard ASB Run" ,
                                description: "Perform a standard monthly Android Security Bulletin run."
                            },
                            { 
                                value: "backfill_asb", 
                                label: "Backfill ASB Run",
                                description: "Parses only kernel issues for a new FireOS device. Applicable issues in Android Security Bulletins since June 2016 are considered."
                            },
                            { 
                                value: "metrics", 
                                label: "Metrics Report",
                                description: "Generates a CSV metric report based on filed ASB Jiras"
                            }
                        ]}
                    />
                    <Textarea
                      rows={1}
                      onChange={({ detail }) => setJobDescription(detail.value)}
                      placeholder="Job description. For example, 'May 2023 ASB'."
                      value={jobDescription}
                    />
                  </SpaceBetween>
                </Container>
              )
            },
            {
              title: "Configure ASB Data",
              description: "Select the Android Security Bulletin month(s) to consider in this job. Optionally, new ASB data may be uploaded in zip format.",
              content: (
                <Container
                  header={
                    <Header variant="h2">
                      ASB Data Selection
                    </Header>
                  }
                >
                    <ASBDataSelector 
                        jobType={jobType} 
                        asbDataSelection={asbDataSelection} 
                        setASBDataSelection={setASBDataSelection}
                        environmentSelection={environmentSelection}
                        setEnvironmentSelection={setEnvironmentSelection}
                        deviceFamilySelection={deviceFamilySelection}
                        setDeviceFamilySelection={setDeviceFamilySelection}
                        metricsSLA={metricsSLA}
                        setMetricsSLA={setMetricsSLA}
                        metricsMonthSelection={metricsMonthSelection}
                        setMetricsMonthSelection={setMetricsMonthSelection}
                    />
                </Container>
              )
            },
            {
                title: "Advanced Job Options",
                description: "Job options are automatically selected based on job type. You may customize parameters for this job run.",
                content: (
                  <Container
                    header={
                      <Header variant="h2">
                        Configure Advanced Job Parameters
                      </Header>
                    }
                  >
                    {jobType !== 'metrics' &&
                    <SpaceBetween direction="vertical" size="l">
                        <DeviceSelector selectedOption={deviceSelection} setSelectedOption={setDeviceSelection} multiselect={false}/>
                        <FormField
                            description="Options related to ASB patch level processing and additional steps"
                            label="Parsing and Filing Options"
                        >
                            <ASBCheckbox
                                onChange={setPatch01}
                                checked={patch01 && jobType !== 'backfill_asb'}
                                disabled={jobType === 'backfill_asb'}
                                text={'Parse Patch01 (Kernel) issues'}
                            />
                            <ASBCheckbox
                                onChange={setPatch05}
                                checked={patch05 || jobType === 'backfill_asb'}
                                text={'Parse Patch05 (Platform) issues'}
                            />
                            <ASBCheckbox
                                onChange={setGenerateTrackingTable}
                                checked={generateTrackingTable && jobType !== 'backfill_asb'}
                                disabled={jobType === 'backfill_asb'}
                                text={'Generate Tracking Wiki Page'}
                            />
                        </FormField>
                        <FormField
                            description="Options related to additional filters and filing testing"
                            label="Advanced Options"
                        >
                            <Input
                                onChange={({ detail }) => setCVEFilters(detail.value)}
                                placeholder="Comma-separated CVE filters"
                                value={cveFilters}
                            />
                            <br/>
                            <Input
                                onChange={({ detail }) => setMaxFile(detail.value)}
                                placeholder="Maximum number of Jiras to file"
                                value={maxFile}
                                disabled={true}
                            />
                        </FormField>
                    </SpaceBetween>
                    }
                    {jobType === 'metrics' &&
                      <SpaceBetween direction="vertical" size="l">
                        <DeviceSelector selectedOption={metricsDeviceSelection} setSelectedOption={setMetricsDeviceSelection} multiselect={true}/>
                        <FormField
                            description="Optionally, specify a custom JQL query to override any parameters set in the previous step."
                            label="Custom JQL Query"
                        >
                            <Input
                                onChange={({ detail }) => setMetricsJQL(detail.value)}
                                value={metricsJQL}
                            />
                        </FormField>
                      </SpaceBetween>
                    }
                  </Container>
                )
              },
            {
              title: "Review and create",
              content: (
                <SpaceBetween size="xs">
                  <Header
                    variant="h3"
                    actions={
                      <Button
                        onClick={() => setActiveStepIndex(0)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Step 1: Job Type and Description
                  </Header>
                    <Container>
                      <ColumnLayout
                        columns={1}
                        variant="text-grid"
                      >
                        <div>
                          <Box variant="awsui-key-label">
                            Job Type
                          </Box>
                          <div><JobTypeBadge jobType={jobType}/></div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">
                              Job Description
                            </Box>
                            <div>{jobDescription ? jobDescription : '--'}</div>
                        </div>
                      </ColumnLayout>
                    </Container>
                  <Header
                    variant="h3"
                    actions={
                      <Button
                        onClick={() => setActiveStepIndex(1)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Step 2: Configure ASB Data
                  </Header>
                  <Container>
                    {jobType !== 'metrics' &&
                      <ColumnLayout
                        columns={1}
                        variant="text-grid"
                      >
                        <div>
                          <Box variant="awsui-key-label">
                            Patches
                          </Box>
                          <div>{jobType === 'backfill_asb' ? 'All' : (asbDataSelection && asbDataSelection.value ? asbDataSelection.value : '--')}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            Environment
                          </Box>
                          <div>{environmentSelection && environmentSelection.value ? environmentSelection.value : '--'}</div>
                        </div>
                      </ColumnLayout>
                    }
                    {jobType === 'metrics' &&
                      <ColumnLayout
                        columns={1}
                        variant="text-grid"
                      >
                        <div>
                          <Box variant="awsui-key-label">
                            Patches
                          </Box>
                          <div>
                            <GenericList options={metricsMonthSelection} empty={'All'}/>
                          </div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            Device Families
                          </Box>
                          <div>
                            <GenericList options={deviceFamilySelection}/>
                          </div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            Metrics SLA
                          </Box>
                          <div>{metricsSLA > 0 ? metricsSLA : '--'}</div>
                        </div>
                      </ColumnLayout>
                    }
                  </Container>
                  
                  <Header
                    variant="h3"
                    actions={
                      <Button
                        onClick={() => setActiveStepIndex(2)}
                      >
                        Edit
                      </Button>
                    }
                  >
                    Step 3: Advanced Job Options
                  </Header>
                  <Container>
                    {jobType !== 'metrics' &&
                      <ColumnLayout
                        columns={2}
                        variant="text-grid"
                      >
                        <div>
                          <Box variant="awsui-key-label">
                            Parsing and Filing Options
                          </Box>
                          <div><JobParsingFilingOptionList patch01={patch01} patch05={patch05} generatePatches={generatePatches} generateTrackingTable={generateTrackingTable} sendEmailNotification={sendEmailNotification}/></div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            Device Selection
                          </Box>
                          <div>{deviceSelection && deviceSelection.value ? deviceSelection.value : '--'}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            CVE Filter(s)
                          </Box>
                          <div>{cveFilters && cveFilters.length > 0 ? cveFilters : '--'}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            Maximum Jiras to File
                          </Box>
                          <div>{maxFile > 0 ? maxFile : 'All'}</div>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            Job ID
                          </Box>
                          <div>
                            {jobId} &nbsp;
                            <Button
                                iconName="refresh"
                                onClick={(e) => {e.preventDefault(); generateJobID()}}
                            >
                            </Button>	
                          </div>
                        </div>
                      </ColumnLayout>
                    }
                    {jobType === 'metrics' &&
                      <ColumnLayout
                        columns={2}
                        variant="text-grid"
                      >
                        <div>
                          <Box variant="awsui-key-label">
                            Device Selection
                          </Box>
                          <GenericList options={metricsDeviceSelection} empty={'All'}/>
                        </div>
                        <div>
                          <Box variant="awsui-key-label">
                            Custom JQL Query
                          </Box>
                          <div>{metricsJQL && metricsJQL.length > 0 ? metricsJQL : '--'}</div>
                        </div>
                      </ColumnLayout>
                    }
                  </Container>

                  <Header
                    variant="h3"
                  >
                    Advanced: Modify Zest Service Parameters
                  </Header>
                  <Container>
                    <Textarea
                      rows={1}
                      onChange={({ detail }) => setZestRawParams(detail.value)}
                      value={zestRawParams && zestRawParams.length > 0 ? zestRawParams : generateZestServiceParameterString()}
                    />
                  </Container>
                  <Alert
                    visible={errorWindowVisible}
                    type="error"
                    header={errorWindowTitle}
                  >
                    {errorWindowDescription}
                  </Alert>
                </SpaceBetween>
              )
            }
          ]}
        />
      );
}
