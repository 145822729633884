import * as React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import { environments } from '../Common/ZestConstants';

export default function EnvironmentSelectionModal(props) {
    return (
        <Modal
            onDismiss={() => props.setVisibilityCallback(false)}
            visible={props.visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button 
                            variant="normal" 
                            onClick={(e) => { 
                                e.preventDefault();
                                props.setEnvironmentCallback(null); 
                                props.setVisibilityCallback(false); 
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={(e) => { 
                                e.preventDefault(); 
                                props.setVisibilityCallback(false); 
                                props.submitCallback(); 
                            }}
                        >
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Select Environment"
        >
            <FormField>
                <RadioGroup
                    value={props.environment}
                    onChange={({ detail }) =>
                        props.setEnvironmentCallback(detail.value)
                    }
                    items={environments}
                />
            </FormField>
        </Modal>
    );
}