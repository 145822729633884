import React from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../app/actions";
import Container from "@amzn/awsui-components-react/polaris/container";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import TextContent from "@amzn/awsui-components-react/polaris/text-content";
import Link from "@amzn/awsui-components-react/polaris/link";

export default function AboutPage() {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setPageTitle('About Zest'));
    // eslint-disable-next-line
    }, [])

    return(
        <Container>
            <SpaceBetween direction="vertical" size="m">
                <TextContent>
                    <p>Zest is a automated service that handles parsing the monthly Android Security Bulletin, filing Jiras, generating patches, and creating metrics reports.</p>
                    <i>Special thanks to Jason Hosic for his original Zest codebase.</i>
                    <br/><br/>
                    <strong>Internal ASB resources:</strong>
                    <ul>
                        <li><Link external href="https://wiki.labcollab.net/confluence/display/DIGSECPUB/ASB+Lollipop+Mainline+Integration+Status">Monthly ASB Wiki</Link></li>
                        <li><Link external href="https://code.amazon.com/packages/AndroidBulletinsFos/trees/mainline">ASB Patches Repository</Link></li>
                        <li><Link external href="https://drive.corp.amazon.com/personal/scd/ASB%20STS">STS Test Case Drive</Link></li>
                    </ul>
                    <br/>
                    <strong>ASB resources from Google:</strong>
                    <ul>
                        <li><Link external href="https://drive.google.com/drive/folders/1q4ftXxCjU0nmLInjg3Oc7yQULoob8gAQ?resourcekey=0-TD5jJmS-R7v_rY_B4ZntQw" >ASB Google Drive</Link></li>
                        <li><Link external href="https://docs.partner.android.com/security/advisories">Partner Security Advisories</Link></li>
                        <li><Link external href="https://groups.google.com/g/android-partner-security">Android Partner Security Group</Link></li>
                    </ul>
                    <br/>
                    <strong>Source code for this dashboard and the Zest backend service can found here:</strong>
                    <ul>
                        <li><Link external href="https://code.amazon.com/packages/DigitalSecurityZestFrontendWeb/trees/mainline">Zest Dashboard</Link></li>
                        <li><Link external href="https://code.amazon.com/packages/Zest/trees/mainline">Zest Backend</Link></li>
                    </ul>
                </TextContent>
                <TextContent>
                    <h2><b>Contact Us</b></h2>
                    <p>This tool was created by <Link href="https://phonetool.amazon.com/users/scd">scd@</Link> and is owned by the <Link href="https://w.amazon.com/bin/view/DevicesSecurityAutomation/">Devices Security Automation Team</Link>.</p>
                </TextContent>
            </SpaceBetween>	
        </Container>
    ); 
}