import Badge from "@amzn/awsui-components-react/polaris/badge";

export default function JobTypeBadge(props) {
    let jobType = null;

    //Specify job type directly in params, or parse job type from job parameters
    if(props.jobType) {
        jobType = props.jobType;
    }
    else if(props.jobParams) {
        try { //Attempt to parse job type from mode in parameters
            let parameters = props.jobParams.split(' ');
            let index = 0;
            for(let i = 0; i < parameters.length; i++) {
                if(parameters[i] === '--mode') {
                    index = i;
                }
                if(parameters[i] === '--backfill') {
                    jobType = 'backfill_asb'; //We know it's a backfill if the backfill flag is set. We can ignore other mode options
                    break;
                }
            }

            if(jobType == null) {
                let mode = parameters[index+1];
                switch(mode.trim()) {
                    case 'metrics-report':
                        jobType = 'metrics';
                        break;
                    case 'parse-asb': //Fallthrough to standard
                    case 'generate-patches': //Fallthrough to standard
                    case 'file-jiras':
                        jobType = 'standard_asb'
                        break;
                    default:
                        jobType = 'unknown';
                        break;
                }
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    switch(jobType) {
        case 'metrics':
            return <Badge color="green">Metrics</Badge>;
        case 'backfill_asb':
            return <Badge color="red">Backfill</Badge>;
        default:
            return <Badge color="blue">Standard</Badge>;
    }
}