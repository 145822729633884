import React from 'react';
import Input from "@amzn/awsui-components-react/polaris/input";
import Select from "@amzn/awsui-components-react/polaris/select";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";

export default class JiraRequiredField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item,
            selectedOptions: this.props.item.schema.type === 'array' ? [] : {},
            selectedValue: ''
        };
      }
    
    render() {
        if(this.state.item.schema && this.state.item.schema.type) {
            switch(this.state.item.schema.type) {
                case "string":
                    return(
                        <Input
                            value={this.state.item.value}
                            onChange={({ detail }) => {	
                                this.setState({selectedValue: detail.value});
                                this.props.onChangeCallback(
                                    {
                                        id: this.state.item.fieldId,
                                        name: this.state.item.name,
                                        type: this.state.item.schema.type,
                                        value: detail.value,
                                        required: this.state.item.required
                                    }
                                );
                            }}
                        />	
                    );
                case "array":
                    let arrayOptions = [];
                    for(const option of this.state.item.allowedValues) {
                        let obj = {};
                        if(option.description) {
                            obj.description = option.description;
                        }
                        if(option.value) {
                            obj.id = option.value;
                            obj.value = option.value;
                            obj.label = option.value;
                        }
                        else if(option.name) {
                            obj.id = option.name;
                            obj.value = option.name;
                            obj.label = option.name;
                        }
                        arrayOptions.push(obj);
                    }
                
                    return(
                        <Multiselect
                            selectedOptions={this.state.item.value}
                            onChange={({ detail }) => {
                                this.setState({selectedOptions: detail.selectedOptions});
                                this.props.onChangeCallback(
                                    {
                                        id: this.state.item.fieldId,
                                        name: this.state.item.name,
                                        type: this.state.item.schema.type,
                                        value: detail.selectedOptions,
                                        required: this.state.item.required
                                    }
                                );
                                
                            }}
                            filteringType="auto"
                            deselectAriaLabel={e => "Remove " + e.label}
                            options = {arrayOptions}
                            placeholder="Choose options"
                            selectedAriaLabel="Selected"
                        />	
                    );
                case "option":
                    let optionOptions = [];
                    for(const option of this.state.item.allowedValues) {
                        let obj = {};
                        if(option.description) {
                            obj.description = option.description;
                        }
                        if(option.value) {
                            obj.value = option.value;
                            obj.id = option.value;
                            obj.label = option.value;
                        }
                        else if(option.name) {
                            obj.id = option.name;
                            obj.value = option.name;
                            obj.label = option.name;
                        }
                        optionOptions.push(obj);
                    }
                    return(
                        <Select
                            selectedOption={this.state.item.value}
                            onChange={({ detail }) =>
                                {
                                    this.setState({selectedOptions: detail.selectedOption});
                                    this.props.onChangeCallback(
                                        {
                                            id: this.state.item.fieldId,
                                            name: this.state.item.name,
                                            type: this.state.item.schema.type,
                                            value: detail.selectedOption,
                                            required: this.state.item.required
                                        }
                                    );
                                }
                            }
                            options={optionOptions}
                            selectedAriaLabel="Selected"
                            errorText={'Error loading Options'}
                            placeholder={'Options'}
                            loadingText={'Loading Options'}
                            empty={'No Options found'}
                        />
                    );
                default:
                    return null;
            }
        }
    }
}