import { useSelector, useDispatch } from "react-redux";
import { useCookies } from 'react-cookie';
import { setTheme } from '../../app/actions';
import SideNavigation from "@amzn/awsui-components-react/polaris/side-navigation";
import Toggle from "@amzn/awsui-components-react/polaris/toggle";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import "../../styles/header.scss"

function ThemeToggle(props) {
  return(
    <Toggle
      onChange={({ detail }) => {
        props.onChange(detail.checked);
      }}
      checked={props.state}
    >
      Dark Mode
    </Toggle>
  );
}

function SelfProfile(props) {
  let name = props.user;
  if(props.userDetails) {
    if(props.userDetails.person_details) {
      name = props.userDetails.person_details.name.includes(' ') ? props.userDetails.person_details.name.split(' ')[0] : props.userDetails.person_details.name;
    }
  }
  else {
    if(props.name) {
      name = props.name.includes(' ') ? props.name.split(' ')[0] : props.name;
    }
  }

  return(
    <SpaceBetween direction="horizontal" size="xs">
          <div><img className="circular-portrait small" src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + props.user} alt='Phonetool'/></div>
          <div style={{'padding': '10px 0'}}>
              Hello, <b>{name}</b>!
          </div>
    </SpaceBetween>
  );
}

export function Navigation (props) {
  const dispatch = useDispatch()

  const theme = useSelector((state) => state.currentViews.theme); //Get current theme
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['zest_dashboard']);
  const loggedInUser = useSelector((state) => state.currentViews.loggedInUser);
  const onThemeToggleChanged = (state) => {
    let toggle = theme === 'dark' ? 'light' : 'dark';
    dispatch(setTheme(toggle)); //Toogle state
    setCookie('theme', toggle, {path: '/'}); //Set cookie for persistence
  };

  const items = [
    {
      type: 'link',
      info: <SelfProfile user={loggedInUser ? loggedInUser : 'User'}/>
    },
    {
      type: 'divider'
    },
    {
      type: 'section',
      text: 'Jobs',
      items: [
        { type: 'link', text: 'Start New Job', href: '/jobs/create' },
        { type: 'link', text: 'Most Recent Job', href: '/jobs/latest' },
        { type: 'link', text: 'All Jobs', href: '/jobs'}
      ]
    },
    {
      type: 'section',
      text: 'Jiras',
      items: [
        { type: 'link', text: 'Search', href: '/jira/search' }
        //{ type: 'link', text: 'File', href: '/jira/file' }
      ]
    },
    {
      type: 'section',
      text: 'Configuration',
      items: [
        { type: 'link', text: 'Upload ASB Data', href: '/asb_upload' },
        { type: 'link', text: 'Upload Job File', href: '/config/job_file_upload' },
        //{ type: 'link', text: 'Onboard Device', href: '/onboard_device' },
        { type: 'link', text: 'Backfill Applicability Check', href: '/config/backfill_check' },
        //{ type: 'link', text: 'Global', href: '/config/global' }
      ]
    },
    {
      type: 'section',
      text: 'Help and Docs',
      items: [
        { type: 'link', text: 'About', href: '/about' }
      ]
    },
    {
      type: 'link',
      info: <ThemeToggle state={theme === 'dark' ? true : false} onChange={onThemeToggleChanged}/>
    }
  ];

  return (
    <div>
      <SideNavigation
        header={{ href: "/", text: "Zest Dashboard" }}
        items={items}
        activeHref={props.activeHref}
        />
    </div>
  );
}