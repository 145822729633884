import React from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setPageTitle } from "../../app/actions";
import axios from "axios";
import copy from 'copy-to-clipboard';
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { parseJiraFile } from "../Common/ParseUtils"
import {
    Container,
    SpaceBetween,
    Spinner
} from '@amzn/awsui-components-react/polaris';
import '../../styles/center.scss';
import JiraTable from '../Jira/JiraTable';
import FailedJiraTable from '../Jira/FailedJiraTable';
import PANNResultsPanel from './PANNResultsPanel';
import PANNVerifyPanel from './PANNVerifyPanel';
import CodeEditor from "@amzn/awsui-components-react/polaris/code-editor";
import Button from "@amzn/awsui-components-react/polaris/button";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';

function DownloadButton(props) {
    if(props.loadingURL) {
        return <Spinner />; 
    }
    else if(props.loadingArtifact) {
        return '';
    }
    else {
        return(<Button onClick={(e) => {e.preventDefault(); props.downloadFile(props.jobId, props.artifactId);}} iconName="download" variant="primary">Download File</Button>);
    }
}

function CopyButton(props) {
    if(props.loadingArtifact) {
        return '';
    }

    return(
        <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success">Copied file contents.</StatusIndicator>}
        >
            <Button 
                onClick={() => copy(props.contents)} 
                iconName="copy" 
                variant="primary"
            >
                Copy File Contents
            </Button>
        </Popover>
    );
}

function WikiOpenButton(props) {
    if(props.loadingArtifact) {
        return '';
    }
    
    return(
        <Button 
            onClick={(e) => {e.preventDefault(); window.open('https://wiki.labcollab.net/confluence/display/DIGSECPUB/ASB+Lollipop+Mainline+Integration+Status', '_blank', 'noopener noreferrer');}} 
            iconAlign="right"      
            iconName="external"      
            target="_blank"
        >
            Open ASB Wiki
        </Button>);
}

export default function JobArtifactPanel() {
    const dispatch = useDispatch();
    const [loadingArtifact, setLoadingArtifact] = React.useState(false); 
    const [loadingDownloadURL, setLoadingDownloadURL] = React.useState(false); 
    const [loadingAce, setLoadingAce] = React.useState(false);
    const [ace, setAce] = React.useState(undefined);
    const [jobArtifact, setJobArtifact] = React.useState(''); 
    const [preferences, setPreferences] = React.useState(undefined);
    const { job_id, artifact_id } = useParams();

    React.useEffect(() => {
        dispatch(setPageTitle('Artifact - ' + job_id + '/' + artifact_id));
        loadJobArtifact(job_id, artifact_id);
        loadAce()
    }, [job_id, artifact_id])

    const downloadFile = async(jobId, artifactId) => {
        setLoadingDownloadURL(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let response = await axios.get(`${getApiURL()}/job/artifact_download?job=${jobId}&id=${artifactId}`, options);
            if(response.status === 200) { //Open download link in new tab
                window.open(response.data, '_blank');
            }
        } catch (err) {
            console.log(err);
        }

        setLoadingDownloadURL(false);
    }

    const loadAce = () => {
        setLoadingAce(true);
        import('ace-builds')
            .then(ace =>
                import('ace-builds/webpack-resolver')
                .then(() => {
                    ace.config.set('useStrictCSP', true);
                    ace.config.set('loadWorkerFromBlob', false);
                    setAce(ace);
                    setLoadingAce(false);
                })
                .catch(() => setLoadingAce(false)
            )
            .catch(() => setLoadingAce(false)));
    }

    const loadJobArtifact = async(jobId, artifactId) => {
        setLoadingArtifact(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let response = await axios.get(`${getApiURL()}/job/artifact?job=${jobId}&id=${artifactId}`, options);
            setJobArtifact(response['data']);
        } catch (err) {
            console.log(err);
        }

        setLoadingArtifact(false);
    }

    if(jobArtifact) {
        //Display Jira table for failed/succeeded Jiras
        if(artifact_id.includes('failed_jiras') || artifact_id.includes('success_jiras')) {
            let jiraArtifact = {};
            try {
                jiraArtifact = JSON.parse(jobArtifact);
            }
            catch(err) {
                jiraArtifact = jobArtifact;
            }

            if(artifact_id.includes('failed_jiras')) {
                return(
                    <Container>
                        <SpaceBetween size="m">
                            <div align="left">
                                <SpaceBetween direction='horizontal' size="m">
                                    <DownloadButton loadingURL={loadingDownloadURL} loadingArtifact={loadingArtifact} downloadFile={downloadFile} jobId={job_id} artifactId={artifact_id}/>
                                    <CopyButton loadingArtifact={loadingArtifact} contents={jobArtifact}/>
                                </SpaceBetween>
                            </div>
                            <FailedJiraTable jira_server={jiraArtifact['jira_server'] ? jiraArtifact['jira_server'] : ''} jiras={parseJiraFile(jiraArtifact['jiras'])} />
                        </SpaceBetween>
                    </Container>
                )
            }
            else if(artifact_id.includes('success_jiras')) {
                return (
                    <Container>
                        <SpaceBetween size="m">
                            <div align="left">
                                <SpaceBetween direction='horizontal' size="m">
                                    <DownloadButton loadingURL={loadingDownloadURL} loadingArtifact={loadingArtifact} downloadFile={downloadFile} jobId={job_id} artifactId={artifact_id}/>
                                    <CopyButton loadingArtifact={loadingArtifact} contents={jobArtifact}/>
                                </SpaceBetween>
                            </div>
                            <JiraTable jira_server={jiraArtifact['jira_server'] ? jiraArtifact['jira_server'] : ''} jiras={parseJiraFile(jiraArtifact['jiras'])} />
                        </SpaceBetween>
                    </Container>
                )
            }
        }
        //Display PANN JQL view for ASB and Delorean results
        else if(artifact_id.includes('result') && (artifact_id.includes('asb') || (artifact_id.includes('backfill') || artifact_id.includes('delorean')))) {
            return (
                <Container>
                    <SpaceBetween size="m">
                        <div align="left">
                            <SpaceBetween direction='horizontal' size="m">
                                <DownloadButton loadingURL={loadingDownloadURL} loadingArtifact={loadingArtifact} downloadFile={downloadFile} jobId={job_id} artifactId={artifact_id}/>
                                <CopyButton loadingArtifact={loadingArtifact} contents={jobArtifact}/>
                            </SpaceBetween>
                        </div>
                        <PANNResultsPanel filename={artifact_id} jobId={job_id} results={jobArtifact} />
                    </SpaceBetween>
                </Container>
            )
        }
        //Display PANN verify view for PANN verify results
        else if(artifact_id.includes('pann-verify')) {
            return (
                <Container>
                    <SpaceBetween size="m">
                        <div align="left">
                            <SpaceBetween direction='horizontal' size="m">
                                <DownloadButton loadingURL={loadingDownloadURL} loadingArtifact={loadingArtifact} downloadFile={downloadFile} jobId={job_id} artifactId={artifact_id}/>
                                <CopyButton loadingArtifact={loadingArtifact} contents={jobArtifact}/>
                            </SpaceBetween>
                        </div>
                        <PANNVerifyPanel filename={artifact_id} jobId={job_id} results={jobArtifact} />
                    </SpaceBetween>
                </Container>
            )
        }
    }

    return(
        <Container>
            <SpaceBetween size="l">
            <div align="left">
                <SpaceBetween direction='horizontal' size="m">
                    <DownloadButton loadingURL={loadingDownloadURL} loadingArtifact={loadingArtifact} downloadFile={downloadFile} jobId={job_id} artifactId={artifact_id}/>
                    <CopyButton loadingArtifact={loadingArtifact} contents={jobArtifact}/>
                    {artifact_id === 'generated_tracking_table.html' &&
                        <WikiOpenButton loadingArtifact={loadingArtifact}/>
                    }
                </SpaceBetween>
            </div>
            <CodeEditor
                ace={ace}
                language="text"
                value={jobArtifact}
                preferences={preferences}
                onPreferencesChange={e => setPreferences(e.detail)}
                loading={loadingAce || loadingArtifact}
                i18nStrings={{
                    loadingState: "Loading artifact",
                    errorState:
                    "There was an error loading the artifact viewer.",
                    errorStateRecovery: "Retry",
                    editorGroupAriaLabel: "Artiface viewer",
                    statusBarGroupAriaLabel: "Status bar",
                    cursorPosition: (row, column) =>
                    `Ln ${row}, Col ${column}`,
                    errorsTab: "Errors",
                    warningsTab: "Warnings",
                    preferencesButtonAriaLabel: "Preferences",
                    paneCloseButtonAriaLabel: "Close",
                    preferencesModalHeader: "Preferences",
                    preferencesModalCancel: "Cancel",
                    preferencesModalConfirm: "Confirm",
                    preferencesModalWrapLines: "Wrap lines",
                    preferencesModalTheme: "Theme",
                    preferencesModalLightThemes: "Light themes",
                    preferencesModalDarkThemes: "Dark themes"
                }}
            />
            </SpaceBetween>
        </Container>
    )
}