import React from 'react';
import Badge from "@amzn/awsui-components-react/polaris/badge";
import PANNVerifyJiraTable from "../Jira/PANNVerifyJiraTable";
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween,
    Spinner
} from '@amzn/awsui-components-react/polaris';
import '../../styles/center.scss';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

function PANNRunBadge(props) {
    if(props.numPannAnalyzed > 0) {
        return <Badge color="green">Yes</Badge>;
    }
    return <Badge color="red">No</Badge>;
}

export default function PANNVerifyPanel(props) {
    const [loading, setLoading] = React.useState(false); 
    const [patch05Issues, setPatch05Issues] = React.useState([]);
    const [patch01Issues, setPatch01Issues] = React.useState([]);
    const [patch01IssuesDelorean, setPatch01IssuesDelorean] = React.useState([]);
    const [numPannAnalyzed, setNumPannAnalyzed] = React.useState(0); 

    React.useEffect(() => {
        parsePANNResults(props.results);
    }, [props.filename]);

    const parsePANNResults = async(results) => {
        setLoading(true);
        let numPannAnalyzed = 0;

        try {
            let issues = [];
            for(const issue of results['issues']['patch05']) {
                let pann_verified = false;
                let applicable = false;

                if('prediction' in issue) {
                    pann_verified = true;
                    applicable = issue['prediction'];
                    numPannAnalyzed += 1;
                }
                else {
                    pann_verified = false;
                    applicable = true;
                }

                issues.push({
                    'project': issue['project']['key'],
                    'summary': issue['summary'],
                    'type': 'patch01_delorean',
                    'pann_verified': pann_verified,
                    'applicable': applicable
                });
            }
            setPatch05Issues(issues);
        }
        catch(e){}

        try {
            let issues = [];
            for(const issue of results['issues']['patch01_delorean']) {
                let pann_verified = false;
                let applicable = false;

                if('prediction' in issue) {
                    pann_verified = true;
                    applicable = issue['prediction'];
                    numPannAnalyzed += 1;
                }
                else {
                    pann_verified = false;
                    applicable = true;
                }

                issues.push({
                    'project': issue['project']['key'],
                    'summary': issue['summary'],
                    'type': 'patch01_delorean',
                    'pann_verified': pann_verified,
                    'applicable': applicable
                });
            }
            setPatch01IssuesDelorean(issues);
        }
        catch(e){}

        try {
            let issues = [];
            for(const issue of results['issues']['patch01_standard']) {
                //Patch01 standard issues are not verified by PANN. Values are static here.
                issues.push({
                    'project': issue['project']['key'],
                    'summary': issue['summary'],
                    'type': 'patch01_delorean',
                    'pann_verified': false,
                    'applicable': true
                });
            }
            setPatch01Issues(issues);
        }
        catch(e){}

        setNumPannAnalyzed(numPannAnalyzed);
        setLoading(false);
    }

    if(loading) {
        return (
            <Container header={<Header variant="h3">PANN Results</Header>}>
                <div align="center">
                    <Spinner size="large" />
                </div>
            </Container>
        );
    }

    return(
        <SpaceBetween size="m">
            <Container 
                header={
                    <Header 
                        variant="h3"
                    >
                        PANN Results (Pre-filing)
                    </Header>
                }
            >
                <ColumnLayout columns={4} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="PANN Analysis Complete"><PANNRunBadge numPannAnalyzed={numPannAnalyzed} /></ValueWithLabel>
                        <ValueWithLabel label="Total Issues">{patch05Issues.length + patch01Issues.length + patch01IssuesDelorean.length}</ValueWithLabel>
                        <ValueWithLabel label="Patch05 Issues">{patch05Issues.length}</ValueWithLabel>
                        <ValueWithLabel label="Patch01 (Delorean)">{patch01IssuesDelorean.length}</ValueWithLabel>
                        <ValueWithLabel label="Patch01 (Standard)">{patch01Issues.length}</ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container 
                header={
                    <Header 
                        variant="h4"
                    >
                        Patch05 Jiras - {patch05Issues.length}
                    </Header>
                }
            >
                <PANNVerifyJiraTable jiras={patch05Issues} pann_run={props.numPannAnalyzed > 0 ? true : false} />
            </Container>
            <Container 
                header={
                    <Header 
                        variant="h4"
                    >
                        Patch01 Jiras (Delorean) - {patch01IssuesDelorean.length}
                    </Header>
                }
            >
                <PANNVerifyJiraTable jiras={patch01IssuesDelorean} pann_run={props.numPannAnalyzed > 0 ? true : false}/>
            </Container>
            <Container 
                header={
                    <Header 
                        variant="h4"
                    >
                        Patch01 Jiras (Standard) - {patch01Issues.length}
                    </Header>
                }
            >
                <PANNVerifyJiraTable jiras={patch01Issues} pann_run={props.numPannAnalyzed > 0 ? true : false}/>
            </Container>
        </SpaceBetween>
    )
}