export function parseVendor(model) {
    model = model.toLowerCase();
    if(model.includes("broadcom") || model.includes("bcm")) {
        return {
            id: "broadcom",
            display: "Broadcom"
        };
    }
    else if(model.includes("mediatek") || model.includes("mtk") || model.includes("mt")) {
        return {
            id: "mtk",
            display: "Mediatek"
        };
    }
    else if(model.includes("qualcomm") || model.includes("qca") || model.includes("qc")) {
        return {
            id: "qcomm",
            display: "Qualcomm"
        };
    }
    else if(model.includes("mstar")) {
        return {
            id: "mstar",
            display: "MStar"
        };
    }
    else if(model.includes("amlogic")) {
        return {
            id: "amlogic",
            display: "Amlogic"
        };
    }
    else if(model.includes("nxp")) {
        return {
            id: "nxp",
            display: "NXP"
        };
    }
    else if(model.includes("ti")) {
        return {
            id: "ti",
            display: "TI"
        };
    }
    else if(model.includes("intel")) {
        return {
            id: "intel",
            display: "Intel"
        };
    }
    return undefined;
}