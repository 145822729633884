import React from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import Badge from "@amzn/awsui-components-react/polaris/badge";

export default function PANNVerifyJiraTable(props) {
    let visibleColumns = [
        "project",
        "summary"
    ];
    if(props.pann_run) {
        visibleColumns.push("pann_verified");
        visibleColumns.push("file");
    }

    const [filteringText, setFilteringText] = React.useState("");
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [collectionPreferences, setCollectionPreferences] = React.useState({
        visibleContent: visibleColumns,
        wrapLines: true,
        custom: "table"
    })
    const [filteredJiras, setFilteredJiras] = React.useState([]);
    React.useEffect(() => {
        setFilteredJiras(props.jiras);
    }, [props.jiras])

    const columnDefinitions = [
        {
            id: "project",
            header: "Project",
            cell: (item) => item.project ? item.project : '--'
        },
        {
            id: "summary",
            header: "Summary",
            cell: (item) => item.summary ? item.summary : '--'
        },
        {
            id: "pann_verified",
            header: "PANN Verified",
            cell: (item) => {
                if(item.pann_verified) {
                    return <Badge color="green">Yes</Badge>;
                }
                else {
                    return <Badge color="red">No</Badge>;
                }
            }
        },
        {
            id: "file",
            header: "File",
            cell: (item) => {
                if(item.applicable) {
                    return <Badge color="green">Yes</Badge>;
                }
                else {
                    return <Badge color="red">No</Badge>;
                }
            }
        },
    ]
    
    return (
          <Table
            loading={props.loading}
            items={filteredJiras}
            columnDefinitions={columnDefinitions}
            loadingText={'Loading Jiras...'}
            trackBy='id'
            filter={
                <TextFilter
                  filteringPlaceholder="Find Jira"
                  filteringText={filteringText}
                  onChange={({ detail }) => {
                        let filter = detail.filteringText.toLowerCase().trim();

                        if(!filter === null || filter.length === 0) {
                            setFilteredJiras(props.jiras);
                        }
                        else {
                            let tmp = [];
                            for(const jira of props.jiras) {
                                let project = jira.project ? jira.project.toLowerCase() : '';
                                let summary = jira.summary ? jira.summary.toLowerCase() : '';

                                if(project.includes(filter) ||
                                summary.includes(filter)) {
                                    tmp.push(jira);
                                }
                            }
                            setFilteredJiras(tmp);
                        }
                        
                        setFilteringText(filter);
                    }
                  }
                />
            }
            selectedItems={selectedItems}
            visibleColumns={collectionPreferences.visibleContent}
            empty={
              <div className="awsui-util-t-c">
                  <div className="awsui-util-pt-s awsui-util-mb-xs">
                      <b>No Jiras</b>
                  </div>
                  <p className="awsui-util-mb-s">
                      No Jiras present.
                  </p>
              </div>	
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    onConfirm={({ detail }) => {setCollectionPreferences(detail)}}
                    preferences={collectionPreferences}
                    visibleContentPreference={{
                        title: "Select visible columns",
                        options: [
                        {
                            label: "Fields",
                            options: [
                                {
                                    id: "project",
                                    label: "Jira Project",
                                    editable: false
                                },
                                { id: "summary", label: "Summary" },
                                { id: "pann_verified", label: "PANN Verified" },
                                { id: "file", label: "File" }
                            ]
                        }
                        ]
                    }}
                />
            }
          >
          </Table>
      );
}
