import React from 'react';
import ClipboardCopy from "../Common/ClipboardCopy";
import ExternalLink from "../Common/ExternalLink";
import PANNResultJiraTable from "../Jira/PANNResultJiraTable";
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    SpaceBetween,
    Spinner
} from '@amzn/awsui-components-react/polaris';
import '../../styles/center.scss';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

function JiraLink(props) {
    if(props.issues && props.issues.length > 0) {
        return(
            <ValueWithLabel label={props.label}>
                <ExternalLink 
                    base={'https://issues.labcollab.net/issues/?jql=issuekey%20in%20'} 
                    value={'(' + props.issues.join(',') + ')'} 
                    text={props.issues.length}>
                </ExternalLink>
                <ClipboardCopy copyText={props.issues.join(',')} successMessage={'Jira IDs Copied.'}/>
            </ValueWithLabel>
        );
    }
    return '--';
}

export default function PANNResultsPanel(props) {
    const [loading, setLoading] = React.useState(false); 
    const [pannApplicable, setPANNApplicable] = React.useState([]); 
    const [pannNonApplicable, setPANNNonApplicable] = React.useState([]); 

    React.useEffect(() => {
        parsePANNResults(props.results);
    }, [props.filename]);

    const parsePANNResults = async(results) => {
        setLoading(true);
        
        let applicable = [];
        let non_applicable = [];

        for(const result of results['results']) {
            let pann_applicable = false;
            let compile_status = false;
            
            if(result['prediction']) {
                pann_applicable = result['prediction'];
            }
            if(result['enable']) {
                compile_status = result['enable'];
            }
            
            if(results['model'] && results['model'] === 'delorean_results') { //Set compile_status to True if Delorean.
                compile_status = true;
            }

            if(pann_applicable && compile_status) {
                applicable.push(result['id']);
            }
            else {
                non_applicable.push(result['id']);
            }
        }
        setPANNApplicable(applicable);
        setPANNNonApplicable(non_applicable);
        setLoading(false);
    }

    if(loading) {
        return (
            <Container header={<Header variant="h3">PANN Results</Header>}>
                <div align="center">
                    <Spinner size="large" />
                </div>
            </Container>
        );
    }

    return(
        <SpaceBetween size="m">
            <Container 
                header={
                    <Header 
                        variant="h3"
                    >
                        PANN Results (Post-filing)
                    </Header>
                }
            >
                <ColumnLayout columns={3} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Total Issues Analyzed">{pannApplicable.length + pannNonApplicable.length}</ValueWithLabel>
                        <JiraLink issues={pannApplicable} label={"Applicable Issues"}/>
                        <JiraLink issues={pannNonApplicable} label={"Non-Applicable Issues"}/>
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
            <Container 
                header={
                    <Header 
                        variant="h3"
                    >
                        Jiras - {props.results['results'].length}
                    </Header>
                }
            >
                <PANNResultJiraTable jiras={props.results['results']} />
            </Container>
        </SpaceBetween>
    )
}