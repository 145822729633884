import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import { AppLayout } from '@amzn/awsui-components-react';
import { auth } from './components/Common/Auth';
import { Navigation } from './components/Common/Navigation';
import { setTheme } from './app/actions';
import Header from "@amzn/awsui-components-react/polaris/header";
import PuffLoader from "react-spinners/PuffLoader";
import StatusBar from "./components/Common/StatusBar";
import JobsPage from "./components/Job/JobsPage";
import LatestJobPage from "./components/Job/LatestJobPage";
import JobDetailPage from "./components/Job/JobDetailPage";
import JobArtifactPage from "./components/Job/JobArtifactPage";
import JobCreatePage from "./components/Job/JobCreatePage";
import ASBUploadPage from "./components/Configuration/ASBUploadPage";
import DeviceOnboardingPage from "./components/Configuration/DeviceOnboardingPage";
import GlobalConfigPage from "./components/Configuration/GlobalConfigPage";
import BackfillApplicabilityPage from "./components/Configuration/BackfillApplicabilityPage";
import JiraSearchPage from "./components/Jira/JiraSearchPage";
import JiraFilePage from "./components/Jira/JiraFilePage";
import JobFileUploadPage from "./components/Configuration/JobFileUploadPage";
import AboutPage from "./components/Common/AboutPage"
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import '@amzn/awsui-global-styles/polaris.css';
import './styles/center.scss';

export default function App() {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(['zest_dashboard']);

  setCookie('path', window.location.pathname); //Save path to cookie in the event that we have to auth and lose the current path

  auth(dispatch); //Auth user 

  const userAlias = useSelector((state) => state.currentViews.loggedInUser);
  const pageTitle = useSelector((state) => state.currentViews.pageTitle);
  const [navigationOpen, setNavigationOpen] = React.useState(true);
  React.useEffect(() => {
    if('theme' in cookies && cookies.theme) {
      dispatch(setTheme(cookies.theme));
    }
    if('path' in cookies && cookies.path) { //Restore path from cookie and remove
      console.log("Redirect " + cookies.path);
      removeCookie('path');
    }
    // eslint-disable-next-line
  }, [])

  return userAlias ?
    (
      <div>
        <Router>
          <Fragment>
          <AppLayout
              disableContentPaddings={true}
              content={
                <div
                  style={{
                    padding: "5px 5px 5px 5px",
                  }}>
                  <Routes>
                    <Route exact path="/" element={< JobsPage />}></Route>
                    <Route exact path="/jobs" element={< JobsPage />}></Route>
                    <Route exact path="/jobs/create" element={< JobCreatePage />}></Route>
                    <Route exact path="/jobs/latest" element={< LatestJobPage />}></Route>
                    <Route exact path="/jobs/:id" element={< JobDetailPage />}></Route>
                    <Route exact path="/jobs/:job_id/artifact/:artifact_id" element={< JobArtifactPage />}></Route>
                    <Route exact path="/asb_upload" element={< ASBUploadPage />}></Route>
                    <Route exact path="/onboard_device" element={< DeviceOnboardingPage />}></Route>
                    <Route exact path="/config/global" element={< GlobalConfigPage />}></Route>
                    <Route exact path="/config/backfill_check" element={< BackfillApplicabilityPage />}></Route>
                    <Route exact path="/jira/search" element={< JiraSearchPage />}></Route>
                    <Route exact path="/jira/file" element={< JiraFilePage />}></Route>
                    <Route exact path="/config/job_file_upload" element={< JobFileUploadPage />}></Route>
                    <Route exact path="/about" element={<AboutPage />}></Route>
                  </Routes>
                </div>
              }
              contentHeader={
                <SpaceBetween size="xs">
                  <Header>{pageTitle}</Header>
                  <StatusBar/>
                </SpaceBetween>
              }
              navigation={<Navigation />}
              navigationOpen={navigationOpen}
              onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
              toolsHide={true}
          />
          </Fragment>
        </Router>
      </div>
    )
    :
    (
      <div class="centered">
        <PuffLoader color={"#0073bb"} loading={true} size={150} />
      </div>
    )
}