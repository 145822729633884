import { SET_LOGGED_IN_USER, SET_THEME, SET_PAGE_TITLE, SET_STATUS_BAR_MESSAGE } from "../actionTypes";

const initialState = {
  theme: 'light',
  loggedInUser: null,
  pageTitle: 'Zest Dashboard',
  statusBarMessage: null
};

export default function setView(state = initialState, action) {
  switch (action.type) {
    case SET_LOGGED_IN_USER: {
      return {
        ...state,
        loggedInUser: action.payload
      }
    }
    case SET_STATUS_BAR_MESSAGE: {
      return {
        ...state,
        statusBarMessage: action.payload
      }
    }
    case SET_THEME: {
      if(action.payload) {
        switch(action.payload.toLowerCase()) {
          case 'dark': //Enable dark mode
            document.body.classList.add('awsui-polaris-dark-mode');
            break;
          case 'light': //Disable dark mode
            document.body.classList.remove('awsui-polaris-dark-mode');
            break;
          default: //Not dark or light? Ignore
            break;
        }
      }

      return {
        ...state,
        theme: action.payload
      }
    }
    case SET_PAGE_TITLE: {
      return {
        ...state,
        pageTitle: action.payload
      }
    }
    default:
      return state;
  }
}
