import React from 'react';
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Select from "@amzn/awsui-components-react/polaris/select";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import JiraRequiredField from './JiraRequiredField';

function JiraField(props) {
    if(props.field) {
        return(
            <FormField
                label={props.field.name}
            >
                <JiraRequiredField item={props.field} onChangeCallback={props.saveJiraField}/>
            </FormField>
        );
    }

    return '';
}

export default function JiraAddFieldModal(props) {
    const [fieldData, setFieldData] = React.useState(null);
    const [selectedField, setSelectedField] = React.useState(null); 

    let availableFields = [];
    for(const field of props.availableFields) {
        if(!field || field.required) { //Do not display required fields
            continue;
        }

        if(field.fieldId === 'labels') { //Do not allow labels to be edited
            continue;
        }

        //Supported field types are string, array (multi-select), and option (single)
        if(field.schema.type !== "string" && field.schema.type !== "array" && field.schema.type !== "option") {
            continue;
        }

        //Do not allow users to configure fields that have already been configured
        let existing = false;
        for(const existingField of props.excludeFields) {
            if(existingField.fieldId === field.fieldId) {
                existing = true;
                break;
            }
        }
        if(existing) {
            continue;
        }

        availableFields.push({
            label: field.name,
            id: field.fieldId
        });
    }

    const saveJiraField = (params) => {
        let tmp = fieldData;

        if(tmp.schema.type === "string") {
            tmp.format = false;
            tmp.value = params.value;
        }
        else {
            tmp.value = params.value;
        }

        setFieldData(tmp);
    }

    return (
        <Modal
          onDismiss={() => props.setModalVisibility(false)}
          visible={props.visible}
          closeAriaLabel="Close"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={(e) => {
                    e.preventDefault(); 
                    setSelectedField(null);
                    setFieldData(null);
                    props.setModalVisibility(false);
                    }
                }>
                    Cancel
                </Button>
                <Button variant="primary" onClick={(e) => {
                        e.preventDefault(); 
                        props.saveJiraField(fieldData);  
                        setSelectedField(null);
                        setFieldData(null);
                        props.setModalVisibility(false);
                    }
                }>
                    Add
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Add Jira Field"
        >
          <SpaceBetween size="xs">
            <FormField 
                label="Field"
                description="Choose an available Jira field."
            >
                <Select
                    selectedOption={selectedField}
                    onChange={({ detail }) => {
                        setSelectedField(detail.selectedOption);
                        for(const field of props.availableFields) {
                            if(field.fieldId === detail.selectedOption.id) {
                                setFieldData(field);
                                break;
                            }
                        }
                    }}
                    options={availableFields}
                    selectedAriaLabel="Selected field"
                    empty="No fields available"
                />
            </FormField>
            <JiraField field={fieldData} saveJiraField={saveJiraField}/>
          </SpaceBetween>
        </Modal>
      );
}