import React, { useRef } from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle, setStatusBarMessage } from "../../app/actions";
import { formatBytes } from "../Common/FormatUtils"
import { parseJiraFile } from "../Common/ParseUtils"
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { ColumnLayout } from '@amzn/awsui-components-react/polaris';
import JobSelector from '../Job/JobSelector';
import PendingFileJiraPanel from './PendingFileJiraPanel';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

function UploadButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    else {
        if(!props.disabled) {
            if(props.job &&
             (props.selectedJiraFile && props.selectedJiraFile.type === 'application/json')
            ) {
                return(<Button onClick={(e) => {e.preventDefault(); props.uploadFile();}} variant="primary">Upload</Button>);
            }
        }
        return(<Button disabled variant="primary">Upload</Button>);
    }
}

function FileDetails(props) {
    if(props.selectedFile) {
        return(
            <Container
                header={
                    <Header
                        variant="h3"
                    >
                    File Details
                    </Header>
                }
                >
                <ColumnLayout columns={3} variant="text-grid">
                    <ValueWithLabel label="File name">{props.selectedFile.name}</ValueWithLabel>
                    <ValueWithLabel label="Size">{formatBytes(props.selectedFile.size)}</ValueWithLabel>
                    <ValueWithLabel label="Type">{props.selectedFile.type}</ValueWithLabel>
                </ColumnLayout>
            </Container>
        );
    }
    return '';
}

function PendingJiraView(props) {
    if(props.jiraArtifact) {
        return(
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Review pending Jiras to be filed."
                    >
                    Jiras
                    </Header>
                }
                >
                <PendingFileJiraPanel jira_server={props.jiraArtifact['jira_server'] ? props.jiraArtifact['jira_server'] : ''} jiras={parseJiraFile(props.jiraArtifact['jiras'])} />
            </Container>
        );
    }

    return '';
}

export default function JiraFilePage() {
    const dispatch = useDispatch();
    const jiraFileInputRef = useRef();
    const selectJiraFile = () => {
        jiraFileInputRef.current.click();
    }
    const [job, setJob] = React.useState(null);
    const [selectedJiraFile, setSelectedJiraFile] = React.useState(null);
    const [jiraArtifact, setJiraArtifact] = React.useState(null);
    const [environment, setEnvironment] = React.useState({ label: "Production", value: "production" });

    const [loading, setLoading] = React.useState(false);  
    const [uploadDisabled, setUploadDisabled] = React.useState(false);  

    React.useEffect(() => {
        dispatch(setPageTitle('ASB Jira Filing'));
    }, [])

    const uploadFile = async() => {
        setLoading(true);
        setJiraArtifact(null);
        dispatch(setStatusBarMessage(null));

        try {
            if(selectedJiraFile) {
                //Get presigned URL
                const options = {
                    headers: {
                        'Authorization': `Bearer ${getJwtToken()}`,
                        'Content-Type': 'application/json'
                    },
                };
                let jira_presigned_url_data = {
                    file_name: selectedJiraFile.name,
                    job_id: job.value
                };
                let jira_presigned_url_response = await axios.post(`${getApiURL()}/jira/upload`, jira_presigned_url_data, options);

                //Upload Jira File to S3
                let jira_payload = new FormData();
                Object.entries(jira_presigned_url_response.data.fields).forEach(([ key, val ]) => {
                    jira_payload.append(key, val);
                });
                jira_payload.append("file", selectedJiraFile);

                let jira_response = await axios.post(jira_presigned_url_response.data.url, jira_payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }    
                });

                if(jira_response.status < 200 || jira_response.status >= 300 ) {
                    throw 'Jira file upload failed.';
                }
                
                //Get Jira file contents
                let jira_artifact_response = await axios.get(`${getApiURL()}/job/artifact?job=${job.value}&id=${selectedJiraFile.name}`, options);
                setJiraArtifact(jira_artifact_response['data']);

                //TODO: For each Jira, call filing API and retrieve status
            }
            
            dispatch(setStatusBarMessage({type: 'success', message: 'Jiras have been uploaded for filing.'}));
            setUploadDisabled(true);
            setLoading(false);
        }
        catch(e) {
            console.log(e);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to file Jiras: ' + e}));
        }
        setLoading(false);
    }

    return (
        <SpaceBetween size='m'>
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Job and Environment Selection
                    </Header>
                }
                >
                <SpaceBetween size="s">
                    <JobSelector selectedOption={job} setSelectedOption={setJob}/>
                    <FormField
                        label="Environment"
                    >
                        <Select
                            selectedOption={environment}
                            onChange={({ detail }) =>
                                setEnvironment(detail.selectedOption)
                            }
                            options={[
                                { label: "Production", value: "production" },
                                { label: "Staging", value: "staging" }
                            ]}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                </SpaceBetween>
            </Container>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Choose a JSON file containing Zest Jiras to upload."
                    >
                    Jira Data File Selection
                    </Header>
                }
                >
                <SpaceBetween size='s'>
                    <input 
                        type="file" 
                        style={{ "display": "none" }} 
                        ref={jiraFileInputRef} 
                        onChange={e => {
                            setSelectedJiraFile(e.target.files[0]);
                            if(e.target.files[0].type !== 'application/json') {
                                dispatch(setStatusBarMessage({type: 'warning', message: 'Invalid file selection: Please select a JSON file containing Jira data.'}));

                            }
                            else {
                                dispatch(setStatusBarMessage(null));
                            }
                        }}
                    />
                    <Button
                        onClick={(e) => {e.preventDefault(); selectJiraFile();}}
                        iconAlign="right"
                        iconName="upload"
                        >
                        Choose file
                    </Button>
                    <FileDetails selectedFile={selectedJiraFile}/>
                    <div align="right">
                        <UploadButton selectedJiraFile={selectedJiraFile} job={job} uploadFile={uploadFile} loading={loading} disabled={uploadDisabled} />
                    </div>
                </SpaceBetween>
            </Container>
            <PendingFileJiraPanel jiraArtifact={jiraArtifact} job={job ? job.value : null} environment={environment} />
        </SpaceBetween>
    );
}