import React, { useRef, useCallback, useMemo } from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle, setStatusBarMessage } from "../../app/actions";
import { formatBytes } from "../Common/FormatUtils"
import { compareJiraChangelogs } from "../Common/CompareFunctions"
import { MONTHS } from '../Common/ZestConstants';
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { ColumnLayout } from '@amzn/awsui-components-react/polaris';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Button from "@amzn/awsui-components-react/polaris/button";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import AttributeEditor from "@amzn/awsui-components-react/polaris/attribute-editor";
import Select from "@amzn/awsui-components-react/polaris/select";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import ExpandableSection from "@amzn/awsui-components-react/polaris/expandable-section";
import ExternalLink from '../Common/ExternalLink';
import ASBMonthSelector from '../Job/ASBMonthSelector';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

function SearchResultsTable(props) {
    if(props.jiras) {
        let jira_url = 'issues.labcollab.net';
        if(props.environment && props.environment.value && props.environment.value === 'staging') {
            jira_url = 'issues-staging.labcollab.net';
        }

        return(
            <Table
            columnDefinitions={[
              {
                id: "id",
                header: "ID",
                cell: item => {
                    return(
                        <ExternalLink
                            base={'https://' + jira_url + '/browse/'}
                            value={item.id}
                        />
                    );
                },
                sortingField: "id"
              },
              {
                id: "summary",
                header: "Summary",
                cell: item => {
                    if(item.summary) {
                        return (<small>{item.summary}</small>);
                    }
                    return (<small>--</small>);
                },
                sortingField: "summary"
              },
              {
                id: "updates",
                header: "Updates",
                cell: item => {
                    if(props.jiraChangelog) {
                        if(props.jiraChangelog[item.id] && props.jiraChangelog[item.id]['changelog'] && props.jiraChangelog[item.id]['changelog'].length > 0) {
                            return (
                                <SpaceBetween size="xs">
                                    <Button
                                        onClick={(e) => {e.preventDefault(); props.setJiraChangelogModalVisible(true);}}
                                        iconAlign="left"
                                        iconName="status-warning"
                                    >
                                        {props.jiraChangelog[item.id]['changelog'].length + (props.jiraChangelog[item.id]['changelog'].length > 1 ? ' Updates' : ' Update')}
                                    </Button>
                                    <Modal
                                        onDismiss={() => props.setJiraChangelogModalVisible(false)}
                                        visible={props.jiraChangelogModalVisible}
                                        closeAriaLabel="Close"
                                        footer={
                                            <Box float="right">
                                            <SpaceBetween direction="horizontal" size="xs">
                                                <Button 
                                                    variant="link"
                                                    onClick={(e) => {e.preventDefault(); props.setJiraChangelogModalVisible(false);}}
                                                >
                                                    Close
                                                </Button>
                                            </SpaceBetween>
                                            </Box>
                                        }
                                        header={item.id + " Changelog"}
                                        size="large"
                                    >
                                        <Table
                                            columnDefinitions={[
                                                {
                                                    id: "date",
                                                    header: "Date",
                                                    cell: item => item.date || "--",
                                                    sortingField: "date"
                                                },
                                                {
                                                    id: "type",
                                                    header: "Type",
                                                    cell: item => {
                                                        switch(item.type) {
                                                            case "comment":
                                                                return <Badge color="blue">Comment</Badge>;
                                                            case "labels":
                                                                return <Badge color="green">Labels</Badge>;
                                                            case "attachment":
                                                                return <Badge color="red">Attachment</Badge>;
                                                            default:
                                                                return <Badge>{item.type}</Badge>;
                                                        }
                                                    },
                                                    sortingField: "type"
                                                },
                                                {
                                                    id: "change",
                                                    header: "Change",
                                                    cell: item => {
                                                        return (<small>{item.change || "--"}</small>);
                                                    }
                                                }
                                            ]}
                                            variant={"embedded"}
                                            wrapLines={true}
                                            items={props.jiraChangelog[item.id]['changelog'].sort(compareJiraChangelogs)}
                                            loadingText="Loading Changes"
                                            sortingDisabled
                                            empty={
                                                <Box textAlign="center" color="inherit">
                                                <b>No changes</b>
                                                <Box
                                                    padding={{ bottom: "s" }}
                                                    variant="p"
                                                    color="inherit"
                                                >
                                                    No changes for this Jira.
                                                </Box>
                                                </Box>
                                            }
                                        />
                                    </Modal>
                                </SpaceBetween>
                            );
                        }
                        else {
                            return '--';
                        }
                    }
                    else {
                        return <Spinner />; 
                    }
                },
                sortingField: "updates"
              }
            ]}
            onSelectionChange={({ detail }) =>
                props.setSelectedJiras(detail.selectedItems)
            }
            selectedItems={props.selectedJiras}
            selectionType="multi"
            items={props.jiras}
            empty={<b>No ASB Jiras found</b>}
            loadingText="Loading Jiras"
            sortingDisabled
            header={<Header>Search Results - {props.jiras.length}</Header>}
          />
        );
    }
    return '';
}

const LabelControl = React.memo(({ value, index, placeholder, setItems, prop }) => {
    return (
      <Input
        value={value}
        placeholder={placeholder}
        onChange={({ detail }) => {
          setItems(items => {
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              [prop]: detail.value,
            };
            return updatedItems;
          });
        }}
      />
    );
  });

function UpdateJiraForm(props) {
    if(props.selectedJiras && props.selectedJiras.length > 0) {
        return (
            <ExpandableSection 
                variant="container"
                header={
                    <Header
                        variant="h2"
                    >
                        Jira Update Options
                    </Header>
                }
            >
                <SpaceBetween size="m">
                    <FormField
                        label="Add Comment"
                    >
                        <Textarea
                            onChange={({ detail }) => props.setUpdateComment(detail.value)}
                            value={props.updateComment}
                        />
                    </FormField>
                    <FormField>
                        <AttributeEditor
                            items={props.jiraLabels}
                            definition={props.labelDefinition}
                            onAddButtonClick={props.onAddLabelButtonClick}
                            onRemoveButtonClick={props.onRemoveLabelButtonClick}
                            addButtonText="Add label"
                            removeButtonText="Remove"
                            empty="No labels configured."
                        />
                    </FormField>
                    <FormField
                        label="Add Attachment"
                    >
                        <input 
                            type="file" 
                            style={{ "display": "none" }} 
                            ref={props.fileInputRef} 
                            onChange={e => {
                                props.setSelectedFile(e.target.files[0]);
                            }}
                        />
                        <Button
                            onClick={(e) => {e.preventDefault(); props.selectFile();}}
                            iconAlign="right"
                            iconName="upload"
                            >
                            Choose file
                        </Button>
                    </FormField>
                    <FileDetails selectedFile={props.selectedFile}/>
                    <div align="right">
                        <UpdateButton selectedJiras={props.selectedJiras ? props.selectedJiras : []} loading={props.loading} updateJiras={props.updateJiras} />
                    </div>
                </SpaceBetween>
            </ExpandableSection>
        );
    }
    return '';
}

function FileDetails(props) {
    if(props.selectedFile) {
        return(
            <Container
                header={
                    <Header
                        variant="h4"
                    >
                    Attachment Details
                    </Header>
                }
                >
                <ColumnLayout columns={3} variant="text-grid">
                    <ValueWithLabel label="File name">{props.selectedFile.name}</ValueWithLabel>
                    <ValueWithLabel label="Size">{formatBytes(props.selectedFile.size)}</ValueWithLabel>
                </ColumnLayout>
            </Container>
        );
    }
    return '';
}

function SearchButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    return(<Button onClick={(e) => {e.preventDefault(); props.searchJiras();}} variant="primary">Search</Button>);
}

function UpdateButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    if(props.selectedJiras.length > 0) {
        return(<Button onClick={(e) => {e.preventDefault(); props.updateJiras();}} variant="primary">Update Jiras</Button>);
    }
    return(<Button disabled variant="primary">Update Jiras</Button>);
}

export default function JiraSearchPage() {
    const dispatch = useDispatch();
    const fileInputRef = useRef();
    const selectFile = () => {
        fileInputRef.current.click();
    }
   
    const [loading, setLoading] = React.useState(false);  
    const [cveId, setCVEId] = React.useState('');
    const [environment, setEnvironment] = React.useState({ label: "Production", value: "production" });
    const [asbDataSelection, setASBDataSelection] = React.useState({});
    const [matchedJiras, setMatchedJiras] = React.useState(null);
    const [selectedJiras, setSelectedJiras] = React.useState([])
    const [jiraChangelog, setJiraChangelog] =  React.useState(null);
    const [updateComment, setUpdateComment] = React.useState(null);
    const [updateLabels, setUpdateLabels] = React.useState([]);
    const [updateAttachment, setUpdateAttachment] = React.useState(null);
    const[jiraChangelogModalVisible, setJiraChangelogModalVisible] = React.useState(false); 

    React.useEffect(() => {
        dispatch(setPageTitle('ASB Jira Search'));
    }, [])

    const labelDefinition = useMemo(
        () => [
          {
            label: 'Add Labels',
            control: ({ key = '' }, itemIndex) => (
              <LabelControl prop="key" value={key} index={itemIndex} placeholder="Enter label text" setItems={setUpdateLabels} />
            )
          }
        ],
        []
    );

    const onAddLabelButtonClick = useCallback(() => {
        setUpdateLabels(items => [...items, {}]);
    }, []);

    const onRemoveLabelButtonClick = useCallback(({ detail: { itemIndex } }) => {
        setUpdateLabels(items => {
            const newItems = items.slice();
            newItems.splice(itemIndex, 1);
            return newItems;
        });
    }, []);

    //Load list of available devices
    const searchJiras = async() => {
        setLoading(true);
        setMatchedJiras(null);
        setSelectedJiras([]);
        setJiraChangelog(null);
        dispatch(setStatusBarMessage(null));
        setUpdateComment(null);
        setUpdateLabels([]);
        setUpdateAttachment(null);

        if(cveId && cveId.length > 0) {
            let params = {
                "search": {
                    "cve": cveId
                },
                "environment": environment['value']
            };
            
            if(asbDataSelection && asbDataSelection.label) { //Optional ASB month filter
                let month_name = asbDataSelection.label.split(' ')[0].trim();
                let year = asbDataSelection.label.split(' ')[1].trim();
                let month_num = -1;
                for(let i = 0; i < MONTHS.length; i++) {
                    if(MONTHS[i] === month_name) {
                        month_num = i;
                        break;
                    }
                }
                month_num = month_num < 10 ? '0' + month_num.toString() : month_num.toString();
                params['search']['asb_month'] = 'Android_Security_Bulletin_' + month_name + '_' + year + '-' + month_num;
            }

            const options = {
                headers: {
                    'Authorization': `Bearer ${getJwtToken()}`,
                    'Content-Type': 'application/json'
                },
                params: params
            }
        
            try {
                let ids = [];
                let search = [];
                //Get search results from Issue API
                let search_response = await axios.get(`${getApiURL()}/jira/update`, options);
                if(search_response.status === 200) {
                    if('data' in search_response && search_response['data'] && 'matched_jiras' in search_response['data'] && search_response['data']['matched_jiras']) {
                        search = search_response['data']['matched_jiras'];
                        for(const result of search) {
                            ids.push(result.id);
                        }
                    }
                    
                    const changelog_options = {
                        headers: {
                            'Authorization': `Bearer ${getJwtToken()}`,
                            'Content-Type': 'application/json'
                        }
                    }
                    
                    //Get changelog from Zest Jira records table
                    let changelog = {};
                    let record_response = await axios.get(`${getApiURL()}/jira?ids=` + ids.join(','), changelog_options);
                    if(record_response['data']) {
                        changelog = record_response['data'];
                    }
                    setJiraChangelog(changelog)

                }
                setMatchedJiras(search);
            } catch (e) {
                dispatch(setStatusBarMessage({type: "error", message: "Failed to search for Jiras: " + e}));
                console.log(e);
            }
        }

        setLoading(false);
    }

    const updateJiras = async() => {
        setLoading(true);
        dispatch(setStatusBarMessage(null));

        if(!selectedJiras || selectedJiras.length < 1) {
            dispatch(setStatusBarMessage({type: "warning", message: "No Jiras selected to update"}));
            return;
        }

        if((!updateComment || updateComment.length < 1) && (!updateAttachment) && (updateLabels.length < 1)) {
            dispatch(setStatusBarMessage({type: "warning", message: "No updates specified."}));
        }
        else if(updateAttachment && (!updateComment || updateComment.length < 1)) {
            dispatch(setStatusBarMessage({type: "error", message: "Attachment specified but no comment provided."}));
        }
        else {
            if(updateAttachment) {
                try {
                    //Get presigned URL
                    const presigned_url_options = {
                        headers: {
                            'Authorization': `Bearer ${getJwtToken()}`,
                            'Content-Type': 'application/json'
                        },
                    };
                    const presigned_url_data = {file_name: updateAttachment.name};
                    let presigned_url_response = await axios.post(`${getApiURL()}/jira/attachment`, presigned_url_data, presigned_url_options);
                    
                    //Upload data to S3
                    let payload = new FormData();
                    Object.entries(presigned_url_response.data.fields).forEach(([ key, val ]) => {
                        payload.append(key, val);
                    });
                    payload.append("file", updateAttachment); 
        
                    let response = await axios.post(presigned_url_response.data.url, payload, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }    
                    });

                    if(response.status < 200 || response.status >= 300 ) {
                        throw 'Attachment upload failed.';
                    }
                } catch (e) {
                    dispatch(setStatusBarMessage({type: "error", message: "Failed to update Jiras: " + e}));
                    console.log(e);
                    setLoading(false);
                    return;
                }
            }
            
            let params = {
                "jira_ids": [],
                "environment": environment['value']
            }
            for(const jira of selectedJiras) { //Add Jira IDs to request body
                params['jira_ids'].push(jira['id']);
            }
            if(updateComment) { //Add comment text to request body
                params['comment'] = updateComment;
            }
            if(updateAttachment) { //Add attachment name to request body
                params['attachment'] = updateAttachment.name;
            }
            if(updateLabels.length > 0) { //Add new labels to request body
                params['labels_append'] = [];
                for(const label of updateLabels) {
                    params['labels_append'].push(label.key);
                }
            }

            const options = {
                headers: {
                    'Authorization': `Bearer ${getJwtToken()}`,
                    'Content-Type': 'application/json'
                },
                params: params
            }
        
            try {
                let response = await axios.get(`${getApiURL()}/jira/update`, options);
                console.log(response.status);
    
                if(response['data']) {
                    dispatch(setStatusBarMessage({type: response.status === 200 ? 'success' : 'error', message: response['data']}));
                }
                
            } catch (e) {
                dispatch(setStatusBarMessage({type: "error", message: "Failed to update Jiras: " + e}));
                console.log(e);
            }
        }

        setLoading(false);
    }

    return (
        <SpaceBetween size='l'>
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                        Search Parameters
                    </Header>
                }
            >
                <SpaceBetween size="s">
                    <FormField
                        label="CVE ID"
                    >
                        <Input
                            onChange={({ detail }) => setCVEId(detail.value)}
                            value={cveId}
                        />
                    </FormField>
                    <FormField
                        label="Environment"
                    >
                        <Select
                            selectedOption={environment}
                            onChange={({ detail }) =>
                                setEnvironment(detail.selectedOption)
                            }
                            options={[
                                { label: "Production", value: "production" },
                                { label: "Staging", value: "staging" }
                            ]}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                    <ASBMonthSelector selectedOption={asbDataSelection} setSelectedOption={setASBDataSelection} optional={true}/>
                    <div align="right">
                        <SearchButton loading={loading} searchJiras={searchJiras} />
                    </div>
                </SpaceBetween>
            </Container>

            <SearchResultsTable 
                jiras={matchedJiras} 
                selectedJiras={selectedJiras}
                setSelectedJiras={setSelectedJiras}
                environment={environment}
                jiraChangelog={jiraChangelog}
                jiraChangelogModalVisible={jiraChangelogModalVisible}
                setJiraChangelogModalVisible={setJiraChangelogModalVisible}
            />
            <UpdateJiraForm 
                loading={loading}
                selectedJiras={selectedJiras} 
                updateComment={updateComment} 
                setUpdateComment={setUpdateComment}
                jiraLabels={updateLabels}
                setJiraLabels={setUpdateLabels}
                labelDefinition={labelDefinition}
                onAddLabelButtonClick={onAddLabelButtonClick}
                onRemoveLabelButtonClick={onRemoveLabelButtonClick}
                fileInputRef={fileInputRef}
                selectedFile={updateAttachment}
                setSelectedFile={setUpdateAttachment}
                selectFile={selectFile}
                updateJiras={updateJiras}
            />
        </SpaceBetween>
    );
}