import Table from "@amzn/awsui-components-react/polaris/table";
import Link from "@amzn/awsui-components-react/polaris/link";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import { formatBytes } from "../Common/FormatUtils"

export default function JobArtifactTable(props) {
    const columnDefinitions = [
        {
          id: "file",
          header: "File",
          cell: (item) => {
            try {
              return(<Link href={'/jobs/' + encodeURIComponent(props.job) + '/artifact/' + encodeURIComponent(item.key)}>{item.key}</Link>);
            }
            catch(err) {
              console.log(err);
              return(item.id);
            }
          }
        },
        {
          id: "type",
          header: "Type",
          cell: (item) => {
            try {
              if(item.key.includes("jiras")) {
                return <Badge color="red">Jiras</Badge>;
              }
              else if(item.key.includes("submit") || item.key.includes("patch")) {
                return <Badge color="green">Patches</Badge>;
              }
              else if(item.key.includes("log")) {
                return <Badge color="blue">Logs</Badge>;
              }
              else if(item.key.includes("metrics") || item.key.includes("csv")) {
                return <Badge color="green">Metrics</Badge>;
              }
              else if(item.key.includes("pann") || item.key.includes("asb") || item.key.includes("delorean")) {
                return <Badge>PANN</Badge>;
              }
              else {
                return <Badge>Other</Badge>;
              }
            }
            catch(err) {
              console.log(err);
              return <Badge>Other</Badge>;
            }
          }
        },
        {
            id: "size",
            header: "Size",
            cell: (item) => item.size ? formatBytes(item.size) : '--'
        },
        {
            id: "last_modified",
            header: "Last Modified",
            cell: (item) => item.last_modified ? item.last_modified : '--'
        }
    ]
    
    return (
        <div>
          <Table
            items={props.artifacts}
            columnDefinitions={columnDefinitions}
            trackBy='id'
            empty={
              <div className="awsui-util-t-c">
                  <div className="awsui-util-pt-s awsui-util-mb-xs">
                      <b>No Job Artifacts</b>
                  </div>
              </div>	
            }
          >
          </Table>
        </div>
      );
}
