import React from 'react';
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setPageTitle } from "../../app/actions";
import JobDetailsPanel from "./JobDetailPanel";
import JobArtifactPanel from "./JobArtifactPanel";
import JobJiraPanel from "./JobJiraPanel";

export default function JobDetailPage() {
    const dispatch = useDispatch();
    const { id } = useParams();

    React.useEffect(() => {
        dispatch(setPageTitle('Job - ' + id));
    }, [id])

    return (
        <div>
            <JobDetailsPanel id={id} />
            <br/>
            <JobArtifactPanel id={id} />
            <br/>
            <JobJiraPanel id={id} />
        </div>
    );
}