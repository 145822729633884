const config = {
    AppWebDomain: 'digital-security-automation-zest-prod.auth.us-west-2.amazoncognito.com',
    ClientId: '5ea1cf5heqqhv5k9cle1cuquqg',
    RedirectUriSignIn: window.location.origin,
    RedirectUriSignOut: window.location.origin,
    TokenScopesArray: ['openid', 'profile'],
    UserPoolId: 'us-west-2_Xr5UiEyb0'
}

export function getAuthConfig() {
    return config;
}