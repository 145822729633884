export const device_families = [
    {label: 'Tablets', value: 'tablets'},
    {label: 'Fire TV', value: 'firetv'},
    {label: 'Smart TV', value: 'smarttv'},
    {label: 'Echo', value: 'echo'},
    {label: 'E-Ink', value: 'eink'},
    {label: 'Computer Vision', value: 'cv'},
    {label: 'Vesta', value: 'vesta'}
];

export const jira_priorities = [
    {label: 'Critical', value: 'critical'},
    {label: 'Major', value: 'major'},
    {label: 'Minor', value: 'minor'},
    {label: 'Trivial', value: 'trivial'}
];

export const asb_types = [
    {label: 'RCE', value: 'RCE', description: 'Remote code execution'},
    {label: 'EoP', value: 'EoP', description: 'Elevation of privilege'},
    {label: 'ID', value: 'ID', description: 'Information disclosure'},
    {label: 'DoS', value: 'DoS', description: 'Denial of service'}
]

export const MONTHS = [
    '', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

export const environments = [
    {label: 'Staging', description: 'issues-staging.labcollab.net', value: 'staging'},
    {label: 'Production', description: 'issues.labcollab.net', value: 'production'}
];

export const ISSUES_PER_JQL = 100;