import copy from 'copy-to-clipboard';
import Button from "@amzn/awsui-components-react/polaris/button";
import Popover from "@amzn/awsui-components-react/polaris/popover";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";

export default function ClipboardCopy(props) {
    if(props.disabled) {
        return '';
    }

    if(!props.copyText || props.copyText.length < 1) {
        return '--';
    }

    return (
        <Popover
            size="small"
            position="right"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success">{props.successMessage}</StatusIndicator>}
        >
            <Button
                iconName="copy"
                variant="icon"
                onClick={() => {
                    copy(props.copyText)
                }}/>
        </Popover>
    );
}