import React from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import PatchLevelBadge from '../Common/PatchLevelBadge';
import ExternalLink from '../Common/ExternalLink';

export default function JiraTable(props) {
    const [filteringText, setFilteringText] = React.useState("");
    const [collectionPreferences, setCollectionPreferences] = React.useState({
        visibleContent: [
            "id",
            "project",
            "component",
            "summary",
            "patch_level",
            "actions"
        ],
        wrapLines: true,
        custom: "table"
    })
    const [filteredJiras, setFilteredJiras] = React.useState([]);
    React.useEffect(() => {
        setFilteredJiras(props.jiras);
    }, [props.jiras])

    const columnDefinitions = [
        {
          id: "id",
          header: "ID",
          cell: (item) => {
            try {
                if(item.error) {
                    return '--';
                }
                else {
                    return(
                        <ExternalLink
                            base={'https://issues.labcollab.net/browse/'}
                            value={item.id}
                        />
                    );
                }
            }
            catch(err) {
              console.log(err);
            }
          }
        },
        {
            id: "component",
            header: "Component",
            cell: (item) => item.component ? item.component : '--'
        },
        {
            id: "summary",
            header: "Summary",
            cell: (item) => item.summary ? item.summary : '--'
        },
        {
            id: "patch_level",
            header: "Type",
            cell: (item) => <PatchLevelBadge patch_level={item.patch_level}/>
        }
    ]
    
    return (
          <Table
            loading={props.loading}
            items={filteredJiras}
            columnDefinitions={columnDefinitions}
            loadingText={'Loading Jiras...'}
            trackBy='id'
            filter={
                <TextFilter
                  filteringPlaceholder="Find Jira"
                  filteringText={filteringText}
                  onChange={({ detail }) => {
                        let filter = detail.filteringText.toLowerCase().trim();

                        if(!filter === null || filter.length === 0) {
                            setFilteredJiras(props.jiras);
                        }
                        else {
                            let tmp = [];
                            for(const jira of props.jiras) {
                                let summary = jira.summary ? jira.summary.toLowerCase() : '';
                                let project = jira.project ? jira.project.toLowerCase() : '';
                                let component = jira.component ? jira.component.toLowerCase() : '';
                                let id = jira.id ? jira.id.toLowerCase() : '';

                                if(summary.includes(filter)
                                    || project.includes(filter)
                                    || component.includes(filter)
                                    || id.includes(filter)) {
                                    tmp.push(jira);
                                }
                            }
                            setFilteredJiras(tmp);
                        }
                        
                        setFilteringText(filter);
                    }
                  }
                />
            }
            visibleColumns={collectionPreferences.visibleContent}
            empty={
              <div className="awsui-util-t-c">
                  <div className="awsui-util-pt-s awsui-util-mb-xs">
                      <b>No Jiras</b>
                  </div>
                  <p className="awsui-util-mb-s">
                      No Jiras filed for this job.
                  </p>
              </div>	
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    onConfirm={({ detail }) => {setCollectionPreferences(detail)}}
                    preferences={collectionPreferences}
                    visibleContentPreference={{
                        title: "Select visible columns",
                        options: [
                        {
                            label: "Fields",
                            options: [
                                {
                                    id: "id",
                                    label: "Jira ID",
                                    editable: false
                                },
                                { id: "project", label: "Project" },
                                { id: "component", label: "Component" },
                                { id: "summary", label: "Summary" },
                                { id: "patch_level", label: "Type" },
                                { id: "actions", label: "Actions" }
                            ]
                        }
                        ]
                    }}
                />
            }
          >
          </Table>
      );
}
