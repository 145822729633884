import React from 'react';
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import JiraTable from '../Jira/JiraTable'
import {
    Container,
    Header,
    Button,
    Spinner,
    SpaceBetween
} from '@amzn/awsui-components-react/polaris';
import { MONTHS } from "../Common/ZestConstants";
import '../../styles/center.scss';

function JiraActionButton(props) {
    if(props.performingAction) {
        return <Spinner />; 
    }
    else if(props.loading) {
        return '';
    }
    
    return(
        <Button 
            onClick={(e) => {e.preventDefault(); props.callback();}} 
            disabled={props.disabled}
            iconAlign="right"      
            iconName="external"      
            target="_blank"
        >
            {props.label}
        </Button>);
}

export default function JobArtifactPanel(props) {
    const [loading, setLoading] = React.useState(false); 
    const [jobDetails, setJobDetails] = React.useState({});
    const [environment, setEnvironment] = React.useState('production');
    const [asbLabel, setASBLabel] = React.useState('');
    const [jiras, setJiras] = React.useState([]); 

    React.useEffect(() => {
        loadJiras(props.id);
    }, [props.id])

    const loadJiras = async(id) => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let jobDetailsResponse = await axios.get(`${getApiURL()}/job?id=` + id, options);
            let jobDetails = jobDetailsResponse['data'];
            setJobDetails(jobDetails);

            for(const param of jobDetails['parameters'].split('--')) { //Extract environment and patches from param string
                let key = param.split(' ')[0];
                if(key === 'environment') {
                    let environment = param.split(' ');
                    environment = environment[1].trim();
                    setEnvironment(environment);
                }
                else if(key === 'patches') {
                    let patches = param.split(' ');
                    patches = patches[1].trim();

                    //Parse month, month name, and year from patch string
                    patches = patches.replace('patches', ''); //Strip leading 'patches'
                    patches = patches.replace(/\D/g, ' ').trim(); //Replace non-numeric with spaces and trim
                    let year = patches.split(' ')[0];
                    let month_num = patches.split(' ')[1];
                    let month_name = MONTHS[parseInt(month_num)];

                    setASBLabel(`Android_Security_Bulletin_${month_name}_${year}-${month_num}`);
                }
            }

            let response = await axios.get(`${getApiURL()}/jira/search?job=` + id, options);
            setJiras(response['data']);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    const viewAllJiras = async() => {
        let baseURL = environment === 'production' ? 'issues.labcollab.net' : 'issues-staging.labcollab.net';
        let jqlString = `labels in (${asbLabel})`
        window.open(`https://${baseURL}/issues/?jql=${jqlString}`, '_blank', 'noopener noreferrer');
    }

    const viewDeloreanJiras = async() => {
        let baseURL = environment === 'production' ? 'issues.labcollab.net' : 'issues-staging.labcollab.net';
        let jqlString = `labels in (${asbLabel}) AND labels in (DeLorean)`
        window.open(`https://${baseURL}/issues/?jql=${jqlString}`, '_blank', 'noopener noreferrer');
    }

    if(loading) {
        return (
            <Container header={<Header variant="h2">Jiras</Header>}>
                <div align="center">
                    <Spinner size="large" />
                </div>
            </Container>
        );
    }

    return(
        <Container 
            header={
                <Header 
                    variant="h2"
                    actions={
                        <Button
                            iconName="refresh"
                            onClick={(e) => {e.preventDefault(); loadJiras(props.id)}}
                        >
                        </Button>	
                    }
                >
                    <SpaceBetween direction="vertical" size="m">
                        {"Jiras - " + jiras.length}
                        <SpaceBetween direction="horizontal" size="m">
                            <JiraActionButton
                                disabled={jiras.length < 1}
                                callback={viewAllJiras}
                                label="View All Jiras"
                                performingAction={false}
                                loading={loading}
                            />
                            <JiraActionButton
                                disabled={jiras.length < 1}
                                callback={viewDeloreanJiras}
                                label="View Delorean Jiras"
                                performingAction={false}
                                loading={loading}
                            />
                        </SpaceBetween>
                    </SpaceBetween>
                </Header>
            }
        >
        <JiraTable jira_server={'issues.labcollab.net'} jiras={jiras} />
        </Container>
    )
}