import Link from "@amzn/awsui-components-react/polaris/link";

export default function ExternalLink(props) {
    if(props.base && props.value && props.value.length > 0) {
        return(
            <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href={props.base + props.value}
                >
                {props.text ? props.text : props.value}
            </Link>
        );
    }

    return '--';
}