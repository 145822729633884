export function compareJobs(a, b) {
	let created_a = a.created ? a.created.replace('T', ' ') : a.created;
	let created_b = b.created ? b.created.replace('T', ' ') : b.created;

	if(created_a && created_b) { 
		//Latest created job first
		if(created_a > created_b) {
			return -1;
		}
		else if(created_a < created_b) {
			return 1;
		}

		//Latest completed job first
		if(a.completed > b.completed) {
			return -1;
		}
		else if(a.completed < b.completed) {
			return 1;
		}
	}

	if(created_a) {
		return -1;
	}
	return 1;
}

export function compareJiraChangelogs(a, b) {
	if(a.date > b.date) {
		return -1;
	}
	else if(a.date < b.date) {
		return 1;
	}
	return 0;
}

export function compareArtifacts(a, b) {
	if(a.last_modified < b.last_modified) {
		return 1;
	}
	else if(a.last_modified > b.last_modified) {
		return -1;
	}

	if(a.key < b.key) {
		return -1;
	}
	else if(a.key > b.key) {
		return 1;
	}
	return 0;
}