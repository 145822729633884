import { SET_THEME, SET_LOGGED_IN_USER, SET_PAGE_TITLE, SET_STATUS_BAR_MESSAGE } from "./actionTypes";

export const setLoggedInUser = id => ({
    type: SET_LOGGED_IN_USER,
    payload: id
});

export const setTheme = theme => ({
    type: SET_THEME,
    payload: theme
});

export const setPageTitle = title => ({
    type: SET_PAGE_TITLE,
    payload: title
});

export const setStatusBarMessage = statusBarMessage => ({
    type: SET_STATUS_BAR_MESSAGE,
    payload: statusBarMessage
});