import React, { useEffect } from 'react';
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Icon from "@amzn/awsui-components-react/polaris/icon";

const POLL_INTERVAL_MS = 1500;

function ChangeSetStatus(props) {
    if(props.changeSetData) {
        let statusIndicator = '';
        switch(props.changeSetData['status']) {
            case 'Submitted':
                statusIndicator = <Spinner/>;
                break;
            case 'In Progress':
                statusIndicator = <Spinner/>;
                break;
            case 'Failed':
                statusIndicator = <Icon name="status-negative" variant="error" />;
                break;
            case 'Complete':
                statusIndicator = <Icon name="status-positive" variant="success" />;
                break;
        }

        return(
            <SpaceBetween direction="horizontal" size="xxs">
                {statusIndicator}
                {props.changeSetData['description']}
            </SpaceBetween>
        )
    }
    return '';
}

export default function ChangeSetStatusPanel(props) {
    const [changeSetData, setChangeSetData] = React.useState({});

    const fetchChangeSetData = async(changeSets) => {
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        };

        try {
            for(const changeSet of changeSets) {
                let changeSetId = changeSet['id'];

                if(changeSetId in changeSetData && 'action' in changeSetData[changeSetId]) { //Ignore if 'action' not in the ChangeSet data. This means data came from local source, not API response.
                    if(changeSetData[changeSetId]['status'] !== 'Submitted' || changeSetData[changeSetId]['status'] !== 'In Progress') {
                        console.log('Skipping ' + changeSetId);
                        continue;
                    }
                }

                let response = await axios.get(`${getApiURL()}/config/changeset?id=` + changeSetId, options);
                console.log(response['data']);
                if(response['data']) {
                    let tmp = changeSetData;
                    tmp[changeSetId] = response['data'];
                    setChangeSetData(tmp);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if(props.changeSets && props.changeSets.length > 0) {
                let endPoll = true;
                //Stop polling when all ChangeSets have status != 'Submitted' or 'In Progress'
                for(const changeSet of props.changeSets) {
                    let changeSetId = changeSet['id'];
                    if(!changeSetData[changeSetId]) {
                        endPoll = false;
                        break;
                    }
                    else {
                        if(!changeSetData[changeSetId]['status'] || changeSetData[changeSetId]['status'] === 'Submitted' || changeSetData[changeSetId]['status'] === 'In Progress') {
                            endPoll = false;
                            break;
                        }
                    }
                }
                if(!endPoll) {
                    fetchChangeSetData(props.changeSets)
                }
                else {
                    clearInterval(interval) //Stop polling
                    props.setAddButtonDisabled(false);
                }
            }
        }, POLL_INTERVAL_MS);
        
        return () => clearInterval(interval);
    }, [])

    
    if(props.changeSets && props.changeSets.length > 0) {
        let changeSetViews = [];
        for(const changeSet of props.changeSets) {
            let changeSetId = changeSet['id'];
            if(changeSetId in changeSetData) {
                changeSetViews.push(<ChangeSetStatus changeSetData={changeSetData[changeSetId]}/>);
            }
            else {
                changeSetViews.push(<ChangeSetStatus changeSetData={changeSet}/>);
            }
        }

        return(
            <Container
                header={
                    <Header>
                    Onboarding Progress
                    </Header>
                }
            >
                <SpaceBetween direction="vertical" size="xs">
                    {changeSetViews}
                </SpaceBetween>
            </Container>
        )
    }

    return '';
}