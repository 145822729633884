export function parseJiraFile(jiraFileContents) {
    let jiras = [];
    for(const jira of jiraFileContents) {
        try {
            let jira_obj = {};
            if(jira['id']) {
                jira_obj['id'] = jira['id'];
            }
            else {
                jira_obj['id'] = '--';
            }
            jira_obj['project'] = jira['project']['key'];
            jira_obj['component'] = '--';
            if(jira['components'] && jira['components'].length > 0) {
                jira_obj['component'] = jira['components'][0];
                if(jira_obj['component']['id']) {
                    jira_obj['component'] = jira_obj['component']['id'];
                }
                else if(jira_obj['component']['name']) {
                    jira_obj['component'] = jira_obj['component']['name'];
                }
            }
            jira_obj['labels'] = jira['labels'];
            let asb_month_label = '';
            let patch_level = '';
            for(const label of jira_obj['labels']) {
                if(label.includes('Android_Security_Bulletin_')) {
                    asb_month_label = label.replace('Android_Security_Bulletin_', '');
                    asb_month_label = asb_month_label.replace('_', ' ');
                }
                else if(label === 'security-patch-level-05') {
                    patch_level = 'Patch05'
                }
                else if(label === 'security-patch-level-01') {
                    patch_level = 'Patch01'
                }
            }
            jira_obj['patch_level'] = patch_level;

            jira_obj['summary'] = jira['summary'].replace(jira_obj['project'] + ' - Android Security Bulletin - ' + asb_month_label + ' - ', '')
            jira_obj['summary'] = jira['summary'].replace(jira_obj['project'] + ' - Android Security Bulletin -' + asb_month_label + '- ', '')
            jira_obj['summary'] = jira['summary'].replace('- Android Security Bulletin', '')
            jira_obj['attachments'] = jira['attachments'];
            if(jira['error']) {
                jira_obj['error'] = jira['error'];
            }
            jiras.push(jira_obj);
        }
        catch(err) {
            console.log(err);
        }
    }
    return jiras;
}