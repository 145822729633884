import React from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle, setStatusBarMessage } from "../../app/actions";
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { normalizeValue } from '../Common/FormatUtils';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Table from "@amzn/awsui-components-react/polaris/table";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";

function DevicePropertiesTable(props) {
    if(props.devices && props.devices.length > 0) {
        if(Object.keys(props.devices[0]).length === 0) { //Empty device info corresponds to onboarded device match in device_family config file.
            return '';
        }

        return(
            <Table
            columnDefinitions={[
              {
                id: "device",
                header: "Device",
                cell: item => item.name || "-",
                sortingField: "name"
              },
              {
                id: "soc",
                header: "SoC",
                cell: item => item.soc || "-",
                sortingField: "soc"
              },
              {
                id: "wifi",
                header: "WiFi Chipset",
                cell: item => item.wifi || "-",
                sortingField: "wifi"
              },
              {
                id: "kernel_version",
                header: "Kernel Version",
                cell: item => item.kernel || "-",
                sortingField: "kernel_version"
              },
              {
                id: "kernel_repo",
                header: "Kernel Repo",
                cell: item => item.full_kernel_repo && item.full_kernel_repo.length > 0 ? item.full_kernel_repo : item.kernel_repo || "-",
                sortingField: "kernel_repo"
              },
              {
                id: "aosp",
                header: "AOSP",
                cell: item => item.aosp || "-",
                sortingField: "aosp"
              },
            ]}
            items={props.devices}
            empty={<b>No devices found</b>}
            loadingText="Loading devices"
            sortingDisabled
            header={<Header>{props.title}</Header>}
          />
        );
    }
    return '';
}

function StatusView(props) {
    if(props.message) {
        return(
            <Alert
                visible={true}
                type={props.type}
            >
                <SpaceBetween size="s">
                    {props.message}
                    <DevicePropertiesTable title={'Selected Device Properties'} devices={[props.selectedDevice]}/>
                    <DevicePropertiesTable title={'Matching Onboarded Devices'} devices={props.matchedDevices}/>
                </SpaceBetween>
            </Alert>
        );
    }
    return '';
}

function CheckButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    else {
        if(!props.disabled) {
            return(<Button onClick={(e) => {e.preventDefault(); props.checkApplicability();}} variant="primary">Check</Button>);
        }
        return(<Button disabled variant="primary">Check</Button>);
    }
}

function DeviceSelector(props) {
    if(props.loading) {
        return <Spinner />;
    }

    return (
        <FormField
        description="Select a device that has been onboarded with the Digital Security device repository."
        label="Choose a Device"
        >
            <Select
                selectedOption={props.selectedOption}
                empty="No devices available for use."
                onChange={({ detail }) =>
                    props.setSelectedOption(detail.selectedOption)
                }
                options={props.devices}
                selectedAriaLabel="Selected"
            />
        </FormField>
      );
}

export default function BackfillApplicabilityPage() {
    const dispatch = useDispatch();
   
    const [loading, setLoading] = React.useState(false);  
    const [statusMessage, setStatusMessage] = React.useState(null);  
    const [statusType, setStatusType] = React.useState('');  
    const [checkDisabled, setCheckDisabled] = React.useState(false);  
    const [loadingDevices, setLoadingDevices] = React.useState(false);
    const [devices, setDevices] = React.useState([]);
    const [deviceSelection, setDeviceSelection] = React.useState('');
    const [matchedDevices, setMatchedDevices] = React.useState([]);
    const [selectedDeviceInfo, setSelectedDeviceInfo] = React.useState({});

    React.useEffect(() => {
        dispatch(setPageTitle('Check Device Backfill Applicability'));
        loadDevices();
    }, [])

    //Load list of available devices
    const loadDevices = async() => {
        setLoadingDevices(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let tmp = [];
            let response = await axios.get(`${getApiURL()}/config/devices`, options);
            if(response['data']) {
                response['data'].sort()
                for(const device of response['data']) {
                    tmp.push({
                        label: device, value: device
                    });
                }
            }
            setDevices(tmp);
        } catch (e) {
            console.log(e);
        }

        setLoadingDevices(false);
    }

    const checkApplicability = async() => {
        setLoading(true);
        setCheckDisabled(true);
        setStatusMessage(null);
        dispatch(setStatusBarMessage(null));
        setMatchedDevices([]);
        setSelectedDeviceInfo({});
        setStatusType('');

        if(!deviceSelection || !deviceSelection.label) {
            dispatch(setStatusBarMessage({type: 'warning', message: 'Please select a device.'}));
        }
        else {
            const options = {
                headers: {
                    'Authorization': `Bearer ${getJwtToken()}`,
                    'Content-Type': 'application/json'
                },
            }
        
            try {
                //First get list of onboarded devices in device_family_config.json.
                let onboarded_devices = {};
                let response = await axios.get(`${getApiURL()}/config/file?name=device_family_config.json`, options);
                let device_families = response['data']['device_families'];
                for(const family of Object.keys(device_families)) {
                    for(const device of device_families[family]['devices']) {
                        onboarded_devices[device] = {};
                    }
                }
                
                if(deviceSelection.label in onboarded_devices) { //If device name matches an explicitly onboarded device, we don't need to continue SoC/WiFi/Kernel/FOS version checks.
                    setStatusType('success');
                    setStatusMessage(deviceSelection.label + ' is already onboarded with monthly ASB filing.');
                }
                else { //If no explicitly onboarded device found, we need to check the SoC/WiFi/Kernel/FOS version of each onboarded device against the selected device.
                    //Next get device config for all devices
                    response = await axios.get(`${getApiURL()}/config/devices?name=*`, options);
                    let device_info = response['data'];

                    let selected_device_info = null;
                    for(const device of device_info) {
                        let device_name = device['name'];
                        if(deviceSelection.label === device_name) {
                            selected_device_info = device;
                        }
                        else if(device_name in onboarded_devices) {
                            onboarded_devices[device_name] = device;
                        }
                    }

                    let backfillRequired = true;
                    let CONNBackfillRequired = true;
                    let matchingDevices = [];

                    //If kernel version, kernel repo, FOS version, and SoC are the same, no backfill is required.
                    if(selected_device_info) {
                        for(const device_name of Object.keys(onboarded_devices)) {
                            let device = onboarded_devices[device_name];
                            if(normalizeValue(device['soc']) === normalizeValue(selected_device_info['soc']) &&
                                device['kernel'] === selected_device_info['kernel'] &&
                                device['aosp'] === selected_device_info['aosp'] &&
                                (device['full_kernel_repo'] === selected_device_info['full_kernel_repo'] || device['kernel_repo'] === selected_device_info['kernel_repo'])) {
                                    matchingDevices.push(device);
                            }
                        }

                        if(matchingDevices.length > 0) {
                            backfillRequired = false;
                            //Additionally, check if WIFI/BT chipset or WIFI/BT repository are unique. In this case, only a CONN backfill is required.
                            for(const device of matchingDevices) {
                                if(normalizeValue(device['wifi']) === normalizeValue(selected_device_info['wifi']) &&
                                    device['wifi_repo'] === selected_device_info['wifi_repo']) {
                                        CONNBackfillRequired = false;
                                }
                            }

                            setSelectedDeviceInfo(selected_device_info);
                            setMatchedDevices(matchingDevices);

                            if(!backfillRequired && !CONNBackfillRequired) {
                                setStatusType('success');
                                setStatusMessage('No backfill or CONN backfill is required. The following matching device(s) are already onboarded: ');
                            }
                            else {
                                setStatusType('warning');
                                setStatusMessage('Only CONN backfill is required. The following matching device(s) are already onboarded: ');
                            }
                        }
                        else {
                            setStatusType('warning');
                            setStatusMessage('ASB Backfill is required for ' + deviceSelection.label + '.')
                        }
                    }
                    else {
                        dispatch(setStatusBarMessage({type: 'error', message: 'Failed to check device backfill applicability: Could not load device info for ' + deviceSelection.label}));
                    }
                }

            } catch (e) {
                console.log(e);
                dispatch(setStatusBarMessage({type: 'error', message: 'Failed to check device backfill applicability: ' + e}));
            }
        }

        setLoading(false);
        setCheckDisabled(false);
    }

    return (
        <SpaceBetween size='l'>
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Device Selection
                    </Header>
                }
            >
                <SpaceBetween size="s">
                    <DeviceSelector devices={devices} selectedOption={deviceSelection} setSelectedOption={setDeviceSelection} loading={loadingDevices}/>
                    <FormField
                        label="Backfill Criteria"
                    >
                        <ul>
                            <li>If the selected device has the same <b>kernel version</b>, <b>kernel repo</b>, <b>FOS version</b>, and <b>SoC</b> as another onboarded device, <u>no backfill is required</u>.</li>
                            <li>Additionally, if the selected device has the same <b>WiFi/BT chipset</b> and <b>WiFi repo</b> as another onboarded device, <u>no CONN backfill is required</u>.</li>
                            <li>Otherwise, backfill is required.</li>
                        </ul>   
                    </FormField>
                </SpaceBetween>
            </Container>
            <StatusView type={statusType} message={statusMessage} selectedDevice={selectedDeviceInfo} matchedDevices={matchedDevices}/>
            <div align="right">
                <CheckButton loading={loading} disabled={checkDisabled} checkApplicability={checkApplicability} />
            </div>
        </SpaceBetween>
    );
}