import React from 'react';
import axios from "axios";
import { useDispatch } from "react-redux";
import { setStatusBarMessage } from "../../app/actions";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import JobArtifactTable from './JobArtifactTable';
import EnvironmentSelectionModal from './EnvironmentSelectionModal';
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import Select from "@amzn/awsui-components-react/polaris/select";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import '../../styles/center.scss';
import { compareArtifacts } from "../Common/CompareFunctions";
import { environments, MONTHS } from "../Common/ZestConstants";

function JobActionButton(props) {
    if(props.performingAction) {
        return <Spinner />; 
    }
    else if(props.loading) {
        return '';
    }
    
    return(<Button onClick={(e) => {e.preventDefault(); props.callback(props.jobId, props.action, null);}} iconName={props.icon} variant="primary" disabled={props.disabled}>{props.label}</Button>);
}

function FileJirasModal(props) {
    return (
        <Modal
            onDismiss={() => props.setVisibilityCallback(false)}
            visible={props.visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button 
                            variant="normal" 
                            onClick={(e) => { 
                                e.preventDefault();
                                props.setEnvironmentCallback(null); 
                                props.setVisibilityCallback(false); 
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={(e) => { 
                                e.preventDefault(); 
                                props.setVisibilityCallback(false); 
                                props.submitCallback(); 
                            }}
                        >
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="File Jiras"
        >
            <SpaceBetween direction="vertical" size="xs">
                <FormField
                    label="ASB Jira Data"
                    description="Choose an artifact containing ASB Jira data. This can be a PANN verification file or a failed Jiras log."
                >
                    <Select
                        selectedOption={props.selectedFileArtifact}
                        onChange={({ detail }) => props.setSelectedFileArtifact(detail.selectedOption)}
                        options={props.fileArtifacts}
                    />
                </FormField>
                <FormField      
                    label="Environment"
                >
                    <RadioGroup
                        value={props.environment}
                        onChange={({ detail }) =>
                            props.setEnvironmentCallback(detail.value)
                        }
                        items={environments}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
}

function RunPANNModal(props) {
    return (
        <Modal
            onDismiss={() => props.setVisibilityCallback(false)}
            visible={props.visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button 
                            variant="normal" 
                            onClick={(e) => { 
                                e.preventDefault();
                                props.setVisibilityCallback(false); 
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={(e) => { 
                                e.preventDefault(); 
                                props.setVisibilityCallback(false); 
                                props.submitCallback(); 
                            }}
                        >
                            Ok
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Run PANN"
        >
            <SpaceBetween direction="vertical" size="xs">
                <FormField
                    label="ASB Jira Data"
                    description="Choose a PANN verification file."
                >
                    <Select
                        selectedOption={props.selectedPANNArtifact}
                        onChange={({ detail }) => props.setSelectedPANNArtifact(detail.selectedOption)}
                        options={props.pannArtifacts}
                    />
                </FormField>
            </SpaceBetween>
        </Modal>
    );
}

export default function JobArtifactPanel(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false); 
    const [jobArtifacts, setJobArtifacts] = React.useState([]);
    const [jobDetails, setJobDetails] = React.useState({});
    const [fileEnabled, setFileEnabled] = React.useState(false); 
    const [patchGenEnabled, setPatchGenEnabled] = React.useState(false);
    const [pannRunEnabled, setPannRunEnabled] = React.useState(false);
    const [createTrackingJiraEnabled, setCreateTrackingJiraEnabled] = React.useState(false);
    const [patchGenLoading, setPatchGenLoading] = React.useState(false); 
    const [pannRunLoading, setPannRunLoading] = React.useState(false); 
    const [fileJirasLoading, setFileJirasLoading] = React.useState(false); 
    const [trackingJiraLoading, setTrackingJiraLoading] = React.useState(false);
    const [latestSuccessJiraFile, setLatestSuccessJiraFile] = React.useState(null);
    const [fileJirasEnvironment, setFileJirasEnvironment] = React.useState(null);
    const [environmentModalFileVisible, setEnvironmentModalFileVisible] = React.useState(false);
    const [runPANNModalVisible, setRunPANNModalVisible] = React.useState(false);
    const [trackingTicketEnvironment, setTrackingTicketEnvironment] = React.useState(null);
    const [environmentModalTrackingJiraVisible, setEnvironmentModalTrackingJiraVisible] = React.useState(false);
    const [selectedFileArtifact, setSelectedFileArtifact] = React.useState(null);
    const [selectedPANNArtifact, setSelectedPANNArtifact] = React.useState(null);
    const [fileArtifacts, setFileArtifacts] = React.useState([]);

    React.useEffect(() => {
        loadJobArtifacts(props.id);
    }, [props.id])

    const loadJobArtifacts = async(id) => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let jobDetailsResponse = await axios.get(`${getApiURL()}/job?id=` + id, options);
            let jobDetails = jobDetailsResponse['data'];
            setJobDetails(jobDetails);

            let response = await axios.get(`${getApiURL()}/job/artifacts?job=` + id, options);
            let artifacts = response['data'];
            artifacts.sort(compareArtifacts);
            
            let jsonArtifacts = [];
            if(jobDetails['state'] === 'COMPLETED_SUCCESS' || jobDetails['state'] === 'COMPLETED_FAILURE') { //Do not display options for an in-progress job
                for(const artifact of artifacts) {
                    let file_ext = artifact.key.substring(artifact.key.lastIndexOf('.')+1).toLowerCase();
                    if(file_ext === 'json') {
                        if(artifact.key.includes('pann-verify') || artifact.key.includes('pann_verify')) { //We can file when there are PANN verify results. We don't necessarily need completed results, so don't check for 'completed' in the key
                            setFileEnabled(true);
                            setPannRunEnabled(true);
                        }
                        else if(artifact.key.includes('success-jiras') || artifact.key.includes('success_jiras')) { //Filing is done when a success_jiras_XXXX artifact is present. We can generate patches based on filing results
                            setPatchGenEnabled(true);
                            if(latestSuccessJiraFile === null) {
                                setLatestSuccessJiraFile(artifact.key);
                            }
                        }
                        jsonArtifacts.push({
                            label: artifact.key,
                            value: artifact.key,
                            key: artifact.key
                        });
                    }
                    else if(artifact.key === 'Fireos-8-CR-submit.txt') { //If CR submit file has been generated, we can create the tracking ticket
                        setCreateTrackingJiraEnabled(true);
                    }
                }
            }

            setJobArtifacts(artifacts);
            setFileArtifacts(jsonArtifacts);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    //Parameters: Job ID
    const createTrackingJira = async(id) => {
        setTrackingJiraLoading(true);
        dispatch(setStatusBarMessage(null));

        if(trackingTicketEnvironment === null) { //Invalid or missing environment selection
            dispatch(setStatusBarMessage({type: "error", message: "Environment selection required to file tracking Jira."}));
            setTrackingJiraLoading(false);
            return;
        }

        try {
            //Get job details and patches name
            let patches = null;
            for(const param of jobDetails['parameters'].split('--')) { //Extract patches name from param string
                if(param.includes('patches')) {
                    patches = param.split(' ');
                    patches = patches[1].trim();
                    break;
                }
            }

            //If we couldn't parse patches, throw exception
            if(patches === null) {
                throw 'Could not find parse patch data.';
            }

            //Parse month, month name, and year from patch string
            patches = patches.replace('patches', ''); //Strip leading 'patches'
            patches = patches.replace(/\D/g, ' ').trim(); //Replace non-numeric with spaces and trim
            let year = patches.split(' ')[0];
            let month_num = patches.split(' ')[1];
            let month_name = MONTHS[parseInt(month_num)];

            //File jira
            const options = {
                headers: {
                    'Authorization': `Bearer ${getJwtToken()}`,
                    'Content-Type': 'application/json'
                },
            };
            let jira_filing_data = {
                environment: trackingTicketEnvironment,
                asb_data: {
                    'month_name': month_name,
                    'month_num': month_num,
                    'year': year
                }
            };
            let file_jira_response = await axios.post(`${getApiURL()}/jira/tracking_ticket`, jira_filing_data, options);
            if(file_jira_response.status === 200) {
                //Open Jira to add attachments
                let baseURL = trackingTicketEnvironment === 'production' ? 'issues.labcollab.net' : 'issues-staging.labcollab.net';
                window.open(`https://${baseURL}/browse/${file_jira_response['data']}`, '_blank', 'noopener noreferrer');

                //Finally, display status message with filed ticket
                dispatch(setStatusBarMessage({type: "success", message: "Created tracking Jira: " + file_jira_response['data']}));
            }
            else { //Display creation error message (API response)
                dispatch(setStatusBarMessage({type: "error", message: "Failed to create tracking Jira: " + (file_jira_response && file_jira_response['data']) ? file_jira_response['data'] : 'Unknown error'}));
            }
        }
        catch(e) {
            console.log(e);
            dispatch(setStatusBarMessage({type: "error", message: "Failed to create tracking Jira: " + e.toString()}));
        }

        setTrackingJiraLoading(false);
    }

    const fileJirasCallback = async(id) => {
        setFileJirasLoading(true);
        dispatch(setStatusBarMessage(null));

        if(fileJirasEnvironment === null || selectedFileArtifact === null) {
            dispatch(setStatusBarMessage({type: "error", message: "Environment and artifact selection required to file Jiras."}));
        }
        else {
            //Restart the job in filing mode
            restartJobInMode(id, 'file-jiras', selectedFileArtifact.value);
        }
        
        setFileJirasLoading(false);
    }

    const runPANNCallback = async(job_id) => {
        setPannRunLoading(true);
        dispatch(setStatusBarMessage(null));

        if(job_id === null || selectedPANNArtifact === null) {
            dispatch(setStatusBarMessage({type: "error", message: "PANN verify artifact selection required to run PANN."}));
        }
        else {
            //Call frontend PANN integration endpoint 
            let response = null;
            const options = {
                headers: {
                    'Authorization': `Bearer ${getJwtToken()}`,
                    'Content-Type': 'application/json'
                },
            }

            let data = {
                'job': job_id,
                'artifact': selectedPANNArtifact['value']
            };
            
            try {
                response = await axios.post(`${getApiURL()}/job/pann`, data, options);
                if(response.status === 200) {
                    dispatch(setStatusBarMessage({type: "success", message: 'PANN job started successfully'}));
                    window.location = '/jobs/' + job_id; //Refresh page
                }
                else { //Display error message (API response)
                    dispatch(setStatusBarMessage({type: "error", message: "Failed to trigger PANN run: " + response && response['data'] ? response['data'] : 'Unknown error'}));
                }
            }
            catch(e) {
                dispatch(setStatusBarMessage({type: "error", message: "Failed to trigger PANN run: Unknown error"}));
            }
        }
        
        setPannRunLoading(false);
    }

    //Parameters: Job ID, restart mode 
    const restartJobInMode = async(id, type, file_input) => {
        switch(type) {
            case 'generate-patches':
                setPatchGenLoading(true);
                break;
            case 'file-jiras':
                setFileJirasLoading(true);
                break;
            default:
                return;
        }

        dispatch(setStatusBarMessage(null));

        let response = null;
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }

        try {
            let restartMessage = '';

            const data = {
                job_id: id,
                job_description: jobDetails['description'],
                restart: true
            } 

            let patches = null;
            for(const param of jobDetails['parameters'].split('--')) { //Extract patches name from param string
                if(param.includes('patches')) {
                    patches = param.split(' ');
                    patches = patches[1].trim();
                    break;
                }
            }

            switch(type) {
                case 'generate-patches':
                    data['params'] = `--run-id ${id} --success-jiras ${latestSuccessJiraFile} --patches ${patches} --mode generate-patches`;
                    restartMessage = "Restarted job in patch generation mode.";
                    break;
                case 'file-jiras':
                    let jiraInputFile = null;
                    let fileTypeFlag = null;

                    if(file_input !== null) {
                        jiraInputFile = file_input;
                        if(file_input.includes('pann-verify-complete')) {
                            fileTypeFlag = '--pann-results';
                        }
                        else {
                            fileTypeFlag = '--jira-data-file';
                        }
                    }
                    else {
                        for(const artifact of jobArtifacts) {
                            let file_ext = artifact.key.substring(artifact.key.lastIndexOf('.')+1).toLowerCase();
                            if(file_ext === 'json') {
                                if(artifact.key.includes('pann-verify-complete') || artifact.key.includes('pann_verify_complete')) { //PANN verify results
                                    jiraInputFile = artifact.key;
                                    fileTypeFlag = '--pann-results'
                                    break;
                                }
                            }
                        }
                    }

                    data['params'] = `--run-id ${id} ${fileTypeFlag} ${jiraInputFile} --patches ${patches} --environment ${fileJirasEnvironment} --mode file-jiras`;
                    restartMessage = "Restarted job in Jira filing mode.";
                    break;
                default:
                    return;
            }
            
            response = await axios.post(`${getApiURL()}/job`, data, options);
            if(response.status === 200) {
                dispatch(setStatusBarMessage({type: "success", message: restartMessage}));
                window.location = '/jobs/' + id; //Navigate to new Job detail view
            }
            else { //Display creation error message (API response)
                dispatch(setStatusBarMessage({type: "error", message: "Failed to restart job: " + response && response['data'] ? response['data'] : 'Unknown error'}));
            }
        } catch (e) { //Display creation error message (local)
            console.log(e);
            dispatch(setStatusBarMessage({type: "error", message: "Failed to restart job: " + e.toString()}));
        }

        setPatchGenLoading(false);
        setFileJirasLoading(false);
    }

    if(loading) {
        return (
            <Container header={<Header variant="h2">Artifacts</Header>}>
                <div align="center">
                    <Spinner size="large" />
                </div>
            </Container>
        );
    }

    return(
        <Container 
            header={
                <Header 
                    variant="h2"
                    actions={
                        <Button
                            iconName="refresh"
                            onClick={(e) => {e.preventDefault(); loadJobArtifacts(props.id)}}
                        >
                        </Button>	
                    }
                >
                    <SpaceBetween direction="vertical" size="m">
                        {"Artifacts - " + jobArtifacts.length}
                        <SpaceBetween direction="horizontal" size="m">
                            <JobActionButton
                                disabled={!pannRunEnabled}
                                callback={() => {
                                    setRunPANNModalVisible(true);
                                }}
                                jobId={props.id}
                                action={'run-pann'}
                                icon="suggestions"
                                label="Run PANN"
                                performingAction={pannRunLoading}
                                loading={loading}
                            />
                            <JobActionButton
                                disabled={!patchGenEnabled}
                                callback={restartJobInMode}
                                jobId={props.id}
                                action={'generate-patches'}
                                icon="script"
                                label="Generate CR Submit Patch"
                                performingAction={patchGenLoading}
                                loading={loading}
                            />
                            <JobActionButton
                                disabled={!fileEnabled}
                                callback={() => {
                                    setEnvironmentModalFileVisible(true);
                                }}
                                jobId={props.id}
                                action={'file-jiras'}
                                icon="bug"
                                label="File Jiras"
                                performingAction={fileJirasLoading}
                                loading={loading}
                            />
                            <JobActionButton
                                disabled={!createTrackingJiraEnabled}
                                callback={() => {
                                    setEnvironmentModalTrackingJiraVisible(true);
                                }}
                                jobId={props.id}
                                action={null}
                                icon="flag"
                                label="Create Tracking Jira"
                                performingAction={trackingJiraLoading}
                                loading={loading}
                            />
                        </SpaceBetween>
                        <FileJirasModal
                            visible={environmentModalFileVisible}
                            setVisibilityCallback={setEnvironmentModalFileVisible}
                            environment={fileJirasEnvironment}
                            setEnvironmentCallback={setFileJirasEnvironment}
                            submitCallback={() => {
                                fileJirasCallback(props.id);
                            }}
                            selectedFileArtifact={selectedFileArtifact}
                            setSelectedFileArtifact={setSelectedFileArtifact}
                            fileArtifacts={fileArtifacts}
                        />
                        <RunPANNModal
                            visible={runPANNModalVisible}
                            setVisibilityCallback={setRunPANNModalVisible}
                            submitCallback={() => {
                                runPANNCallback(props.id);
                            }}
                            selectedPANNArtifact={selectedPANNArtifact}
                            setSelectedPANNArtifact={setSelectedPANNArtifact}
                            pannArtifacts={fileArtifacts}
                        />
                        <EnvironmentSelectionModal
                            visible={environmentModalTrackingJiraVisible}
                            setVisibilityCallback={setEnvironmentModalTrackingJiraVisible}
                            environment={trackingTicketEnvironment}
                            setEnvironmentCallback={setTrackingTicketEnvironment}
                            submitCallback={() => {
                                createTrackingJira(props.id);
                            }}
                        />
                    </SpaceBetween>
                </Header>
            }
        >
            <JobArtifactTable job={props.id} artifacts={jobArtifacts} />
        </Container>
    )
}