import React, { useRef } from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle, setStatusBarMessage } from "../../app/actions";
import { formatBytes } from "../Common/FormatUtils"
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { ColumnLayout } from '@amzn/awsui-components-react/polaris';
import JobSelector from '../Job/JobSelector';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

function UploadButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    else {
        if(!props.disabled) {
            if(props.job &&
             (props.selectedFile && (props.selectedFile.type === 'application/json' || props.selectedFile.type === 'text/html'))
            ) {
                return(<Button onClick={(e) => {e.preventDefault(); props.uploadFile();}} variant="primary">Upload</Button>);
            }
        }
        return(<Button disabled variant="primary">Upload</Button>);
    }
}

function FileDetails(props) {
    if(props.selectedFile) {
        return(
            <Container
                header={
                    <Header
                        variant="h3"
                    >
                    File Details
                    </Header>
                }
                >
                <ColumnLayout columns={3} variant="text-grid">
                    <ValueWithLabel label="File name">{props.selectedFile.name}</ValueWithLabel>
                    <ValueWithLabel label="Size">{formatBytes(props.selectedFile.size)}</ValueWithLabel>
                    <ValueWithLabel label="Type">{props.selectedFile.type}</ValueWithLabel>
                </ColumnLayout>
            </Container>
        );
    }
    return '';
}

export default function JobFileUploadPage() {
    const dispatch = useDispatch();
    const fileInputRef = useRef();
    const selectFile = () => {
        fileInputRef.current.click();
    }
    const [job, setJob] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);

    const [loading, setLoading] = React.useState(false);  
    const [uploadDisabled, setUploadDisabled] = React.useState(false);  

    React.useEffect(() => {
        dispatch(setPageTitle('Upload Job File'));
    }, [])

    const uploadFile = async() => {
        setLoading(true);
        dispatch(setStatusBarMessage(null));

        try {
            if(selectedFile) {
                //Get presigned URL
                const file_presigned_url_options = {
                    headers: {
                        'Authorization': `Bearer ${getJwtToken()}`,
                        'Content-Type': 'application/json'
                    },
                };
                let file_presigned_url_data = {
                    file_name: selectedFile.name,
                    job_id: job.value
                };
                let file_presigned_url_response = await axios.post(`${getApiURL()}/config/asb_results`, file_presigned_url_data, file_presigned_url_options);

                //Upload File to S3
                let file_payload = new FormData();
                Object.entries(file_presigned_url_response.data.fields).forEach(([ key, val ]) => {
                    file_payload.append(key, val);
                });
                file_payload.append("file", selectedFile);

                let file_response = await axios.post(file_presigned_url_response.data.url, file_payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }    
                });

                if(file_response.status < 200 || file_response.status >= 300 ) {
                    throw 'File upload failed.';
                }
            }

            dispatch(setStatusBarMessage({type: 'success', message: 'File has been uploaded.'}));
            setUploadDisabled(true);
            setLoading(false);

            window.location = '/jobs/' + job.value; //Navigate to job detail view
        }
        catch(e) {
            console.log(e);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to upload file: ' + e}));
        }
        setLoading(false);
    }

    return (
        <SpaceBetween size='m'>
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Zest Job Selection
                    </Header>
                }
                >
                <JobSelector selectedOption={job} setSelectedOption={setJob}/>
            </Container>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Choose a file to upload and attach to the selected Zest Job."
                    >
                    File Selection
                    </Header>
                }
                >
                <SpaceBetween size='s'>
                    <input 
                        type="file" 
                        style={{ "display": "none" }} 
                        ref={fileInputRef} 
                        onChange={e => {
                            setSelectedFile(e.target.files[0]);
                            if(e.target.files[0].type !== 'application/json') {
                                dispatch(setStatusBarMessage({type: 'warning', message: 'Invalid file selection: Please select a JSON or HTML file.'}));
                            }
                            else {
                                dispatch(setStatusBarMessage(null));
                            }
                        }}
                    />
                    <Button
                        onClick={(e) => {e.preventDefault(); selectFile();}}
                        iconAlign="right"
                        iconName="upload"
                        >
                        Choose file
                    </Button>
                    <FileDetails selectedFile={selectedFile}/>
                </SpaceBetween>
            </Container>
            <div align="right">
                <UploadButton selectedFile={selectedFile} job={job} uploadFile={uploadFile} loading={loading} disabled={uploadDisabled} />
            </div>
        </SpaceBetween>
    );
}