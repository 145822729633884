import Popover from "@amzn/awsui-components-react/polaris/popover";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import { TextContent } from "@amzn/awsui-components-react";

function PANNStatus(props) {
    if(props.status && props.status === 'completed') {
        return (
            <div>
                {props.text}
                <Button
                    iconName="download"
                    variant="icon"
                    onClick={() => {
                        
                    }}
                />
            </div>
        );
    }
    
    return props.text;
}

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export default function PANNJobStatus(props) {
    console.log(props);
    if(!props.pannStatus || !props.pannJobId) { //No PANN job status or a PANN job was never started
        return('');
    }

    let statusIcon = '';
    let statusText = '';

    switch(props.pannStatus) {
        case 'new':
            statusIcon = 'pending';
            statusText = 'Pending';
            break;
        case 'in progress':
            statusIcon = 'in-progress';
            statusText = 'In progress';
            break;
        case 'error':
            statusIcon = 'error';
            statusText = 'Failed';
            break;
        case 'pending upload':
            statusIcon = 'in-progress';
            statusText = 'Uploading results';
            break;
        case 'completed':
            statusIcon = 'success';
            statusText = 'Complete';
            break;
        case 'load_error':
            statusIcon = 'error';
            statusText = 'Failed to get PANN job status';
            break;
    }

    return(
        <ValueWithLabel label="PANN Status">
            <div style={{paddingLeft: '10px'}}>
                <StatusIndicator type={statusIcon}>
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content={
                            <div>
                                <TextContent>
                                    <small><b>Job ID</b>: {props.pannJobId ? props.pannJobId : '--'}</small>
                                </TextContent>
                            </div>
                        }
                    >
                        <PANNStatus status={statusIcon} text={statusText}/>
                    </Popover>
                </StatusIndicator>
            </div>
        </ValueWithLabel>
    );
}