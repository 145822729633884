import React  from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle, setStatusBarMessage } from "../../app/actions";
import { jira_priorities } from "../Common/ZestConstants"
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { parseVendor } from '../Common/Vendor';
import ExternalLink from '../Common/ExternalLink';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import JiraFieldConfigurationPanel from '../Jira/JiraFieldConfigurationPanel';
import ChangeSetStatusPanel from './ChangeSetStatusPanel';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

function AddButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    if(props.disabled) {
        return(<Button variant="primary" disabled>Add Device</Button>); 
    }
    return(<Button onClick={(e) => {e.preventDefault(); props.addDevice();}} variant="primary">Add Device</Button>);
}

function DeviceDetails(props) {
    if(props.loading) {
        return <Spinner />; 
    }

    if(!props.deviceDetails) {
        return (
            <Alert
                visible={true}
                header="Device Selection Required"
            >
                Please select a device to view its properties.
            </Alert>
        );
    }

    return (
        <SpaceBetween size="xs">
            <ValueWithLabel label="Device Family">{props.deviceDetails['family'] ? props.deviceDetails['family'] : '--'}</ValueWithLabel>
            <ValueWithLabel label="AOSP">{props.deviceDetails['aosp'] ? props.deviceDetails['aosp'] : '--'}</ValueWithLabel>
            <ValueWithLabel label="SoC Vendor">{props.deviceDetails['soc_vendor'] ? props.deviceDetails['soc_vendor']['display'] : '--'}</ValueWithLabel>
            <ValueWithLabel label="Connectivity Vendor">{props.deviceDetails['wifi_vendor'] ? props.deviceDetails['wifi_vendor']['display'] : '--'}</ValueWithLabel>
            <ValueWithLabel label="Jira Project">
                <ExternalLink
                    base={'https://issues.labcollab.net/projects/'}
                    value={props.deviceDetails['jira_project']}
                />
            </ValueWithLabel>
        </SpaceBetween>
    );
}

function DeviceSelector(props) {
    if(props.loading) {
        return <Spinner />;
    }

    return (
        <FormField
        description="Select a device that has been onboarded with the Digital Security device repository."
        label="Choose a Device"
        >
            <Select
                selectedOption={props.selectedOption}
                empty="No devices available for use."
                onChange={({ detail }) =>
                    props.setSelectedOption(detail.selectedOption)
                }
                options={props.devices}
                selectedAriaLabel="Selected"
            />
        </FormField>
      );
}

export default function DeviceOnboardingPage() {
    const dispatch = useDispatch();

    //Response
    const [loading, setLoading] = React.useState(false);
    const [addButtonDisabled, setAddButtonDisabled] = React.useState(false);
    const [changeSets, setChangeSets] = React.useState([]);

    //Device list
    const [loadingDevices, setLoadingDevices] = React.useState(false);
    const [devices, setDevices] = React.useState([]);
    const [deviceSelection, setDeviceSelection] = React.useState('');

    //Device details
    const [loadingDeviceDetails, setLoadingDeviceDetails] = React.useState(false); 
    const [deviceDetails, setDeviceDetails] = React.useState(null);

    //Jira config
    const [availableJiraFields, setAvailableJiraFields] = React.useState([]);
    const [requiredJiraFields, setRequiredJiraFields] = React.useState([]);
    const [otherJiraFields, setOtherJiraFields] = React.useState([]);
    const [jiraPriority, setJiraPriority] = React.useState(jira_priorities[0]);
    const [jiraLabels, setJiraLabels] = React.useState([]);
    const [jiraAddDeviceFamilyLabel, setJiraAddDeviceFamilyLabel] = React.useState(true);
    const [jiraAddDueDate, setJiraAddDueDate] = React.useState(true);


    React.useEffect(() => {
        dispatch(setPageTitle('Onboard New Device'));
        loadDevices();
    }, [])

    //Sets deviceSelection and calls loadDeviceDetails()
    const saveDeviceSelection = (selectedDevice) => {
        //Clear existing config
        dispatch(setStatusBarMessage(null));
        setAvailableJiraFields([]);
        setRequiredJiraFields([]);
        setOtherJiraFields([]);
        setJiraPriority(jira_priorities[0]);
        setJiraLabels([]);
        setJiraAddDeviceFamilyLabel(true);
        setJiraAddDueDate(true);

        setDeviceSelection(selectedDevice);
        loadDeviceDetails(selectedDevice.value);
    }

    //Load list of available devices
    const loadDevices = async() => {
        setLoadingDevices(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let tmp = [];
            let response = await axios.get(`${getApiURL()}/config/devices`, options);
            if(response['data']) {
                response['data'].sort()
                for(const device of response['data']) {
                    tmp.push({
                        label: device, value: device
                    });
                }
            }
            setDevices(tmp);
        } catch (e) {
            console.log(e);
        }

        setLoadingDevices(false);
    }

    //Load device details for a selected device
    const loadDeviceDetails = async(device) => {
        setLoadingDeviceDetails(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let response = await axios.get(`${getApiURL()}/config/devices?name=` + device, options);
            let details = response['data'];
            if(details['soc']) {
                details['soc_vendor'] = parseVendor(details['soc']);
            }
            if(details['wifi']) {
                details['wifi_vendor'] = parseVendor(details['wifi']);
            }

            setDeviceDetails(response['data']);
        } catch (e) {
            console.log(e);
        }

        setLoadingDeviceDetails(false);
    }

    const addDevice = async() => {
        //Verify device properties
        dispatch(setStatusBarMessage(null));
        let missingFields = [];
        let verificationPass = true;
        
        let fields_check = [
            {'key': 'family', 'display': 'Device Family'},
            {'key': 'aosp', 'display': 'AOSP'},
            {'key': 'soc_vendor', 'display': 'SoC Vendor'},
            {'key': 'wifi_vendor', 'display': 'Connectivity Vendor'},
            {'key': 'jira_project', 'display': 'Jira Project'}
        ];

        if(deviceDetails) {
            for(const field of fields_check) {
                if(!deviceDetails[field['key']]) {
                    missingFields.push(field['display']);
                    verificationPass = false;
                }
            }

            if(!verificationPass) {
                dispatch(setStatusBarMessage({type: 'warning', message: 'Missing required device properties: ' + missingFields.join(', ') + '. Please update the Digital Security device repository and try again.'}));
                return;
            }
        }
        else {
            dispatch(setStatusBarMessage({type: 'warning', message: 'Please select a valid device.'}));
            return;
        }

        //Verify Jira fields are available (we have the necessary permissions!)
        if(!availableJiraFields || availableJiraFields.length < 1) {
            dispatch(setStatusBarMessage({type: 'warning', message: 'The Zest bot account is missing permissions for the ' + deviceDetails['jira_project'] + ' Jira project.'}));
            return;
        }


        //Verify required Jira fields are present
        if(requiredJiraFields && requiredJiraFields.length > 0) {
            for(const field of requiredJiraFields) {
                if(!field || !field.value ||
                    (field.schema.type === 'string' && field.value.trim().length < 1) || //Empty string
                    ((field.schema.type === 'array' || field.schema.type === 'option') && field.value.length < 1) //Empty array or dict
                ) {
                    missingFields.push(field.name);
                    verificationPass = false;
                }
            }
        }

        if(!verificationPass) {
            dispatch(setStatusBarMessage({type: 'warning', message: 'Missing required Jira fields: ' + missingFields.join(', ')}));
            return;
        }

        //Submit changes to API
        setLoading(true);
        setAddButtonDisabled(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        };

        //Add device name to device_family_config
        try {
            const device_family_config = {
                file: 'device_family_config.json',
                action: 'ADD_DEVICE',
                description: 'Add ' + deviceSelection.value + ' device to device family configuration',
                changes: [
                    {
                        'device': deviceSelection.value,
                        'family': deviceDetails['family']
                    }
                ]
            };
            let device_family_response = await axios.post(`${getApiURL()}/config/changeset`, device_family_config, options);
            let tmp = changeSets;
            tmp.push({
                id: device_family_response.data,
                description: device_family_config['description'],
                status: 'Submitted'
            });
            setChangeSets(tmp);
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setAddButtonDisabled(false);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to onboard device: ' + e}));
            return;
        }

        //Add device Jira project to patch05_jira_mapping under vendor
        //Add device connectivity and device connectivity backfill Jira project to patch05_jira_mapping under vendor
        try {
            const patch05_jira_mapping_config = {
                file: 'patch05_jira_mapping.json',
                action: 'ADD_DEVICE',
                description: 'Add ' + deviceDetails['jira_project'] + ' project to patch05 vendor Jira mapping',
                changes: [
                    {
                        'jira_project': deviceDetails['jira_project'],
                        'connectivity_vendor': deviceDetails['wifi_vendor']['id'],
                        'soc_vendor': deviceDetails['soc_vendor']['id']
                    }
                ]
            };
            console.log(patch05_jira_mapping_config);
            let patch05_jira_mapping_response = await axios.post(`${getApiURL()}/config/changeset`, patch05_jira_mapping_config, options);
            let tmp = changeSets;
            tmp.push({
                id: patch05_jira_mapping_response.data,
                description: patch05_jira_mapping_config['description'],
                status: 'Submitted'
            });
            setChangeSets(tmp);
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setAddButtonDisabled(false);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to onboard device: ' + e}));
            return;
        }

        //Add device Jira project to AOSP Jira project global map
        try {
            const aosp_jira_project_config = {
                file: 'asb_config.json',
                action: 'ADD_DEVICE',
                description: 'Add ' + deviceDetails['jira_project'] + ' project to AOSP Jira mapping',
                changes: [
                    {
                        'jira_project': deviceDetails['jira_project'],
                        'aosp': deviceDetails['aosp']
                    }
                ]
            };
            console.log(aosp_jira_project_config);
            let aosp_jira_project_config_response = await axios.post(`${getApiURL()}/config/changeset`, aosp_jira_project_config, options);
            let tmp = changeSets;
            tmp.push({
                id: aosp_jira_project_config_response.data,
                description: aosp_jira_project_config['description'],
                status: 'Submitted'
            });
            setChangeSets(tmp);
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setAddButtonDisabled(false);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to onboard device: ' + e}));
            return;
        }

        //Add Jira field config to patch05_jira_project_field_config
        try {
            const patch05_jira_project_field_config = {
                file: 'patch05_jira_project_field_config.json',
                action: 'ADD_DEVICE',
                description: 'Add ' + deviceDetails['jira_project'] + ' project to patch05 Jira field configuration',
                changes: [
                    {
                        'jira_project': deviceDetails['jira_project'],
                        'jira_config': {
                            "default": {
                                "enabled": true,
                                "project": deviceDetails['jira_project'],
                                "device": deviceSelection.value.toUpperCase(),
                                "priority": jiraPriority.label,
                                "add_device_family_label": jiraAddDeviceFamilyLabel,
                                "add_due_date": jiraAddDueDate,
                                "labels": [],
                                "custom_fields": []
                            }
                        }
                    }
                ]
            };

            for(const label of jiraLabels) {
                patch05_jira_project_field_config['changes'][0]['jira_config']['default']['labels'].push(label.key);
            }

            for(const field of requiredJiraFields) {
                if(field.fieldId === 'components') {
                    patch05_jira_project_field_config['changes'][0]['jira_config']['default']['components'] = field.value[0].value;
                }
                else {
                    let custom_field = {
                        'key': field.fieldId,
                        'description': field.name
                    }

                    switch(field.schema.type) {
                        case 'string':
                            custom_field['format'] = false;
                        case 'option':
                            custom_field['value'] = field.value.value;
                            break;
                        case 'array':
                            custom_field['value'] = [];
                            for(const value_obj of field.value) {
                                custom_field['value'].push(value_obj.value);
                            }
                    }
                    patch05_jira_project_field_config['changes'][0]['jira_config']['default']['custom_fields'].push(custom_field);
                }
            }
            for(const field of otherJiraFields) {
                if(field.fieldId === 'components') {
                    patch05_jira_project_field_config['changes'][0]['jira_config']['default']['components'] = field.value[0].value;
                }
                else {
                    let custom_field = {
                        'key': field.fieldId,
                        'description': field.name
                    }

                    switch(field.schema.type) {
                        case 'string':
                            custom_field['format'] = false;
                        case 'option':
                            custom_field['value'] = field.value;
                            break;
                        case 'array':
                            custom_field['value'] = [];
                            for(const value_obj of field.value) {
                                custom_field['value'].push(value_obj.value);
                            }
                    }
                    patch05_jira_project_field_config['changes'][0]['jira_config']['default']['custom_fields'].push(custom_field);
                }
            }
            
            console.log(patch05_jira_project_field_config);
            let patch05_jira_project_field_response = await axios.post(`${getApiURL()}/config/changeset`, patch05_jira_project_field_config, options);
            let tmp = changeSets;
            tmp.push({
                id: patch05_jira_project_field_response.data,
                description: patch05_jira_project_field_config['description'],
                status: 'Submitted'
            });
            setChangeSets(tmp);
        }
        catch (e) {
            console.log(e);
            setLoading(false);
            setAddButtonDisabled(false);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to onboard device: ' + e}));
            return;
        }

        setLoading(false);
        dispatch(setStatusBarMessage({type: 'success', message: 'Device onboarding in progress...'}));
    }

    return (
        <SpaceBetween size='l'>
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Device Selection
                    </Header>
                }
            >
                <SpaceBetween size="s">
                    <DeviceSelector devices={devices} selectedOption={deviceSelection} setSelectedOption={saveDeviceSelection} loading={loadingDevices}/>
                </SpaceBetween>
            </Container>
            
            <Container
                header={
                    <Header
                        variant="h2"
                        description={deviceSelection ? "Please verify the following properties are correct for " + deviceSelection.value + "." : ''}
                    >
                    Device Properties
                    </Header>
                }
            >
                <DeviceDetails deviceDetails={deviceDetails} loading={loadingDeviceDetails}/>
            </Container>

            <JiraFieldConfigurationPanel 
                device_properties={deviceDetails}
                availableJiraFields={availableJiraFields}
                setAvailableJiraFields={setAvailableJiraFields}
                requiredJiraFields={requiredJiraFields}
                setRequiredJiraFields={setRequiredJiraFields} 
                otherJiraFields={otherJiraFields}
                setOtherJiraFields={setOtherJiraFields}
                jiraPriority={jiraPriority}
                setJiraPriority={setJiraPriority}
                jiraLabels={jiraLabels}
                setJiraLabels={setJiraLabels}
                jiraAddDeviceFamilyLabel={jiraAddDeviceFamilyLabel}
                setJiraAddDeviceFamilyLabel={setJiraAddDeviceFamilyLabel}
                jiraAddDueDate={jiraAddDueDate}
                setJiraAddDueDate={setJiraAddDueDate}
            />

            <ChangeSetStatusPanel changeSets={changeSets} setAddButtonDisabled={setAddButtonDisabled}/>
            <div align="right">
                <AddButton addDevice={addDevice} loading={loading} disabled={addButtonDisabled}/>
            </div>
        </SpaceBetween>
    );
}