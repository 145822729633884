import React, { useCallback, useMemo } from 'react';
import { asb_types } from '../Common/ZestConstants';
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Input from "@amzn/awsui-components-react/polaris/input";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import AttributeEditor from "@amzn/awsui-components-react/polaris/attribute-editor";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";

const LabelControl = React.memo(({ value, index, placeholder, setItems, prop }) => {
    return (
      <Input
        value={value}
        placeholder={placeholder}
        onChange={({ detail }) => {
          setItems(items => {
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              [prop]: detail.value,
            };
            return updatedItems;
          });
        }}
      />
    );
  });

export default function TypeLabelConfigurationAddModal(props) {
    const [description, setDescription] = React.useState("");  
    const [types, setTypes] = React.useState([]);  
    const [labels, setLabels] = React.useState([]);  

    const jira_label_definition = useMemo(
        () => [
          {
            label: 'Jira Labels',
            control: ({ key = '' }, itemIndex) => (
              <LabelControl prop="key" value={key} index={itemIndex} placeholder="Enter label text" setItems={setLabels} />
            )
          }
        ],
        []
    );

    const onAddLabelButtonClick = useCallback(() => {
        setLabels(items => [...items, {}]);
    }, []);

    const onRemoveLabelButtonClick = useCallback(({ detail: { itemIndex } }) => {
        setLabels(items => {
            const newItems = items.slice();
            newItems.splice(itemIndex, 1);
            return newItems;
        });
    }, []);
    
    
    return (
        <Modal
          onDismiss={() => props.setModalVisibility(false)}
          visible={props.visible}
          closeAriaLabel="Close"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={(e) => {
                    e.preventDefault(); 
                    setDescription("");
                    setTypes([]);
                    setLabels([]);
                    props.setModalVisibility(false);
                    }
                }>
                    Cancel
                </Button>
                <Button variant="primary" onClick={(e) => {
                    e.preventDefault(); 
                    setDescription("");
                    setTypes([]);
                    setLabels([]);
                    props.addNewTypeLabelMapping(description, types, labels);
                    props.setModalVisibility(false);
                    }
                }>
                    Add
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Add Type Label Mapping"
        >
          <SpaceBetween size="l">
            <FormField 
                label="Description"
            >
                <Input
                    onChange={({ detail }) => setDescription(detail.value)}
                    value={description}
                />
            </FormField>
            <FormField 
                label="ASB Issue Types"
            >
                <Multiselect
                    selectedOptions={types}
                    onChange={({ detail }) =>
                        setTypes(detail.selectedOptions)
                    }
                    deselectAriaLabel={e => `Remove ${e.label}`}
                    options={asb_types}
                    placeholder="Choose options"
                    selectedAriaLabel="Selected"
                />
            </FormField>
            <AttributeEditor
                items={labels}
                definition={jira_label_definition}
                onAddButtonClick={onAddLabelButtonClick}
                onRemoveButtonClick={onRemoveLabelButtonClick}
                addButtonText="Add label"
                removeButtonText="Remove"
                empty="No Jira labels configured."
            />
          </SpaceBetween>
        </Modal>
      );
}