import React from 'react';
import Button from "@amzn/awsui-components-react/polaris/button";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import Link from "@amzn/awsui-components-react/polaris/link";
import JobStatus from './JobStatus'
import JobTypeBadge from './JobTypeBadge';

export default function JobTable(props) {
    const [filteringText, setFilteringText] = React.useState("");
    const [collectionPreferences, setCollectionPreferences] = React.useState({
        visibleContent: [
            "id",
            "type",
            "description",
            "status",
            "started",
            "completed"
        ],
        wrapLines: true,
        custom: "table"
    })
    const [filteredJobs, setFilteredJobs] = React.useState([]);
    React.useEffect(() => {
        setFilteredJobs(props.jobs);
    }, [props.jobs])

    const columnDefinitions = [
        {
          id: "id",
          header: "ID",
          cell: (item) => {
            try {
              return(<Link href={'/jobs/' + encodeURIComponent(item.id)}>{item.id}</Link>);
            }
            catch(err) {
              console.log(err);
              return(item.id);
            }
          }
        },
        {
            id: "type",
            header: "Type",
            cell: (item) => {
                return(<JobTypeBadge jobParams={item['parameters'] ? item['parameters'] : null}/>);
            }
        },
        {
            id: "description",
            header: "Description",
            cell: (item) => item.description ? item.description : '--'
        },
        {
            id: "status",
            header: "Status",
            cell: (item) => {
                return(<JobStatus job={item} format={'short'}/>);
            }
        },
        {
            id: "started",
            header: "Start Time",
            sortingField: "created",
            cell: (item) => {
                if(item.created) {
                    try {
                        return item.created.replace('T', ' ');
                    }
                    catch(err) {
                        return item.created;
                    } 
                }
                return '--';
            }
        },
        {
            id: "completed",
            header: "Completion Time",
            sortingField: "completed",
            cell: (item) => {
                if(item.completed) {
                    try {
                        return item.completed.replace('T', ' ');
                    }
                    catch(err) {
                        return item.completed;
                    } 
                }
                return '--';
            }
        }
    ]

    const header = (
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-group" align="right">
            <Button
                iconName="add-plus"
                href={'/jobs/create?project=' + props.project }
            >
            </Button>
            &nbsp;
            <Button
                iconName="refresh"
                onClick={(e) => {e.preventDefault(); props.refreshHandler()}}
            >
            </Button>	
          </div>
        </div>
    );
    
    return (
        <div>
          <Table
            loading={props.loading}
            items={filteredJobs}
            columnDefinitions={columnDefinitions}
            header={header}
            loadingText={'Loading Jobs...'}
            trackBy='id'
            filter={
                <TextFilter
                  filteringPlaceholder="Find job"
                  filteringText={filteringText}
                  onChange={({ detail }) => {
                        let filter = detail.filteringText.toLowerCase().trim();

                        if(!filter === null || filter.length === 0) {
                            setFilteredJobs(props.jobs);
                        }
                        else {
                            let tmp = [];
                            for(const job of props.jobs) {
                                let description = job.description ? job.description.toLowerCase() : '';
                                let id = job.id ? job.id.toLowerCase() : '';

                                if(description.includes(filter)
                                    || id.includes(filter)) {
                                    tmp.push(job);
                                }
                            }
                            setFilteredJobs(tmp);
                        }
                        
                        setFilteringText(filter);
                    }
                  }
                />
            }
            visibleColumns={collectionPreferences.visibleContent}
            empty={
              <div className="awsui-util-t-c">
                  <div className="awsui-util-pt-s awsui-util-mb-xs">
                      <b>No Zest Jobs</b>
                  </div>
                  <p className="awsui-util-mb-s">
                      Create a Zest job to view it here.
                  </p>
              </div>	
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    onConfirm={({ detail }) => {setCollectionPreferences(detail)}}
                    preferences={collectionPreferences}
                    visibleContentPreference={{
                        title: "Select visible columns",
                        options: [
                        {
                            label: "Fields",
                            options: [
                                {
                                    id: "id",
                                    label: "Job ID",
                                    editable: false
                                },
                                { id: "type", label: "Type" },
                                { id: "description", label: "Description" },
                                { id: "status", label: "Status" },
                                { id: "started", label: "Started" },
                                { id: "completed", label: "Completed" }
                            ]
                        }
                        ]
                    }}
                />
            }
          >
          </Table>
        </div>
      );
}
