import Popover from "@amzn/awsui-components-react/polaris/popover";
import StatusIndicator from "@amzn/awsui-components-react/polaris/status-indicator";
import Button from "@amzn/awsui-components-react/polaris/button";
import ProgressBar from "@amzn/awsui-components-react/polaris/progress-bar";

export default function JobStatus(props) {
    let iconName = '';
    let shortStatusDescription = '';
    let longStatusDescription = '';
    let statusIndicatorPopup = '';
    let stageProgress = props.job.state !== 'COMPLETED_SUCCESS' ? (props.job.stage_progress / props.job.stage_total) * 100 : 100;
    let totalProgress = props.job.state !== 'COMPLETED_SUCCESS' ? (props.job.overall_progress / props.job.overall_total) * 100 : 100;

    switch(props.job.state) {
        case 'NEW':
            iconName = 'refresh';
            shortStatusDescription = 'New';
            longStatusDescription = 'Job is enqueued for processing.'
            statusIndicatorPopup = 'pending';
            break;
        case 'IN_PROGRESS_DOWNLOAD_PATCHES':
            iconName = 'status-in-progress';
            shortStatusDescription = 'Downloading ASB Data';
            longStatusDescription = 'Downloading ASB patches and files.'
            statusIndicatorPopup = 'loading';
            break;
        case 'IN_PROGRESS_PARSING_PATCH_05':
            iconName = 'status-in-progress';
            shortStatusDescription = 'Parsing ASB';
            longStatusDescription = 'Parsing Patch05 (Kernel) ASB issues.'
            statusIndicatorPopup = 'loading';
            break;
        case 'IN_PROGRESS_PARSING_PATCH_01':
            iconName = 'status-in-progress';
            shortStatusDescription = 'Parsing ASB';
            longStatusDescription = 'Parsing Patch01 (Platform) ASB issues.'
            statusIndicatorPopup = 'loading';
            break;
        case 'IN_PROGRESS_JIRA_FILING_PATCH_05':
            iconName = 'status-in-progress';
            shortStatusDescription = 'Filing Jiras';
            longStatusDescription = 'Filing Jiras for Patch05 (Kernel) ASB issues.'
            statusIndicatorPopup = 'loading';
            break;
        case 'IN_PROGRESS_JIRA_FILING_PATCH_01':
            iconName = 'status-in-progress';
            shortStatusDescription = 'Filing Jiras';
            longStatusDescription = 'Filing Jiras for Patch01 (Platform) ASB issues.'
            statusIndicatorPopup = 'loading';
            break;
        case 'COMPLETED_SUCCESS':
            iconName = 'status-positive'
            shortStatusDescription = 'Complete';
            longStatusDescription = 'Job completed.'
            statusIndicatorPopup = 'success';
            break;
        case 'COMPLETED_FAILURE':
            iconName = 'status-negative'
            shortStatusDescription = 'Failed';
            longStatusDescription = 'Job failed.'
            statusIndicatorPopup = 'error';
            break;
    }

    if(props.format === 'short') {
        return(
            <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <div>
                        <StatusIndicator type={statusIndicatorPopup}>
                            <ProgressBar
                                value={isNaN(stageProgress) ? 0 : stageProgress}
                                description={longStatusDescription}
                                label="Stage Progress"
                            />
                            <ProgressBar
                                value={isNaN(totalProgress) ? 0 : totalProgress}
                                label="Overall Job Progress"
                            />
                        </StatusIndicator>
                    </div>
                }
            >
                <Button iconName={iconName}>
                    {shortStatusDescription}
                </Button>
            </Popover>
        )
    }

    return(
        <div>
            <StatusIndicator type={statusIndicatorPopup}>
                <ProgressBar
                    value={isNaN(stageProgress) ? 0 : stageProgress}
                    description={longStatusDescription}
                    label="Stage Progress"
                />
                <ProgressBar
                    value={isNaN(totalProgress) ? 0 : totalProgress}
                    label="Overall Job Progress"
                />
            </StatusIndicator>
        </div>
    );
}