import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../app/actions";
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import Container from "@amzn/awsui-components-react/polaris/container";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

export default function LatestJobPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(setPageTitle('Latest Job'));
        loadLatestJob();
    }, [])

    const loadLatestJob = async () => {
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let response = await axios.get(`${getApiURL()}/job?id=latest`, options);
            navigate('/jobs/' + response['data']);
        } catch (e) {
            console.log(e);
            navigate('/');
        }
    }

    return (
        <Container>
            <div align="center">
                <Spinner size="large" />
            </div>
        </Container>
    );
}