import React, { useCallback, useMemo } from 'react';
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { jira_priorities } from '../Common/ZestConstants';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Checkbox from "@amzn/awsui-components-react/polaris/checkbox";
import Input from "@amzn/awsui-components-react/polaris/input";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import AttributeEditor from "@amzn/awsui-components-react/polaris/attribute-editor";
import JiraRequiredField from './JiraRequiredField';
import JiraAddFieldModal from './JiraAddFieldModal';

function EditWarning(props) {
    if(props.existing) {
        return(
            <Alert
                visible={true}
                type="warning"
            >
                {"You are editing the Jira configuration for all devices using the " + props.jira_project + " Jira project."}
            </Alert>
        );
    }
    return '';
}

function JiraFields(props) {
    let fields = [];
    for(const field of props.fields) {
        if(props.availableFields) {
            fields.push(
                <FormField
                        label={<div>{field.name}<Button onClick={(e) => {e.preventDefault(); props.removeJiraField(field);}} iconName="status-negative" variant="icon" /></div>}
                >
                    <JiraRequiredField item={field} onChangeCallback={props.saveJiraField}/>
                </FormField>
            );
        }
        else {
            fields.push(
                <FormField
                        label={field.name}
                >
                    <JiraRequiredField item={field} onChangeCallback={props.saveJiraField}/>
                </FormField>
            );
        }
    }

    if(props.availableFields) { //If available fields present, show options to add and remove fields
        if(!fields || fields.length < 1) {
            <SpaceBetween size="xs">
                return <p>--</p>;
                <Button onClick={(e) => {e.preventDefault(); props.setModalVisibility(true);}}>Add field</Button>
            </SpaceBetween>
        }
    
        return (
            <SpaceBetween size="xs">
                <hr/>
                {fields}
                <Button onClick={(e) => {e.preventDefault(); props.setModalVisibility(true);}}>Add field</Button>
            </SpaceBetween>
        );
    }
    else {
        if(!fields || fields.length < 1) {
            return <p>--</p>;
        }
    
        return (
            <SpaceBetween size="xs">
                <hr/>
                {fields}
            </SpaceBetween>
        );
    }
}

const LabelControl = React.memo(({ value, index, placeholder, setItems, prop }) => {
    return (
      <Input
        value={value}
        placeholder={placeholder}
        onChange={({ detail }) => {
          setItems(items => {
            const updatedItems = [...items];
            updatedItems[index] = {
              ...updatedItems[index],
              [prop]: detail.value,
            };
            return updatedItems;
          });
        }}
      />
    );
  });

export default function JiraFieldConfigurationPanel(props) {        
    const [error, setError] = React.useState(null);  
    const [addFieldModalVisible, setAddFieldModalVisible] = React.useState(false);  
    const [editingExisting, setEditingExisting] = React.useState(false);  
    const [loadingJiraFields, setLoadingJiraFields] = React.useState(false);  
    const [loadingPatch05Config, setLoadingPatch05Config] = React.useState(false);  
    const [patch05JiraConfig, setPatch05JiraConfig] = React.useState({});  

    React.useEffect(() => {
        if(props.device_properties && props.device_properties['jira_project']) {
            loadConfigAndJiraFields(props.device_properties['jira_project']);
        }
    }, [props.device_properties])

    const loadConfigAndJiraFields = async(project) => {
        setLoadingPatch05Config(true);
        setError(null);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let response = await axios.get(`${getApiURL()}/config/file?name=patch05_jira_project_field_config.json`, options);
            let patch05JiraConfig = response['data'];
            setPatch05JiraConfig(patch05JiraConfig);
            let jira_config = undefined;

            if(patch05JiraConfig && patch05JiraConfig[project] && patch05JiraConfig[project]['default']) {
                setEditingExisting(true);
                jira_config = patch05JiraConfig[project]['default'];
                if(jira_config['priority']) {
                    props.setJiraPriority({label: jira_config['priority'], value: jira_config['priority'].toLowerCase()});
                }
                if(jira_config['add_device_family_label']) {
                    props.setJiraAddDeviceFamilyLabel(jira_config['add_device_family_label']);
                }
                if(jira_config['add_due_date']) {
                    props.setJiraAddDueDate(jira_config['add_due_date']);
                }
                
                let labels = [];
                if(jira_config['labels'] && jira_config['labels'].length > 0) {
                    for(const label of jira_config['labels']) {
                        labels.push({
                            key: label
                        });
                    }
                }
                else { //default labels
                    labels.push({
                        key: props.device_properties['name'].toLowerCase()
                    });
                    if(props.device_properties['aosp']) {
                        labels.push({
                            key: props.device_properties['aosp']
                        });
                    }
                }
                props.setJiraLabels(labels);
            }
            
            setLoadingJiraFields(true);
            response = await axios.get(`${getApiURL()}/config/jira_fields?type=all&project=` + project, options);
            let availableFields = response['data']['body'];
            props.setAvailableJiraFields(availableFields);
            let requiredFields = [];
            let otherFields = [];

            if(availableFields.length < 1) { //If no available Jira fields, we probably have no permissions for this Jira project!
                setError('The Zest bot account is missing permissions for the ' + project + ' Jira project! Please reach out to the project owner and request permissions for bot-digisec (android-bulletins-fos@amazon.com).');
            }

            //Populate required fields
            for(const field of availableFields) {
                if(field.required) {
                    requiredFields.push(field);
                }
            }

            //Fill in existing field values from Patch05 config, starting with Components
            if(jira_config) {
                if(jira_config['components']) {
                    for(const field of requiredFields) {
                        if(field.fieldId === 'components') {
                            if(typeof jira_config['components'] === "object") { //Object with component ID
                                for(const component of field.allowedValues) {
                                    if(component.id === jira_config['components']['id']) {
                                        field.value = [{
                                            id: component.name,
                                            value: component.name,
                                            label: component.name 
                                        }];
                                        break;
                                    }
                                }
                            }
                            else { //String with component name
                                field.value = [{
                                    id: jira_config['components'],
                                    value: jira_config['components'],
                                    label: jira_config['components']
                                }];
                            }
                            break;
                        }
                    }
                }
            
                //Fill in values for other configured fields
                if(jira_config['custom_fields'] && jira_config['custom_fields'].length > 0) {
                    for(const existingField of jira_config['custom_fields']) {
                        let found = false;

                        for(const field of requiredFields) {
                            if(field.fieldId === existingField.key) {
                                if(field.schema.type === 'option') {
                                    for(const option of field.allowedValues) {
                                        if(option.value === existingField.value) {
                                            field.value = option;
                                            break;
                                        }
                                    }
                                }
                                else if(field.schema.type === 'array') {
                                    field.value = [];
                                    for(const existingOption of existingField.value) {
                                        field.value.push({
                                            id: existingOption,
                                            value: existingOption,
                                            label: existingOption
                                        });
                                    }
                                }
                                else {
                                    field.value = existingField.value;
                                }

                                found = true;    
                                break;
                            }
                        }

                        if(!found) { //If field wasn't found in required fields list, check all available fields
                            for(const field of availableFields) {
                                if(field.fieldId === existingField.key) {
                                    if(field.schema.type === 'option') {
                                        for(const option of field.allowedValues) {
                                            if(option.value === existingField.value) {
                                                field.value = option;
                                                break;
                                            }
                                        }
                                    }
                                    else if(field.schema.type === 'array') {
                                        field.value = [];
                                        for(const existingOption of existingField.value) {
                                            field.value.push({
                                                id: existingOption,
                                                value: existingOption,
                                                label: existingOption
                                            });
                                        }
                                    }
                                    else {
                                        field.value = existingField.value;
                                    }
        
                                    otherFields.push(field);
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            props.setRequiredJiraFields(requiredFields);
            props.setOtherJiraFields(otherFields);

        } catch (e) {
            console.log(e);
            setError('Failed to retrieve Patch05 Jira configuration: ' + e)
        }

        setLoadingPatch05Config(false);
        setLoadingJiraFields(false);
    }

    const removeJiraField = (params) => {
        let tmp = props.otherJiraFields;
        if(params.required) {
            tmp = props.requiredJiraFields;
        }

        let i = 0;
        for(i = 0; i < tmp.length; i++) {
            if(tmp[i].fieldId === params.fieldId) {
                break;
            }
        }

        if(i < tmp.length) {
            if(params.required) {
                props.setRequiredJiraFields(items => {
                    const newItems = items.slice();
                    newItems.splice(i, 1);
                    return newItems;
                });
            }
            else {
                props.setOtherJiraFields(items => {
                    const newItems = items.slice();
                    newItems.splice(i, 1);
                    return newItems;
                });
            }
        }

    }

    const saveJiraField = (params) => {
        let tmp = props.otherJiraFields;

        if(params.required) {
            tmp = props.requiredJiraFields;
        }

        let i = 0;
        for(i = 0; i < tmp.length; i++) {
            if(tmp[i].fieldId === params.id) {
                break;
            }
        }

        if(i < tmp.length) { //We are editing an existing field
            if(params.type === "string") {
                tmp[i].format = false;
                tmp[i].value = params.value;
            }
            else {
                tmp[i].value = params.value;
            }
        }
        else { //We are adding a new Jira field
            tmp.push(params);
        }

        if(params.required) {
            props.setRequiredJiraFields(tmp);
        }
        else {
            props.setOtherJiraFields(tmp);
        }
	};

    const label_definition = useMemo(
        () => [
          {
            label: 'Jira Labels',
            control: ({ key = '' }, itemIndex) => (
              <LabelControl prop="key" value={key} index={itemIndex} placeholder="Enter label text" setItems={props.setJiraLabels} />
            )
          }
        ],
        []
    );

    const onAddLabelButtonClick = useCallback(() => {
        props.setJiraLabels(items => [...items, {}]);
    }, []);

    const onRemoveLabelButtonClick = useCallback(({ detail: { itemIndex } }) => {
        props.setJiraLabels(items => {
            const newItems = items.slice();
            newItems.splice(itemIndex, 1);
            return newItems;
        });
    }, []);


    //Loading state
    if(loadingJiraFields || loadingPatch05Config) {
        return (
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Jira Configuration
                    </Header>
                }
            >
                <div align="center">
                    <Spinner />
                </div>
            </Container>
        );
    }

    //Error state
    if(error) {
        return (
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Jira Configuration
                    </Header>
                }
            >
                <Alert
                visible={true}
                type="error"
                >
                    {error}
                </Alert>
            </Container>
        );
    }

    //Empty (no Jira project defined) state
    if(!props.device_properties || !props.device_properties['jira_project']) {
        return (
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                    Jira Configuration
                    </Header>
                }
            >
                <Alert
                    visible={true}
                    header="Device Selection Required"
                >
                    Please select a device to configure its Jira properties.
                </Alert>
            </Container>
        );
    }

    //Default fields
    return(
        <Container
            header={
                <Header
                    variant="h2"
                >
                Jira Configuration
                </Header>
            }
        >
            <SpaceBetween size="s">
                <EditWarning existing={editingExisting} jira_project={props.device_properties['jira_project']} />
                <FormField
                    label="Jira Priority"
                >
                    <Select
                        selectedOption={props.jiraPriority}
                        empty="No Jira priorities configured"
                        onChange={({ detail }) =>
                            props.setJiraPriority(detail.selectedOption)
                        }
                        options={jira_priorities}
                        selectedAriaLabel="Selected"
                    />
                </FormField>
                <FormField>
                    <AttributeEditor
                        items={props.jiraLabels}
                        definition={label_definition}
                        onAddButtonClick={onAddLabelButtonClick}
                        onRemoveButtonClick={onRemoveLabelButtonClick}
                        addButtonText="Add label"
                        removeButtonText="Remove"
                        empty="No labels configured."
                    />
                </FormField>
                <Checkbox
                    onChange={({ detail }) =>
                        props.setJiraAddDeviceFamilyLabel(detail.checked)
                    }
                    checked={props.jiraAddDeviceFamilyLabel}
                >
                    {props.device_properties['family'] ? "Add Device Family Label: "  + props.device_properties['family'] : "Add Device Family Label"}
                </Checkbox>
                <Checkbox
                    onChange={({ detail }) =>
                        props.setJiraAddDueDate(detail.checked)
                    }
                    checked={props.jiraAddDueDate}
                >
                    Add Due Date
                </Checkbox>
                <FormField
                    label="Required Fields"
                >
                    <JiraFields fields={props.requiredJiraFields} saveJiraField={saveJiraField}/>
                </FormField>
                <FormField
                    label="Other Fields"
                >
                    <JiraFields fields={props.otherJiraFields} availableFields={props.availableJiraFields} setModalVisibility={setAddFieldModalVisible} saveJiraField={saveJiraField} removeJiraField={removeJiraField}/>
                </FormField>
                <JiraAddFieldModal availableFields={props.availableJiraFields} excludeFields={props.otherJiraFields} setModalVisibility={setAddFieldModalVisible} visible={addFieldModalVisible} saveJiraField={saveJiraField} />
            </SpaceBetween>
        </Container>
    )
}