import React from 'react';
import axios from "axios";
import { useDispatch } from "react-redux";
import { setStatusBarMessage } from "../../app/actions";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import ClipboardCopy from "../Common/ClipboardCopy";
import ExternalLink from "../Common/ExternalLink";
import JobStatus from './JobStatus';
import JobTypeBadge from './JobTypeBadge';
import PANNJobStatus from './PANNJobStatus';
import {
    Box,
    ColumnLayout,
    Container,
    Header,
    Button,
    SpaceBetween,
    Spinner
} from '@amzn/awsui-components-react/polaris';
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import '../../styles/center.scss';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
);

export default function JobDetailPanel(props) {
    const dispatch = useDispatch();
    const [loading, setLoading] = React.useState(false);
    const [jobActionLoading, setJobActionLoading] = React.useState(false);
    const [jobDetails, setJobDetails] = React.useState({});
    const [jobParams, setJobParams] = React.useState('');
    const [ecsTaskID, setECSTaskID] = React.useState(''); 
    const [pannJobStatus, setPannJobStatus] = React.useState(null);

    React.useEffect(() => {
        loadJobDetails(props.id);
    }, [props.id])

    const loadJobDetails = async(id) => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let response = await axios.get(`${getApiURL()}/job?id=` + id, options);
            setJobDetails(response['data']);
            setJobParams(response['data']['parameters']);

            let ecsTaskID = response['data']['ecs_task_id'];
            ecsTaskID = ecsTaskID.substring(ecsTaskID.lastIndexOf('/') + 1);
            setECSTaskID(ecsTaskID);
            
            if(response['data']['pann_job_id']) { //Load PANN job state, if applicable
                setPannJobStatus('load_error');
                response = await axios.get(`${getApiURL()}/job/pann?id=` + response['data']['pann_job_id'], options);
                console.log(response);
                if(response.status === 200) {
                    console.log(response['data']);
                    setPannJobStatus(response['data']['status']);
                }
            }
        } catch (e) {
            console.log(e);
        }
        
        setLoading(false);
    }

    const restartJob = async(id) => {
        dispatch(setStatusBarMessage(null));
        setJobActionLoading(true);

        let response = null;
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }

        try {
            const data = {
                job_id: id,
                job_description: jobDetails['description'],
                restart: true,
                params: jobDetails['parameters']
            } 
            
            response = await axios.post(`${getApiURL()}/job`, data, options);
            if(response.status === 200) {
                dispatch(setStatusBarMessage({type: "success", message: 'Restarted job'}));
                window.location = '/jobs/' + id; //Navigate to new Job detail view
            }
            else { //Display creation error message (API response)
                dispatch(setStatusBarMessage({type: "error", message: "Failed to restart job: " + response && response['data'] ? response['data'] : 'Unknown error'}));
            }
        } catch (e) { //Display creation error message (local)
            console.log(e);
            dispatch(setStatusBarMessage({type: "error", message: "Failed to restart job: " + e.toString()}));
        }

        setJobActionLoading(false);
    }

    const stopJob = async(id) => {
        dispatch(setStatusBarMessage(null));
        setJobActionLoading(true);

        let response = null;
        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }

        try {
            const data = {
                job_id: id
            } 
            
            response = await axios.post(`${getApiURL()}/job/stop`, data, options);
            if(response.status === 200) {
                dispatch(setStatusBarMessage({type: "success", message: 'Stopped job'}));
                window.location = '/jobs/' + id; //Navigate to new Job detail view
            }
            else { //Display creation error message (API response)
                dispatch(setStatusBarMessage({type: "error", message: "Failed to stop job: " + response && response['data'] ? response['data'] : 'Unknown error'}));
            }
        } catch (e) { //Display creation error message (local)
            console.log(e);
            dispatch(setStatusBarMessage({type: "error", message: "Failed to stop job: " + e.toString()}));
        }

        setJobActionLoading(false);
    }

    const handleDropdownActionClick = (e) => {
        e.preventDefault();
        let id = e.detail.id
    
        switch(id){
          case 'restart':
            restartJob(props.id);
            break;
          case 'stop':
            stopJob(props.id);
            break;
        }
    }

    if(loading) {
        return (
            <Container header={<Header variant="h2">Details</Header>}>
                <div align="center">
                    <Spinner size="large" />
                </div>
            </Container>
        );
    }

    return(
        <Container 
            header={
                <Header 
                    variant="h2"
                    actions={
                        <SpaceBetween direction='horizontal' size='s'>
                            <Button
                                iconName="refresh"
                                onClick={(e) => {e.preventDefault(); loadJobDetails(props.id)}}
                            >
                            </Button>
                            <ButtonDropdown
                                items={[
                                    { id: "restart", text: "Restart", disabled: (!('state' in jobDetails) || (jobDetails['state'] !== 'COMPLETED_SUCCESS' && jobDetails['state'] !== 'COMPLETED_FAILURE')) },
                                    { id: "stop", text: "Stop", disabled: (!('state' in jobDetails) || (jobDetails['state'] === 'COMPLETED_SUCCESS' || jobDetails['state'] === 'COMPLETED_FAILURE')) }
                                ]}
                                ariaLabel="Job actions"      
                                variant="icon"
                                loading={jobActionLoading}
                                onItemClick={handleDropdownActionClick}
                            />
                        </SpaceBetween>
                    }
                >
                    Details
                </Header>
            }
        >
            <ColumnLayout columns={3} variant="text-grid">
            <SpaceBetween size="l">
                <ValueWithLabel label="ID">{jobDetails.id} <ClipboardCopy copyText={jobDetails.id} successMessage={'ID Copied.'}/></ValueWithLabel>
                <ValueWithLabel label="Mode"><JobTypeBadge jobParams={jobParams}/></ValueWithLabel>
                <ValueWithLabel label="Description">{jobDetails.description ? jobDetails.description : '--'}</ValueWithLabel>
                <ValueWithLabel label="Started By"><ExternalLink base={"https://phonetool.amazon.com/users/"} value={jobDetails.started_by}/></ValueWithLabel>
                <ValueWithLabel label="CloudWatch Log Stream"><ExternalLink base={'https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#logsV2:log-groups/log-group/$252Fecs$252Fzest-asb-service/log-events/ecs$252Fzest$252F'} value={ecsTaskID}/></ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
                <ValueWithLabel label="Job Status">
                    <div style={{paddingLeft: '10px'}}>
                    <JobStatus job={jobDetails} format={'long'}/>
                    </div>
                </ValueWithLabel>
                <PANNJobStatus pannStatus={pannJobStatus} pannJobId={jobDetails.pann_job_id ? jobDetails.pann_job_id : null}/>
            </SpaceBetween>
            <SpaceBetween size="l">
                <ValueWithLabel label="Start Time">{jobDetails.created ? jobDetails.created.replace('T', ' ') : '--'}</ValueWithLabel>
                <ValueWithLabel label="Completion Time">{jobDetails.completed ? jobDetails.completed.replace('T', ' ') : '--'}</ValueWithLabel>
                <ValueWithLabel label="Parameters">{jobDetails.parameters} <ClipboardCopy copyText={jobDetails.parameters} successMessage={'Parameters Copied.'}/></ValueWithLabel>
            </SpaceBetween>
            </ColumnLayout>
        </Container>
    )
}