import React  from 'react';
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { MONTHS } from '../Common/ZestConstants';
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Select from "@amzn/awsui-components-react/polaris/select";
import MultiSelect from "@amzn/awsui-components-react/polaris/multiselect";

export default function ASBMonthSelector(props) {
    const [loading, setLoading] = React.useState(true); 
    const [asbMonths, setASBMonths] = React.useState([]);

    React.useEffect(() => {
        loadASBMonths();
    }, [])

    const loadASBMonths = async() => {
        setLoading(true);

        const options = {
            headers: {
                'Authorization': `Bearer ${getJwtToken()}`,
                'Content-Type': 'application/json'
            },
        }
    
        try {
            let tmp = [];
            let response = await axios.get(`${getApiURL()}/config/patches`, options);
            response['data'].sort().reverse();
            for(const month of response['data']) {
                let year_month_data = month.replace('patches_', '').trim();
                year_month_data = year_month_data.replace('-', ' ');
                year_month_data = year_month_data.replace('_', ' ');
                year_month_data = year_month_data.replace('/', ' ');
                year_month_data = year_month_data.split(' ');

                tmp.push({
                    label: MONTHS[parseInt(year_month_data[1])] + ' ' + year_month_data[0], value: month.replace('/', '')
                });
            }
            setASBMonths(tmp);
        } catch (e) {
            console.log(e);
        }

        setLoading(false);
    }

    if(loading) {
        return <Spinner />;
    }

    let description = "Specify ASB patch month";
    if(props.optional && props.optional === true) {
        description = "(Optional) Specify ASB patch month"
    }

    return (
        <FormField
        description={description}
        label="Patch Month"
        >
            {!props.multiselect &&
                <Select
                    selectedOption={props.selectedOption}
                    empty="No ASB data available for use."
                    onChange={({ detail }) =>
                        props.setSelectedOption(detail.selectedOption)
                    }
                    options={asbMonths}
                    selectedAriaLabel="Selected"
                />
            }
            {props.multiselect &&
                <MultiSelect
                    selectedOptions={props.selectedOption}
                    empty="No ASB data available for use."
                    onChange={({ detail }) =>
                        props.setSelectedOption(detail.selectedOptions)
                    }
                    options={asbMonths}
                    selectedAriaLabel="Selected"
                />
            }
        </FormField>
    );
}