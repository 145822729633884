import React from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import ExternalLink from '../Common/ExternalLink';

export default function PANNResultJiraTable(props) {
    const [filteringText, setFilteringText] = React.useState("");
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [collectionPreferences, setCollectionPreferences] = React.useState({
        visibleContent: [
            "id",
            "cve",
            "device",
            "applicability",
            "compile_status"
        ],
        wrapLines: true,
        custom: "table"
    })
    const [filteredJiras, setFilteredJiras] = React.useState([]);
    React.useEffect(() => {
        setFilteredJiras(props.jiras);
    }, [props.jiras])

    const columnDefinitions = [
        {
            id: "id",
            header: "ID",
            cell: (item) => {
              try {
                return(
                    <ExternalLink
                        base={'https://issues.labcollab.net/browse/'}
                        value={item.id}
                    />
                );
              }
              catch(err) {
                console.log(err);
              }
            }
        },
        {
            id: "cve",
            header: "CVE",
            cell: (item) => item.cve ? item.cve : '--'
        },
        {
            id: "device",
            header: "Device",
            cell: (item) => item.device ? item.device : '--'
        },
        {
            id: "applicability",
            header: "Applicable",
            cell: (item) => {
                if(item.prediction) {
                    return <Badge color="green">Yes</Badge>;
                }
                else {
                    return <Badge color="red">No</Badge>;
                }
            }
        },
        {
            id: "compile_status",
            header: "Compiled",
            cell: (item) => {
                if(item.enable) {
                    return <Badge color="green">Yes</Badge>;
                }
                else {
                    return <Badge color="red">No</Badge>;
                }
            }
        },
    ]
    
    return (
          <Table
            loading={props.loading}
            items={filteredJiras}
            columnDefinitions={columnDefinitions}
            loadingText={'Loading Jiras...'}
            trackBy='id'
            filter={
                <TextFilter
                  filteringPlaceholder="Find Jira"
                  filteringText={filteringText}
                  onChange={({ detail }) => {
                        let filter = detail.filteringText.toLowerCase().trim();

                        if(!filter === null || filter.length === 0) {
                            setFilteredJiras(props.jiras);
                        }
                        else {
                            let tmp = [];
                            for(const jira of props.jiras) {
                                let id = jira.id ? jira.id.toLowerCase() : '';
                                let cve = jira.cve ? jira.cve.toLowerCase() : '';
                                let device = jira.device ? jira.device.toLowerCase() : '';

                                if(id.includes(filter) ||
                                   cve.includes(filter) ||
                                   device.includes(filter)) {
                                    tmp.push(jira);
                                }
                            }
                            setFilteredJiras(tmp);
                        }
                        
                        setFilteringText(filter);
                    }
                  }
                />
            }
            selectedItems={selectedItems}
            visibleColumns={collectionPreferences.visibleContent}
            empty={
              <div className="awsui-util-t-c">
                  <div className="awsui-util-pt-s awsui-util-mb-xs">
                      <b>No Jiras</b>
                  </div>
                  <p className="awsui-util-mb-s">
                      No Jiras present.
                  </p>
              </div>	
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    onConfirm={({ detail }) => {setCollectionPreferences(detail)}}
                    preferences={collectionPreferences}
                    visibleContentPreference={{
                        title: "Select visible columns",
                        options: [
                        {
                            label: "Fields",
                            options: [
                                {
                                    id: "id",
                                    label: "Jira ID",
                                    editable: false
                                },
                                { id: "cve", label: "CVE" },
                                { id: "device", label: "Device" },
                                { id: "applicability", label: "Applicable" },
                                { id: "compile_status", label: "Compiled" }
                            ]
                        }
                        ]
                    }}
                />
            }
          >
          </Table>
      );
}
