import Badge from "@amzn/awsui-components-react/polaris/badge";
import Popover from "@amzn/awsui-components-react/polaris/popover";

export default function PatchLevelBadge(props) {
    if(props.patch_level && props.patch_level.length > 0) {
        let color = '';
        let description = '';

        if(props.patch_level === 'Patch01') {
            color = 'blue';
            description = 'Platform Issue';
        } 
        else if(props.patch_level === 'Patch05') {
            color = 'green';
            description = 'Kernel Issue';
        }

        return(
            <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={description}
            >
                <Badge color={color}>{props.patch_level}</Badge>
            </Popover>
        );
    }

    return '--';
}