import React from 'react';
import { parseJiraFile } from "../Common/ParseUtils"
import FailedJiraTable from './FailedJiraTable';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import Box from "@amzn/awsui-components-react/polaris/box";
import { ColumnLayout } from '@amzn/awsui-components-react/polaris';

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

  function FileButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    else {
        if(!props.disabled) {
            if(props.job &&
             (props.selectedJiraFile && props.selectedJiraFile.type === 'application/json')
            ) {
                return(<Button onClick={(e) => {e.preventDefault(); props.uploadFile();}} variant="primary">Upload</Button>);
            }
        }
        return(<Button disabled variant="primary">Upload</Button>);
    }
}

export default function PendingFileJiraPanel(props) {
    if(props.jiraArtifact) {
        let jira_list = parseJiraFile(props.jiraArtifact['jiras']);
        return(
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Review pending Jiras to be filed."
                    >
                    Jiras
                    </Header>
                }
                >
                    <SpaceBetween size="m">
                        <ColumnLayout columns={3} variant="text-grid">
                            <ValueWithLabel label="Jiras">{jira_list.length}</ValueWithLabel>
                            <ValueWithLabel label="Jira Server">{props.jiraArtifact['jira_server'] ? props.jiraArtifact['jira_server'] : ''}</ValueWithLabel>
                        </ColumnLayout>
                        <FailedJiraTable jira_server={props.jiraArtifact['jira_server'] ? props.jiraArtifact['jira_server'] : ''} jiras={jira_list} />
                        <div align="right">
                            <FileButton job={props.job} jiras={props.selectedJiras} fileAction={props.fileAction} loading={props.loading} />
                        </div>
                    </SpaceBetween>
            </Container>
        );
    }

    return '';
}
