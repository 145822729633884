import React, { useRef } from 'react';
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../app/actions";
import { setStatusBarMessage } from "../../app/actions";
import { formatBytes } from "../Common/FormatUtils"
import axios from "axios";
import { getJwtToken } from '../Common/Auth';
import { getApiURL } from "../Common/envVarsReader";
import { MONTHS } from "../Common/ZestConstants";
import { ColumnLayout } from '@amzn/awsui-components-react/polaris';
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import Button from "@amzn/awsui-components-react/polaris/button";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";

const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );

function UploadButton(props) {
    if(props.loading) {
        return <Spinner />; 
    }
    else {
        if(!props.disabled) {
            if(props.selectedFile && props.selectedFile.type === 'application/zip') {
                return(<Button onClick={(e) => {e.preventDefault(); props.uploadFile();}} variant="primary">Upload</Button>);
            }
        }
        return(<Button disabled variant="primary">Upload</Button>);
    }
}

function FileDetails(props) {
    if(props.selectedFile) {
        let asbMonth = props.selectedFile.name;

        try {
            //Parse month, month name, and year from patch string
            asbMonth = asbMonth.replace('patches', ''); //Strip leading 'patches'
            asbMonth = asbMonth.replace(/\D/g, ' ').trim(); //Replace non-numeric with spaces and trim
            let year = asbMonth.split(' ')[0];
            let month_num = asbMonth.split(' ')[1];
            let month_name = MONTHS[parseInt(month_num)];
            asbMonth = `${month_name} ${year}`;
        }
        catch(err) {
            asbMonth = 'Unknown';
        }

        return(
            <Container
                header={
                    <Header
                        variant="h3"
                    >
                    File Details
                    </Header>
                }
                >
                <ColumnLayout columns={2} variant="text-grid">
                    <ValueWithLabel label="ASB">{asbMonth}</ValueWithLabel>
                    <ValueWithLabel label="Size">{formatBytes(props.selectedFile.size)}</ValueWithLabel>
                    <ValueWithLabel label="File name">{props.selectedFile.name}</ValueWithLabel>
                    <ValueWithLabel label="Type">{props.selectedFile.type}</ValueWithLabel>
                </ColumnLayout>
            </Container>
        );
    }
    return '';
}

export default function ASBUploadPage() {
    const dispatch = useDispatch();
    const fileInputRef = useRef();
    const selectFile = () => {
        fileInputRef.current.click();
    }
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [loading, setLoading] = React.useState(false);  
    const [uploadDisabled, setUploadDisabled] = React.useState(false);  

    React.useEffect(() => {
        dispatch(setPageTitle('Upload ASB Data'));
    }, [])

    const uploadFile = async() => {
        setLoading(true);
        dispatch(setStatusBarMessage(null));

        try {
            //Get presigned URL
            const presigned_url_options = {
                headers: {
                    'Authorization': `Bearer ${getJwtToken()}`,
                    'Content-Type': 'application/json'
                },
            };
            const presigned_url_data = {file_name: selectedFile.name};
            let presigned_url_response = await axios.post(`${getApiURL()}/config/patches`, presigned_url_data, presigned_url_options);
            

            //Upload data to S3
            let payload = new FormData();
            Object.entries(presigned_url_response.data.fields).forEach(([ key, val ]) => {
                payload.append(key, val);
            });
            payload.append("file", selectedFile);

            let response = await axios.post(presigned_url_response.data.url, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }    
            });
            dispatch(setStatusBarMessage({type: 'success', message: 'ASB data has been uploaded. Please wait several minutes for it to appear under available patches.'}));
            setUploadDisabled(true);
        }
        catch(e) {
            console.log(e);
            dispatch(setStatusBarMessage({type: 'error', message: 'Failed to upload file: ' + e}));
        }
        setLoading(false);
    }

    return (
        <SpaceBetween size='l'>
            <Container
                header={
                    <Header
                        variant="h2"
                        description="Choose a zip file containing ASB data to upload."
                    >
                    File Selection
                    </Header>
                }
                >
                <input 
                    type="file" 
                    style={{ "display": "none" }} 
                    ref={fileInputRef} 
                    onChange={e => {
                        setSelectedFile(e.target.files[0]);
                        if(e.target.files[0].type !== 'application/zip') {
                            dispatch(setStatusBarMessage({type: 'warning', message: 'Invalid file selection: Please select a zip archive containing ASB patch data for a specific month.'}));
                        }
                        else {
                            dispatch(setStatusBarMessage(null));
                        }
                    }}
                />
                <Button
                    onClick={(e) => {e.preventDefault(); selectFile();}}
                    iconAlign="right"
                    iconName="upload"
                    >
                    Choose file
                </Button>
            </Container>
            <FileDetails selectedFile={selectedFile}/>
            <div align="right">
                <UploadButton selectedFile={selectedFile} uploadFile={uploadFile} loading={loading} disabled={uploadDisabled} />
            </div>
        </SpaceBetween>
    );
}